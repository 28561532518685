import { Grid } from "@mui/material";
import { React, useRef } from "react";
import BaseColor from "../../Config/Color";
import Files from "../../Config/Files";
import Lottie from "lottie-react";
import CHeader from "../../Components/CHeader";
import CFooter from "../../Components/CFooter";
import CButton from "../../Components/CButton";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const footerRef = useRef();

  const navigate = useNavigate();

  return (
    <>
      <CHeader
        onLoginRequired={() => {
          footerRef?.current?.LoginModalOpen(true);
        }}
      />
      <Grid container sx={{ backgroundColor: BaseColor.whiteColor }}>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          style={{
            height: "61.2vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Lottie
            animationData={Files.lottie.error404}
            loop={true}
            style={{
              height: "80%",
              width: "80%",
            }}
          />
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={4} sm={4} md={3} lg={3}>
              <CButton
                variant="contained"
                onClick={() => {
                  navigate("/products");
                }}
                style={{
                  width: "100%",
                  marginTop: "20px",
                  marginBottom: "90px",
                }}
              >
                Go to home page
              </CButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CFooter ref={footerRef} />
    </>
  );
};

export default Error404;
