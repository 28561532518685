import React, { useEffect, useState } from "react";
import CHeader from "../../../Components/CHeader";
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import styles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import BaseColor from "../../../Config/Color";
import CInput from "../../../Components/CInput";
import CCheckBox from "../../../Components/CCheckBox";
import CButton from "../../../Components/CButton";
import CFooter from "../../../Components/CFooter";
import CImageUpload from "../../../Components/CImageUpload";
import { toast } from "react-toastify";
import _, { isEmpty } from "lodash";
import BaseSetting from "../../../Apis/setting";
import { getApiData, getAxiosApi } from "../../../Apis/apiHelper";
import CPageHeader from "../../../Components/CPageHeader";
import {
  pricingTermsList,
  supplyList,
  unitList,
} from "../../../Config/staticData";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import theme, { FontFamily } from "../../../Config/theme";
import ConfirmModel from "../../../Components/ConfirmationModal";
import CAutoComplete from "../../../Components/CAutoComplete";
import { translate } from "../../../lang/Translate";

const EditSellPost = () => {
  const { userData } = useSelector((state) => state.auth);
  console.log("userData======>>>>>", userData);
  const navigate = useNavigate();
  const location = useLocation();

  const isUpdate = location?.state?.update;
  const postId = location?.state?.postId;

  //media queries
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [condition, setCondition] = useState("");
  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [supply, setSupply] = useState("");
  const [perUnitPrice, setPerUnitPrice] = useState("");
  const [pricingTerms, setPricingTerms] = useState("");
  const [request, setRequest] = useState(false);
  const [zipPostalCode, setZipPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [streetName, setStreetName] = useState("");
  const [targetCountry, setTargetCountry] = useState([]);
  const [removeVideo, setRemoveVideo] = useState([0]);
  const [mainPostData, setMainPostData] = useState({});
  console.log("mainPostData======>>>>>", mainPostData);

  const [check, setCheck] = useState(false);
  const [visible, setVisible] = useState(false);
  // Loader
  const [postBtnLoader, setPostBtnLoader] = useState(false);
  const [draftBtnLoader, setDraftBtnLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  //form validation
  const [categoryErrorText, setCategoryErrorText] = useState("");
  const [subCategoryErrorText, setSubCategoryErrorText] = useState("");
  const [conditionErrorText, setConditionErrorText] = useState("");
  const [productNameErrorText, setProductNameErrorText] = useState("");
  const [productDescErrorText, setProductDescErrorText] = useState("");
  const [productQuantityErrorText, setProductQuantityErrorText] = useState("");
  const [unitErrorText, setUnitErrorText] = useState("");
  const [supplyErrorText, setSupplyErrorText] = useState("");
  const [perUnitPriceErrorText, setPerUnitPriceErrorText] = useState("");
  const [pricingTermsErrorText, setPricingTermsErrorText] = useState("");
  const [zipPostalCodeErrorText, setZipPostalCodeErrorText] = useState("");
  const [countryErrorText, setCountryErrorText] = useState("");
  const [stateErrorText, setStateErrorText] = useState("");
  const [cityErrorText, setCityErrorText] = useState("");
  const [streetNameErrorText, setStreetNameErrorText] = useState("");
  const [targetCountryErrorText, setTargetCountryErrorText] = useState("");
  const [imagesErrorText, setImagesErrorText] = useState("");
  const [videoErrorText, setVideoErrorText] = useState("");
  const [currencyErrorText, setCurrencyErrorText] = useState("");

  const [allTargetedCountry, setAllTargetedCountry] = useState(false);

  //image upload
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  console.log("video======>>>>>", video);
  const [thumbnail, setThumbnail] = useState({});

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [allSubcategoryList, setAllSubCategoryList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  // google pincode api
  const [pinCodeData, setPinCodeData] = useState();

  // get currency list
  const [currencyList, setCurrencyList] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currencyCode, setCurrencyCode] = useState({});

  // save as draft disable
  const [saveAsDraftDisable, setSaveAsDraftDisable] = useState(false);

  const [pageLoad, setPageLoad] = useState(true);
  const [close, setClose] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    GetCategoryListApi();
    GetConditionListApi();
    GetCountryListApi();
    GetCurrencyListApi();
    if (isUpdate) {
      GetSubCategoryListApi();
    }
  }, []);

  useEffect(() => {
    if (removeVideo !== 0) {
      setVideo([]);
    }
  }, [removeVideo]);

  useEffect(() => {
    const isSuccess =
      !_.isEmpty(categoryList) &&
      _.isArray(categoryList) &&
      !_.isEmpty(conditionList) &&
      _.isArray(conditionList) &&
      !_.isEmpty(countryList) &&
      _.isArray(countryList);
    if (isSuccess) {
      if (
        isUpdate &&
        !_.isEmpty(allSubcategoryList) &&
        _.isArray(allSubcategoryList)
      ) {
        getOnePostData();
      } else {
        setPageLoad(false);
      }
    }
  }, [countryList, categoryList, conditionList, allSubcategoryList]);

  const GetCategoryListApi = () => {
    const endpoint = BaseSetting.endpoint.categoryList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const GetSubCategoryListApi = () => {
    const endpoint = BaseSetting.endpoint.subCategoryList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setAllSubCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const GetSubCategoryListByCategoryApi = (parentId) => {
    const endpoint = BaseSetting.endpoint.subCategoryByParentIdList;
    const data = {
      parent_id: parentId,
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setSubCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const GetCountryListApi = () => {
    const endpoint = BaseSetting.endpoint.countryList;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCountryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const GetConditionListApi = () => {
    const endpoint = BaseSetting.endpoint.conditionList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setConditionList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  // get currency list
  const GetCurrencyListApi = () => {
    const endpoint = BaseSetting.endpoint.currencyList;
    getApiData(endpoint, "post", {})
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCurrencyList(data);
          const currency = data.find(
            (v) => v.country_code === userData?.business_info?.country_code
          );
          setCurrencySymbol(currency?.currency_symbol);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  // // get one post data
  const getOnePostData = (localImages, fileType) => {
    const endpoint = BaseSetting.endpoint.getOnePostData;
    const data = { id: postId, type: "Sell" };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          if (
            !_.isEmpty(localImages) &&
            !_.isUndefined(localImages) &&
            _.isArray(localImages) &&
            fileType != "video"
          ) {
            appendLiveImageInArray(
              data?.postImages?.images,
              localImages,
              postId
            );
          } else {
            setData(data, localImages);
          }
          setMainPostData(result?.data?.postData);
        } else {
          toast(result.message, { type: "error" });
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
        setPageLoad(false);
      });
  };

  const setData = (data, bool = false) => {
    const postData =
      !_.isEmpty(data.postData) &&
      !_.isNull(data.postData) &&
      !_.isUndefined(data.postData)
        ? data.postData
        : {};

    const postImages =
      !_.isEmpty(data.postImages) &&
      !_.isNull(data.postImages) &&
      !_.isUndefined(data.postImages)
        ? data.postImages
        : {};

    const imagesData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.images) &&
      !_.isEmpty(postImages?.images) &&
      _.isArray(postImages?.images)
        ? postImages?.images
        : [];

    setImages([...imagesData]);
    const videoData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.video) &&
      !_.isEmpty(postImages?.video) &&
      _.isArray(postImages?.video)
        ? postImages?.video.find((v) => v.type === "video")
        : [];
    setVideo({ ...videoData });
    const thumbnailData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.video) &&
      !_.isEmpty(postImages?.video) &&
      _.isArray(postImages?.video)
        ? postImages?.video.find((v) => v.type === "thumbnail")
        : [];
    setThumbnail({ ...thumbnailData });

    const categoryValue =
      !_.isNull(postData.category_id) && !_.isUndefined(postData.category_id)
        ? categoryList.find((v) => v.id === postData.category_id)
        : "";
    if (!_.isEmpty(categoryValue) && !bool) {
      GetSubCategoryListByCategoryApi(categoryValue?.id);
    }

    const subCategoryValue =
      !_.isNull(postData.sub_category_id) &&
      !_.isUndefined(postData.sub_category_id)
        ? allSubcategoryList.find((v) => v.id === postData.sub_category_id)
        : "";

    const conditionValue =
      !_.isNull(postData.condition_id) && !_.isUndefined(postData.condition_id)
        ? conditionList.find((v) => v.id === postData.condition_id)
        : "";

    const productNameValue =
      !_.isEmpty(postData.name) &&
      !_.isNull(postData.name) &&
      !_.isUndefined(postData.name)
        ? postData.name
        : "";

    const productDescValue =
      !_.isEmpty(postData.description) &&
      !_.isNull(postData.description) &&
      !_.isUndefined(postData.description)
        ? postData.description
        : "";

    const productQuantityValue =
      !_.isNull(postData.quantity) && !_.isUndefined(postData.quantity)
        ? postData?.quantity
        : "";

    const unitValue =
      !_.isEmpty(postData.unit) &&
      !_.isNull(postData.unit) &&
      !_.isUndefined(postData.unit)
        ? unitList.find((v) => v.name === postData.unit)
        : "";

    const supplyValue =
      !_.isEmpty(postData.supply) &&
      !_.isNull(postData.supply) &&
      !_.isUndefined(postData.supply)
        ? supplyList.find((v) => v.value === postData.supply)
        : "";

    const sameAsCompanyAddress =
      !_.isNull(postData.same_company_address) &&
      !_.isUndefined(postData.same_company_address)
        ? postData?.same_company_address == 1
        : false;

    const zipValue =
      !_.isEmpty(postData.zipcode) &&
      !_.isNull(postData.zipcode) &&
      !_.isUndefined(postData.zipcode)
        ? postData?.zipcode
        : "";

    const countryValue =
      !_.isNull(postData.country_code) && !_.isUndefined(postData.country_code)
        ? countryList.find((v) => v.country_code === postData.country_code)
        : "";

    const stateValue =
      !_.isEmpty(postData.state) &&
      !_.isNull(postData.state) &&
      !_.isUndefined(postData.state)
        ? postData?.state
        : "";
    const cityValue =
      !_.isEmpty(postData.city) &&
      !_.isNull(postData.city) &&
      !_.isUndefined(postData.city)
        ? postData?.city
        : "";
    const streetValue =
      !_.isEmpty(postData.street_name) &&
      !_.isNull(postData.street_name) &&
      !_.isUndefined(postData.street_name)
        ? postData?.street_name
        : "";

    const currencyValue =
      !_.isNull(postData.currency_code) &&
      !_.isUndefined(postData.currency_code)
        ? currencyList.find((v) => v.code === postData.currency_code)
        : "";

    const priceValue =
      postData.unit_price > 0 &&
      !_.isNull(postData.unit_price) &&
      !_.isUndefined(postData.unit_price)
        ? postData?.unit_price
        : "";

    const termsValue =
      !_.isEmpty(postData.pricing_term) &&
      !_.isNull(postData.pricing_term) &&
      !_.isUndefined(postData.pricing_term)
        ? pricingTermsList.find((v) => v.name === postData.pricing_term)
        : {};

    const targetedCountryAllValue = postData?.target_all == "1" ? true : false;

    const selectedTargetedCountry =
      !_.isEmpty(postData?.targeted_country_code) &&
      _.isArray(postData?.targeted_country_code) &&
      !targetedCountryAllValue
        ? countryList.filter(
            (v) =>
              postData?.targeted_country_code?.find(
                (id) => v.country_code === id
              ) && v
          )
        : [];

    const isPricePerRequest =
      !_.isNull(postData.price_per_request) &&
      !_.isUndefined(postData.price_per_request)
        ? postData?.price_per_request == 1
        : false;

    const disable = postData?.status === "draft" ? false : true;

    setCategory(categoryValue);
    setSubCategory(subCategoryValue);
    setCondition(conditionValue);
    setProductName(productNameValue);
    setProductDesc(productDescValue);
    setProductQuantity(productQuantityValue);
    setUnit(unitValue);
    setSupply(supplyValue);
    setCheck(sameAsCompanyAddress);
    setZipPostalCode(zipValue);
    setCountry(countryValue);
    setState(stateValue);
    setCity(cityValue);
    setStreetName(streetValue);
    setPerUnitPrice(String(priceValue));
    setPricingTerms(termsValue);
    setTargetCountry(selectedTargetedCountry);
    setRequest(isPricePerRequest);
    setCurrencyCode(currencyValue);
    setCurrencySymbol(currencyValue?.currency_symbol);
    setSaveAsDraftDisable(disable);
    setAllTargetedCountry(targetedCountryAllValue);
    setPageLoad(false);
  };

  const allErrorFalse = () => {
    setCategoryErrorText("");
    setSubCategoryErrorText("");
    setProductNameErrorText("");
    setProductDescErrorText("");
    setConditionErrorText("");
    setProductQuantityErrorText("");
    setUnitErrorText("");
    setSupplyErrorText("");
    setPerUnitPriceErrorText("");
    setPricingTermsErrorText("");
    setZipPostalCodeErrorText("");
    setCountryErrorText("");
    setStateErrorText("");
    setCityErrorText("");
    setStreetNameErrorText("");
    setTargetCountryErrorText("");
    setImagesErrorText("");
    setVideoErrorText("");
  };

  //sold status
  async function ChangeSoldStatus() {
    setLoader(true);
    try {
      const data = {
        post_id: postId,
      };
      const response = await getApiData(
        BaseSetting.endpoint.postSold,
        "POST",
        data
      );
      if (response.status) {
        toast(response.message, { type: "success" });
        setLoader(false);
        setVisible(false);
        getOnePostData();
      } else {
        toast(response?.message, { type: "error" });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  }

  // Google pincode api
  const GetDataFromPincodeApi = async (code) => {
    try {
      const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${code}&key=${BaseSetting.GOOGLE_PIN_CODE_API_KEY}`;
      const result = await axios.get(endpoint);
      const pinCode = result?.data?.results?.[0];
      const address = pinCode?.address_components;
      if (!isEmpty(pinCode) && !_.isEmpty(address) && _.isArray(address)) {
        setPinCodeData(pinCode);
        address?.map((data) => {
          data?.types?.map((item) => {
            if (item === "country") {
              const conValue = countryList.find(
                (v) => v.name == data.long_name
              );
              setCountry(conValue);
            }
            if (item === "administrative_area_level_1") {
              setState(data?.long_name);
            }
            if (item === "locality") {
              setCity(data?.long_name);
            }
          });
        });
      } else {
        toast("Invalid pincode", { type: "error" });
        setCountry({});
        setState("");
        setCity("");
      }
    } catch (err) {
      toast(err.message, { type: "error" });
    }
  };

  const pincodeValidate = (val) => {
    if (/^[0-9]*$/.test(String(val))) {
      if (val.length >= 4) {
        GetDataFromPincodeApi(val);
      } else {
        setPinCodeData({});
        setCountry({});
        setCity("");
        setState("");
      }
    } else {
      setZipPostalCodeErrorText(translate("number_character_allow_error"));
    }
  };

  const createPostValidation = () => {
    const descriptionRegex = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    const onlyNumberRegex = /^[0-9]*$/;
    const onlyCharactersRegex = /^[a-zA-Z\s]+$/;
    const decimalRegex = /^\d*\.?\d*$/;

    const nameWithSpecialCharRegex = /^[\p{L}\-\s]+$/u;

    let isValid = true;

    if (_.isEmpty(category)) {
      setCategoryErrorText(translate("category_empty_error"));
      isValid = false;
    }
    if (_.isEmpty(subCategory)) {
      setSubCategoryErrorText(translate("sub_category_empty_error"));
      isValid = false;
    }
    if (_.isEmpty(condition)) {
      window.scrollTo(0, 0);
      setConditionErrorText(translate("condition_empty_error"));
    }
    if (productName.trim() === "") {
      setProductNameErrorText(translate("product_name_empty_error"));
      isValid = false;
    }
    if (
      !descriptionRegex.test(String(productName)) &&
      productName.trim() !== ""
    ) {
      setProductNameErrorText(translate("number_character_not_allow_error"));
      isValid = false;
    }
    if (productDesc.trim() === "") {
      setProductDescErrorText(translate("product_description_empty_error"));
      isValid = false;
    }
    if (
      !descriptionRegex.test(String(productDesc)) &&
      productDesc.trim() !== ""
    ) {
      setProductDescErrorText(translate("product_description_not_valid_error"));
      isValid = false;
    }
    if (productQuantity <= 0) {
      setProductQuantityErrorText(translate("product_quantity_empty_error"));
      isValid = false;
    }
    if (!onlyNumberRegex.test(String(productQuantity))) {
      setProductQuantityErrorText(translate("number_character_allow_error"));
      isValid = false;
    }
    if (_.isEmpty(unit)) {
      setUnitErrorText(translate("product_unit_empty_error"));
      isValid = false;
    }
    if (_.isEmpty(supply)) {
      setSupplyErrorText(translate("product_supply_empty_error"));
      isValid = false;
    }
    if (_.isEmpty(zipPostalCode)) {
      setZipPostalCodeErrorText(translate("pincode_empty_error"));
      isValid = false;
    }
    if (_.isEmpty(country)) {
      setCountryErrorText(translate("business_country_empty_error"));
      isValid = false;
    }
    if (_.isEmpty(state)) {
      setStateErrorText(translate("state_empty_error"));
      isValid = false;
    }
    if (!nameWithSpecialCharRegex.test(String(state)) && !_.isEmpty(state)) {
      setStateErrorText(translate("character_allow_error"));
      isValid = false;
    }
    if (_.isEmpty(city)) {
      setCityErrorText(translate("city_empty_error"));
      isValid = false;
    }
    if (!nameWithSpecialCharRegex.test(String(city)) && !_.isEmpty(city)) {
      setCityErrorText(translate("character_allow_error"));
      isValid = false;
    }
    //   if (_.isEmpty(streetName)) {
    //   window.scrollTo(1000, 1000);
    //   allErrorFalse();
    //   setStreetNameErrorText(translate("business_street_name_empty_error"));
    // }
    //  if (!descriptionRegex.test(String(streetName))) {
    //   window.scrollTo(1000, 1000);
    //   allErrorFalse();
    //   setStreetNameErrorText(translate("business_street_name_not_valid_error"));
    // }
    if (!request && _.isEmpty(perUnitPrice)) {
      setPerUnitPriceErrorText(translate("per_unit_price_empty_error"));
      isValid = false;
    }
    if (
      !request &&
      !decimalRegex.test(String(perUnitPrice)) &&
      _.isEmpty(perUnitPrice)
    ) {
      setPerUnitPriceErrorText(translate("per_unit_price_not_valid_error"));
      isValid = false;
    }
    if (
      !request &&
      !onlyNumberRegex.test(String(perUnitPrice)) &&
      !_.isEmpty(perUnitPrice)
    ) {
      setPerUnitPriceErrorText(translate("per_unit_price_not_valid_error"));
      isValid = false;
    }
    if (!request && _.isEmpty(currencyCode)) {
      setCurrencyErrorText(translate("empty_currency_error"));
      isValid = false;
    }
    if (!request && perUnitPrice <= 0 && _.isEmpty(perUnitPrice)) {
      setPerUnitPriceErrorText(translate("per_unit_price_not_valid_error"));
      isValid = false;
    }
    if (!request && _.isEmpty(pricingTerms)) {
      setPricingTermsErrorText(translate("pricing_terms_empty_error"));
      isValid = false;
    }
    if (_.isEmpty(targetCountry) && !allTargetedCountry) {
      setTargetCountryErrorText(translate("targeted_country_empty_error"));
      isValid = false;
    }
    if (_.isEmpty(images)) {
      setImagesErrorText(translate("image_empty_error"));
      toast(translate("image_empty_error"), { type: "error" });
      isValid = false;
      // }  if (_.isEmpty(video)) {
      //   window.scrollTo(0, 0);
      //   allErrorFalse();
      //   setVideoErrorText("Please select a video");
    } else {
      if (isValid) {
        allErrorFalse();
        UpdatePostApi("post");
      }
    }
  };

  const draftPostValidation = () => {
    const descriptionRegex = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    let isValid = true;
    if (_.isEmpty(category)) {
      setCategoryErrorText(translate("category_empty_error"));
      isValid = false;
    } else if (_.isEmpty(subCategory)) {
      setSubCategoryErrorText(translate("sub_category_empty_error"));
      isValid = false;
    } else if (_.isEmpty(condition)) {
      setConditionErrorText(translate("condition_empty_error"));
      isValid = false;
    } else if (productName.trim() === "") {
      setProductNameErrorText(translate("product_name_empty_error"));
      isValid = false;
    } else if (
      !descriptionRegex.test(String(productName)) &&
      productName.trim() !== ""
    ) {
      setProductNameErrorText(translate("number_character_not_allow_error"));
      isValid = false;
    } else if (productDesc.trim() === "") {
      setProductDescErrorText(translate("product_description_empty_error"));
      isValid = false;
    } else if (
      !descriptionRegex.test(String(productDesc)) &&
      productDesc.trim !== ""
    ) {
      setProductDescErrorText(translate("product_description_not_valid_error"));
      isValid = false;
    } else {
      UpdatePostApi("draft");
    }
  };

  const appendLiveImageInArray = (liveImages, localImages, id) => {
    let imageArr = localImages;
    const liveImageLength = liveImages?.length;

    if (liveImageLength > 0) {
      imageArr?.splice(0, 1, liveImages[liveImageLength - 1]);
      const findLocalImage = imageArr?.filter(
        (v) => _.isUndefined(v.id) || _.isUndefined(v.url)
      );
      const localImagesLength = findLocalImage.length;
      if (localImagesLength > 0) {
        SingleImageUploadApi(findLocalImage, id);
      }
      setImages([...liveImages]);
    }
  };

  const ChangeMainImageApi = (image_id, post_id) => {
    const endpoint = BaseSetting.endpoint.changeMainImage;
    const data = { image_id: image_id, post_id: post_id };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          getOnePostData();
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };
  const UpdatePostApi = (type) => {
    const endpoint = BaseSetting.endpoint.updatePost;
    let data = {
      post_id: postId,
      post_type: "Sell",
      api_type: "web",
      type: type,
      category_id: category?.id,
      sub_category_id: subCategory?.id,
      condition_id: condition?.id,
      name: productName,
      description: productDesc,
      quantity: productQuantity,
      unit: unit?.name,
      price_per_request: request ? 1 : 0,
      zipcode: zipPostalCode,
      state: state,
      city: city,
      same_company_address: check && userData?.isProfileComplete ? "1" : "0",
      street_name: streetName,
    };
    if (supply) {
      data.supply = supply?.value;
    }
    if (!request) {
      data.unit_price = perUnitPrice;
      data.pricing_term = pricingTerms?.name;
      data.currency_code = currencyCode?.currency_code;
    }
    if (!_.isEmpty(country)) {
      data.country = country?.name;
      data.country_code = country?.country_code;
    }
    if (allTargetedCountry) {
      data.target_all = "true";
    } else {
      if (!_.isEmpty(targetCountry)) {
        const targetCountryName = targetCountry
          ?.map((data) => `'${data?.name}'`)
          .join(", ");
        const targetCountryCode = targetCountry
          ?.map((data) => `'${data?.country_code}'`)
          .join(", ");

        data.target_country = targetCountryName;
        data.targeted_country_code = targetCountryCode;
      }
    }

    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          if (type === "post") {
            navigate("/myOffers");
            setPostBtnLoader(false);
          } else {
            navigate("/myOffers");
            setDraftBtnLoader(false);
          }
          toast(result?.message, { type: "success" });
        } else {
          if (type === "post") {
            toast(result?.message, { type: "warning" });
            setPostBtnLoader(false);
          } else {
            toast(result?.message, { type: "warning" });
            setDraftBtnLoader(false);
          }
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        if (type === "post") {
          setPostBtnLoader(false);
        } else {
          setDraftBtnLoader(false);
        }
      });
  };

  const SingleImageUploadApi = (image, id) => {
    const form = new FormData();
    const type = !_.isUndefined(image[0]?.type)
      ? image[0]?.type?.split("/")
      : [];
    const fileType = !_.isEmpty(type) && _.isArray(type) ? type[0] : "";

    form.append("file_type", fileType);
    form.append("post_image", image[0]);
    form.append("type", "Sell");
    if (id && !_.isUndefined(id) && !_.isNull(id)) {
      form.append("post_id", id);
    }

    const endpoint = BaseSetting.endpoint.singlePostImageUpload;
    getAxiosApi(endpoint, "post", form)
      .then((result) => {
        if (result?.status) {
          if (result?.data?.type === "image_courupt") {
            toast(result?.message, { type: "error" });
            if (
              result?.data?.id &&
              !_.isUndefined(result?.data?.id) &&
              !_.isNull(result?.data?.id)
            ) {
              getOnePostData(image, fileType);
            } else {
              setImages([]);
            }
          } else {
            getOnePostData(image, fileType);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  const mainImage =
    !_.isEmpty(images) && _.isArray(images)
      ? images.filter((v) => v?.main_image == 1)
      : null;

  return (
    <div style={{ background: BaseColor.whiteColor }}>
      <CHeader />
      <Grid container alignItems="center" justifyContent="center" spacing={0}>
        <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
        <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={9}
              md={9}
              lg={7.8}
              xl={7.6}
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <CPageHeader
                title={translate("home_title")}
                titleNav="/products"
                subTitle={translate("edit_post_title")}
                subSubTitle={translate("sell_post_title")}
                isSpaceRequired={false}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={4.2}
              xl={4.4}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CButton
                disabled={mainPostData?.status === "sold" ? true : false}
                variant="contained"
                onClick={() => {
                  setVisible(true);
                }}
                loading={loader}
              >
                {translate(
                  mainPostData?.status === "sold" ? "sold" : "mark_as_sold"
                )}
              </CButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
      </Grid>
      {mainPostData?.status === "rejected" && close && (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          columnSpacing={sm ? 0 : 4}
        >
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}></Grid>
          <Grid
            item
            xs={12}
            sm={11}
            md={4.2}
            lg={3.75}
            xl={3.8}
            alignItems="center"
            justifyContent="flex-end"
            padding={sm ? 2 : 0}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: BaseColor.offWhite,
                borderRadius: 10,
                flexDirection: "row",
                display: "flex",
                justifyContent: md ? "flex-end" : "",
              }}
            >
              <div
                style={{
                  width: "20%",
                  backgroundColor: "red",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                }}
              >
                <div
                  style={{
                    border: "2px solid ",
                    borderColor: BaseColor.offWhite,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CloseIcon
                    style={{ color: BaseColor.offWhite, fontSize: 30 }}
                  />
                </div>
              </div>
              <div style={{ width: "60%", padding: 7, flexWrap: "wrap" }}>
                <Typography
                  style={{
                    fontFamily: FontFamily.Bold,
                    fontWeight: "900",
                    fontSize: 14,
                    color: BaseColor.blackColor,
                  }}
                >
                  {translate("rejected")}
                </Typography>
                <Typography
                  style={{
                    fontFamily: FontFamily.Medium,
                    fontSize: 12,
                    color: BaseColor.blackColor,
                  }}
                >
                  {mainPostData?.reject_reason &&
                  mainPostData?.reject_reason.length > 150
                    ? mainPostData?.reject_reason.substring(0, 150) + "..."
                    : mainPostData?.reject_reason}
                  {/* {mainPostData?.reject_reason} */}
                </Typography>
              </div>
              <div
                style={{
                  width: "20%",
                  borderLeft: "1px solid ",
                  borderColor: BaseColor.white,
                  // height: 60,
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor:'blue'
                }}
                onClick={() => {
                  setClose(false);
                }}
              >
                <Typography
                  style={{
                    fontFamily: FontFamily.Medium,
                    fontSize: sm ? 12 : 14,
                    color: BaseColor.textGray,
                  }}
                >
                  {translate("close")}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      {pageLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "50vh",
          }}
        >
          <CircularProgress style={{ color: BaseColor.primary }} size={30} />
        </div>
      ) : (
        <>
          <Grid container style={{ marginBottom: "5%" }}>
            <Grid container xs={0.5} sm={0.5} md={0.5} lg={1} xl={1}></Grid>
            <Grid
              container
              xs={11}
              sm={11}
              md={11}
              lg={10}
              xl={10}
              marginTop={2}
            >
              <Grid item xs={12} lg={7}>
                <Grid container mb={2}>
                  <Typography
                    style={{
                      fontFamily: FontFamily.SemiBold,
                      fontWeight: "900",
                      fontSize: sm ? 18 : 20,
                      color: BaseColor.primary,
                    }}
                  >
                    {translate("product_details_title")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    backgroundColor: BaseColor.offWhite,
                    padding: "15px",
                    borderRadius: "5px",
                    justifyContent: "space-between",
                  }}
                  // gap={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CAutoComplete
                      selectedValue={category}
                      label={translate("category")}
                      options={categoryList}
                      onSelect={(val) => {
                        setCategory(val);
                        GetSubCategoryListByCategoryApi(val.id);
                        setSubCategory({});
                        setCategoryErrorText("");
                      }}
                      errorMsg={categoryErrorText}
                      popOverStyle={{
                        width: sm ? "85.8%" : lg ? "43.5%" : "22.5%",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CAutoComplete
                      selectedValue={subCategory}
                      label={translate("sub_category")}
                      options={subCategoryList}
                      onSelect={(val) => {
                        setSubCategory(val);
                        setSubCategoryErrorText("");
                      }}
                      noCategory={_.isEmpty(category)}
                      errorMsg={subCategoryErrorText}
                      popOverStyle={{
                        width: sm ? "85.8%" : lg ? "43.5%" : "22.5%",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CAutoComplete
                      selectedValue={condition}
                      label={translate("condition")}
                      options={conditionList}
                      onSelect={(val) => {
                        setCondition(val);
                        setConditionErrorText("");
                      }}
                      errorMsg={conditionErrorText}
                      popOverStyle={{
                        width: sm ? "85.8%" : lg ? "43.5%" : "22.5%",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CInput
                      placeholder={translate("product_name")}
                      value={productName}
                      onChange={(val) => {
                        setProductName(val);
                        setProductNameErrorText("");
                      }}
                      errorMsg={productNameErrorText}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <CInput
                      placeholder={translate("product_description")}
                      value={productDesc}
                      onChange={(val) => {
                        setProductDesc(val);
                        setProductDescErrorText("");
                      }}
                      multiline
                      rows={6}
                      errorMsg={productDescErrorText}
                    />
                  </Grid>
                </Grid>
                <Grid container mb={2} marginTop={5}>
                  <Typography
                    style={{
                      fontFamily: FontFamily.SemiBold,
                      fontWeight: "900",
                      fontSize: sm ? 18 : 20,
                      color: BaseColor.primary,
                    }}
                  >
                    {translate("product_stock_detail_title")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    backgroundColor: BaseColor.offWhite,
                    padding: "15px",
                    borderRadius: "5px",
                    justifyContent: "space-between",
                  }}
                  // gap={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CInput
                      placeholder={translate("product_quantity_placeholder")}
                      type="number"
                      value={productQuantity}
                      onChange={(val) => {
                        setProductQuantity(val);
                        setProductQuantityErrorText("");
                      }}
                      errorMsg={productQuantityErrorText}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CAutoComplete
                      selectedValue={unit}
                      label={translate("unit")}
                      options={unitList}
                      onSelect={(val) => {
                        setUnit(val);
                        setUnitErrorText("");
                      }}
                      errorMsg={unitErrorText}
                      popOverStyle={{
                        width: sm ? "85.8%" : lg ? "43.5%" : "22.5%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                    <CAutoComplete
                      selectedValue={supply}
                      label={translate("supply")}
                      options={supplyList}
                      onSelect={(val) => {
                        setSupply(val);
                        setSupplyErrorText("");
                      }}
                      errorMsg={supplyErrorText}
                      popOverStyle={{
                        width: sm ? "85.8%" : lg ? "43.5%" : "22.5%",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container mb={2} marginTop={5}>
                  <Typography
                    style={{
                      fontFamily: FontFamily.SemiBold,
                      fontWeight: "900",
                      fontSize: sm ? 18 : 20,
                      color: BaseColor.primary,
                    }}
                  >
                    {translate("address_detail_title")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    backgroundColor: BaseColor.offWhite,
                    padding: "15px",
                    borderRadius: "5px",
                    justifyContent: "space-between",
                  }}
                  // gap={2}
                >
                  {userData?.isProfileComplete && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontFamily: FontFamily.Medium,
                        margin: "0px 0px 10px 0px",
                        marginRight: 2,
                      }}
                    >
                      <CCheckBox
                        checked={check}
                        onChange={(val) => {
                          setCheck(!check);

                          const businessInfoData =
                            !_.isEmpty(userData?.business_info) &&
                            !_.isUndefined(userData?.business_info) &&
                            !_.isNull(userData?.business_info)
                              ? userData?.business_info
                              : {};

                          const zipCodeFromBusiness =
                            !_.isEmpty(businessInfoData?.zipcode) &&
                            !_.isUndefined(businessInfoData?.zipcode) &&
                            !_.isNull(businessInfoData?.zipcode) &&
                            val
                              ? businessInfoData?.zipcode
                              : "";

                          const countryFromBusiness =
                            !_.isEmpty(businessInfoData?.country) &&
                            !_.isUndefined(businessInfoData?.country) &&
                            !_.isNull(businessInfoData?.country) &&
                            val
                              ? countryList.find(
                                  (v) => v.name == businessInfoData?.country
                                )
                              : {};
                          const stateFromBusiness =
                            !_.isEmpty(businessInfoData?.state) &&
                            !_.isUndefined(businessInfoData?.state) &&
                            !_.isNull(businessInfoData?.state) &&
                            val
                              ? businessInfoData?.state
                              : "";
                          const cityFromBusiness =
                            !_.isEmpty(businessInfoData?.city) &&
                            !_.isUndefined(businessInfoData?.city) &&
                            !_.isNull(businessInfoData?.city) &&
                            val
                              ? businessInfoData?.city
                              : "";
                          const streetNameFromBusiness =
                            !_.isEmpty(businessInfoData?.street_name) &&
                            !_.isUndefined(businessInfoData?.street_name) &&
                            !_.isNull(businessInfoData?.street_name) &&
                            val
                              ? businessInfoData?.street_name
                              : "";

                          setZipPostalCode(zipCodeFromBusiness);
                          setCountry(countryFromBusiness);
                          setState(stateFromBusiness);
                          setCity(cityFromBusiness);
                          setStreetName(streetNameFromBusiness);
                        }}
                        style={{
                          color: BaseColor.primary,
                          padding: 0,
                          marginRight: 5,
                        }}
                      />
                      <Typography style={{ fontFamily: FontFamily.Medium }}>
                        {translate("same_as_company_address")}
                      </Typography>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CInput
                      placeholder={translate("business_pincode_placeholder")}
                      value={zipPostalCode}
                      disabled={check}
                      onChange={(val) => {
                        setZipPostalCode(val);
                        setZipPostalCodeErrorText("");
                        setPinCodeData({});
                        setCountry({});
                        setState("");
                        setCity("");
                      }}
                      onBlur={() => pincodeValidate(zipPostalCode)}
                      errorMsg={zipPostalCodeErrorText}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CAutoComplete
                      disabled={check}
                      selectedValue={country}
                      label={translate("country_placeholder")}
                      options={countryList}
                      onSelect={(val) => {
                        setCountry(val);
                        setCountryErrorText("");
                      }}
                      errorMsg={countryErrorText}
                      popOverStyle={{
                        width: sm ? "85.8%" : lg ? "43.5%" : "22.5%",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CInput
                      placeholder={translate("state_placeholder")}
                      value={state}
                      disabled={check}
                      onChange={(val) => {
                        setState(val);
                        setStateErrorText("");
                      }}
                      errorMsg={stateErrorText}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CInput
                      placeholder={translate("city_placeholder")}
                      value={city}
                      disabled={check}
                      onChange={(val) => {
                        setCity(val);
                        setCityErrorText("");
                      }}
                      errorMsg={cityErrorText}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5.8} md={5.87} lg={5.79}>
                    <CInput
                      placeholder={translate("area_placeholder")}
                      value={streetName}
                      disabled={check}
                      onChange={(val) => {
                        setStreetName(val);
                        setStreetNameErrorText("");
                      }}
                      errorMsg={streetNameErrorText}
                    />
                  </Grid>
                </Grid>
                <Grid container mb={2} marginTop={5}>
                  <Typography
                    style={{
                      fontFamily: FontFamily.SemiBold,
                      fontWeight: "900",
                      fontSize: sm ? 18 : 20,
                      color: BaseColor.primary,
                    }}
                  >
                    {translate("product_pricing_detail_title")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    backgroundColor: BaseColor.offWhite,
                    padding: "15px",
                    borderRadius: "5px",
                    justifyContent: "space-between",
                  }}
                  // gap={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CInput
                      placeholder={translate("per_unit_price_placeholder")}
                      value={perUnitPrice}
                      disabled={request}
                      onChange={(val) => {
                        setPerUnitPrice(val);
                        setPerUnitPriceErrorText("");
                      }}
                      startIcon={
                        <>
                          <CAutoComplete
                            options={currencyList}
                            // label="Currency"
                            currency
                            selectedValue={currencyCode}
                            onSelect={(val) => {
                              setCurrencySymbol(val?.currency_symbol);
                              setCurrencyCode(val);
                            }}
                            style={{ outline: "none", width: "75px" }}
                          />
                          <div
                            style={{
                              height: 25,
                              borderRight: `1px solid ${BaseColor.primary}`,
                            }}
                          />
                        </>
                      }
                      errorMsg={perUnitPriceErrorText}
                    />
                    {!_.isEmpty(currencyErrorText) && (
                      <div style={{ marginTop: 5, marginLeft: 2 }}>
                        <span
                          style={{
                            fontSize: 14,
                            color: BaseColor.errorRed,
                            fontFamily: FontFamily.Medium,
                          }}
                        >
                          {currencyErrorText}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5.8}
                    md={5.87}
                    lg={5.79}
                    style={{ marginBottom: 15 }}
                  >
                    <CAutoComplete
                      disabled={request}
                      selectedValue={pricingTerms}
                      label={translate("pricing_terms_placeholder")}
                      options={pricingTermsList}
                      onSelect={(val) => {
                        setPricingTerms(val);
                        setPricingTermsErrorText("");
                      }}
                      errorMsg={pricingTermsErrorText}
                      popOverStyle={{
                        width: sm ? "85.8%" : lg ? "43.5%" : "22.5%",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: FontFamily.Medium,
                      marginTop: "10px",
                      marginRight: 2,
                    }}
                  >
                    <CCheckBox
                      checked={request}
                      onChange={(val) => {
                        setPerUnitPrice("");
                        setPricingTerms("");
                        setRequest(val);
                        if (!val) {
                          setCurrencyCode(
                            !_.isNull(mainPostData.currency_code) &&
                              !_.isUndefined(mainPostData.currency_code)
                              ? currencyList.find(
                                  (v) => v.code === mainPostData.currency_code
                                )
                              : currencyList.find(
                                  (v) =>
                                    v.country_code ===
                                    userData?.business_info?.country_code
                                )
                          );
                        } else {
                          setCurrencyCode({});
                        }
                      }}
                      style={{
                        color: BaseColor.primary,
                        padding: 0,
                        marginRight: 5,
                      }}
                    />
                    <Typography style={{ fontFamily: FontFamily.Medium }}>
                      {translate("price_on_req")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container mb={2} marginTop={5}>
                  <Typography
                    style={{
                      fontFamily: FontFamily.SemiBold,
                      fontWeight: "900",
                      fontSize: sm ? 18 : 20,
                      color: BaseColor.primary,
                    }}
                  >
                    {translate("product_target_detail_title")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    backgroundColor: BaseColor.offWhite,
                    padding: "15px",
                    borderRadius: "5px",
                  }}
                  gap={2}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={5.9}
                      md={5.9}
                      lg={5.9}
                      xl={5.9}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <CCheckBox
                        checked={allTargetedCountry}
                        onChange={(val) => {
                          setAllTargetedCountry(!allTargetedCountry);
                          setTargetCountry([]);
                        }}
                        style={{
                          color: BaseColor.primary,
                          padding: 0,
                          marginRight: 5,
                        }}
                      />
                      <Typography style={{ fontFamily: FontFamily.Medium }}>
                        {translate("all")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <Autocomplete
                      disabled={allTargetedCountry}
                      value={targetCountry}
                      multiple
                      disableCloseOnSelect
                      id="tags-outlined"
                      options={!isEmpty(countryList) ? countryList : []}
                      getOptionLabel={(option) => option.name}
                      onChange={(a, b, c, d) => {
                        allErrorFalse();
                        setTargetCountry(b);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Targeted country"
                        />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <CCheckBox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      sx={{
                        backgroundColor: BaseColor.whiteColor,
                        fontFamily: FontFamily?.Medium,
                        "& .MuiOutlinedInput-root": {
                          padding: "2px 7px",
                        },
                      }}
                    /> */}
                    <CAutoComplete
                      disabled={allTargetedCountry}
                      options={countryList}
                      multiple
                      label={translate("target_country_placeholder")}
                      selectedValue={targetCountry}
                      onSelect={(val) => {
                        setTargetCountry(val);
                      }}
                      errorMsg={targetCountryErrorText}
                      popOverStyle={{
                        width: sm ? "85.8%" : lg ? "43.5%" : "22.5%",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={5}
                paddingLeft={lg ? 0 : 10}
                marginTop={lg ? (sm ? 5 : 10) : 0}
                marginBottom={sm && 5}
              >
                <Grid container>
                  <Typography
                    style={{
                      fontFamily: FontFamily.SemiBold,
                      fontWeight: "900",
                      fontSize: sm ? 18 : 20,
                      color: BaseColor.primary,
                    }}
                  >
                    {translate("images_and_videos")}
                  </Typography>
                  <Grid
                    item
                    container
                    sx={{
                      backgroundColor: BaseColor.offWhite,
                      borderRadius: "5px",
                      padding: "20px",
                    }}
                    marginTop={2}
                    rowGap={2}
                  >
                    <Grid item xs={12}>
                      {!_.isEmpty(mainImage) && (
                        <div
                          style={{
                            marginBottom: 30,
                          }}
                        >
                          <CImageUpload file={mainImage} hideRemoveBtn />
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            color: BaseColor.textColor,
                            fontFamily: FontFamily.SemiBold,
                            fontWeight: "900",
                          }}
                        >
                          {translate("more_images")}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 12,
                            marginLeft: 8,
                            color: BaseColor.textGray,
                            fontFamily: FontFamily.SemiBold,
                            fontWeight: "900",
                          }}
                        >
                          ({translate("post_image_upload_supported_format")})
                        </Typography>
                      </div>
                      <Typography
                        style={{
                          fontFamily: FontFamily.RobotoMedium,
                          fontSize: "12px",
                          color: BaseColor.red,
                          marginBottom: 10,
                        }}
                      >
                        {translate("image_upload_note")}
                      </Typography>
                      <CImageUpload
                        multiple
                        file={images}
                        onChange={(val) => {
                          setImages([...val]);
                        }}
                        onChangeMainImage={(id) => {
                          ChangeMainImageApi(id, postId);
                        }}
                        onUploadImage={(val) => {
                          const findLocalImage = val?.filter(
                            (v) => _.isUndefined(v.id) || _.isUndefined(v.url)
                          );
                          SingleImageUploadApi(findLocalImage, postId);
                        }}
                        onRemoveImageSuccess={(bool) => {
                          if (bool) {
                            getOnePostData(bool);
                          }
                        }}
                        errorMsg={imagesErrorText}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: 10,
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            color: BaseColor.textColor,
                            fontFamily: FontFamily.SemiBold,
                            fontWeight: "900",
                          }}
                        >
                          {translate("video")}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 12,
                            marginLeft: 8,
                            color: BaseColor.textGray,
                            fontFamily: FontFamily.SemiBold,
                            fontWeight: "900",
                          }}
                        >
                          ({translate("post_video_upload_supported_format")})
                        </Typography>
                      </div>

                      <Typography
                        style={{
                          fontFamily: FontFamily.RobotoMedium,
                          fontSize: "12px",
                          color: BaseColor.red,
                          marginBottom: 10,
                        }}
                      >
                        {translate("video_upload_note")}
                      </Typography>

                      <CImageUpload
                        video
                        file={video}
                        thumbnail={thumbnail}
                        onChange={(val) => {
                          setVideo([...val]);
                        }}
                        onUploadImage={(val) => {
                          const findLocalImage = val?.filter(
                            (v) => _.isUndefined(v.id) || _.isUndefined(v.url)
                          );
                          SingleImageUploadApi(findLocalImage, postId);
                        }}
                        onRemove={() => {
                          setRemoveVideo([]);
                        }}
                        errorMsg={videoErrorText}
                        onRemoveImageSuccess={(bool) => {
                          if (bool) {
                            getOnePostData(bool);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={0.5} sm={0.5} md={0.5} lg={1} xl={1}></Grid>
          </Grid>

          <Grid container marginBottom={5} marginTop={-5}>
            <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
            <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  flexDirection={"row"}
                  display={"flex"}
                >
                  <Grid
                    container
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={5.8}
                      md={5.8}
                      lg={5.8}
                      xl={5.8}
                      style={{ marginTop: 10 }}
                    >
                      <CButton
                        variant="contained"
                        onClick={() => {
                          createPostValidation();
                        }}
                        loading={postBtnLoader}
                        style={{ width: "100%" }}
                      >
                        {translate("post_button")}
                      </CButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5.8}
                      md={5.8}
                      lg={5.8}
                      xl={5.8}
                      style={{ marginTop: 10 }}
                    >
                      <CButton
                        variant="outlined"
                        onClick={() => {
                          draftPostValidation();
                        }}
                        loading={draftBtnLoader}
                        style={{ width: "100%" }}
                        // disabled={saveAsDraftDisable}
                        disabled={
                          postBtnLoader ? postBtnLoader : saveAsDraftDisable
                        }
                      >
                        {translate("save_as_draft_button")}
                      </CButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={0} sm={0} md={0} lg={6} xl={6} />
              </Grid>
            </Grid>
            <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
          </Grid>
        </>
      )}

      <CFooter />
      <ConfirmModel
        visible={visible}
        handleClose={() => {
          setVisible(false);
        }}
        loader={loader}
        confirmation={() => {
          ChangeSoldStatus();
          setLoader(true);
        }}
        message={translate("mark_as_sold_modal_title")}
      />
    </div>
  );
};

export default EditSellPost;
