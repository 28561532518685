import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import moment from "moment";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/Color";
import { Link } from "@mui/material";
import CLink from "../CLink";
import { translate } from "../../lang/Translate";
const CResendBtn = forwardRef((props, ref) => {
  const { onClick = () => {}, isOTPGenerated = false } = props;

  const countdown = useRef();
  // Timer Value
  const [time, setTime] = useState("00 : 00");

  useImperativeHandle(ref, () => ({
    start(expireAt) {
      countDown(expireAt);
    },
    stop() {
      clearInterval(countdown.current);
      countdown.current = null;
      return;
    },
  }));

  const dateDiffToString = (a, b) => {
    let diff = Math.abs(a - b);

    let ms = diff % 1000;
    diff = (diff - ms) / 1000;
    let ss = diff % 60;
    diff = (diff - ss) / 60;
    let mm = diff % 60;
    diff = (diff - mm) / 60;

    mm = mm < 10 ? "0" + mm : mm;
    ss = ss < 10 ? "0" + ss : ss;

    return `${mm} : ${ss}`;
  };

  const countDown = async (expireAt) => {
    countdown.current = setInterval(function () {
      const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
      const expireTime = moment(expireAt).format("YYYY-MM-DD HH:mm:ss");

      const d1 = moment(currentTime).format();
      const d2 = moment(expireTime).format();

      const dt1 = new Date(d1);
      const dt2 = new Date(d2);

      if (dt1 > dt2) {
        clearInterval(countdown.current);
        countdown.current = null;
        setTime("00 : 00");
      } else {
        const differenceTime = dateDiffToString(dt1, dt2);
        setTime(differenceTime);
      }
    }, 1000);
  };

  const isCountDownStart = time != "00 : 00";
  const isDisabled = isCountDownStart || !isOTPGenerated;
  return (
    <div>
      {isCountDownStart ? (
        <span
          style={{
            cursor: "default",
            fontSize: 16,
            fontFamily: FontFamily.RobotoMedium,
            color: BaseColor.primary,
            marginRight: 10,
          }}
        >
          {time}
        </span>
      ) : (
        <CLink
          title={translate("resend_otp")}
          disabled={isDisabled}
          onClick={() => {
            onClick();
          }}
        />
      )}
    </div>
  );
});
export default CResendBtn;
