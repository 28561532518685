import { InputAdornment, TextField } from "@mui/material";
import styles from "./styles";
import BaseColor from "../../Config/Color";
import _ from "lodash";
import PropTypes from "prop-types";
import { FontFamily } from "../../Config/theme";

const CInput = (props) => {
  const classes = styles();
  const {
    value = "",
    placeholder = "",
    size = "small",
    errorMsg = "",
    variant = "outlined",
    sx = {},
    onChange = () => {},
    startIcon,
    endIcon,
    multiline,
    style,
    rows,
    minRows,
    maxRows,
    maxLength,
    readOnly = false,
    disabled = false,
    type = "",
    disableUnderline,
    onBlur = () => {},
    errorMsgStyle = {},
    required = false,
    price = false,
    hide = false,
  } = props;

  return (
    <>
      <TextField
        required
        placeholder={placeholder}
        variant={variant}
        size={size}
        error={!_.isEmpty(errorMsg)}
        disabled={disabled}
        value={hide ? "" : value}
        style={{ ...style }}
        multiline={multiline}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        type={type}
        onBlur={onBlur}
        sx={[
          {
            width: "100%",
            backgroundColor: BaseColor.whiteColor,
            filter: hide && "blur(4px)",
          },
          sx,
        ]}
        onKeyDown={(evt) => {
          if (type === "number" && (evt.key === "-" || evt.key === "e")) {
            evt.preventDefault();
          }
        }}
        onChange={(e) => {
          if (type === "number" && e.target.value >= 0) {
            const value = Number(e.target.value);
            onChange(e.target.value);
          } else {
            onChange(e.target.value);
          }
        }}
        InputProps={{
          readOnly: readOnly,
          disableUnderline: disableUnderline,
          startAdornment: (
            <div style={{ position: "relative" }}>
              {startIcon && (
                <InputAdornment style={{ cursor: "default" }} position="start">
                  {startIcon}
                </InputAdornment>
              )}
              {required && !multiline && (
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    zIndex: 10,
                    right: price ? -1 : 1,
                    top: -19,
                    fontSize: 20,
                  }}
                >
                  *
                </span>
              )}
              {required && multiline && (
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    zIndex: 10,
                    right: 1,
                    top: -70,
                    fontSize: 20,
                  }}
                >
                  *
                </span>
              )}
            </div>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="start">{endIcon}</InputAdornment>
          ),
        }}
        inputProps={{
          style: { fontSize: "16px", fontFamily: FontFamily.Medium },
          maxLength: maxLength,
          inputMode: type === "number" && "numeric",
          pattern: type === "number" && "[0-9]*",
          min: type === "number" && 1,
        }}
      />

      {!_.isEmpty(errorMsg) && (
        <div className={classes.errorMsgContainer} style={errorMsgStyle}>
          <span className={classes.errorMsgText}>{errorMsg}</span>
        </div>
      )}
    </>
  );
};

CInput.propTypes = {
  variant: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  showError: PropTypes.bool,
  errorMsg: PropTypes.string,
  sx: PropTypes.object,
  errorMsgStyle: PropTypes.object,
  onChange: PropTypes.func,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  disableUnderline: PropTypes.bool,
  required: PropTypes.bool,
};

CInput.defaultProps = {
  value: "",
  placeholder: "",
  showError: false,
  errorMsg: "",
  sx: {},
  onChange: () => {},
};
export default CInput;
