import { Suspense, lazy, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import socket from "./App/Apis/socket";
import BaseColor from "./App/Config/Color";
import { store } from "./App/Redux/Store/configureStore";
import { CircularProgress } from "@mui/material";
import languageAction from "./App/Redux/Reducers/language/actions";

import Error404 from "./App/Pages/Error404";
import EditSellPost from "./App/Pages/EditPost/Sell";
import EditBuyPost from "./App/Pages/EditPost/Buy";
import BaseSetting from "./App/Apis/setting";
import { getApiData } from "./App/Apis/apiHelper";
import { toast } from "react-toastify";
import authActions from "./App/Redux/Reducers/auth/actions";
import { getOS } from "./App/Utils/commonFunctions";
import { initTranslate } from "./App/lang/Translate";
import CDownloadApp from "./App/Components/CDownloadApp";
import { isAndroid, isIOS, isMobile, isTablet } from "react-device-detect";
import AboutUs from "./App/Pages/AboutUs";
import SiteMap from "./App/Pages/SiteMap";

const Login = lazy(() => import("./App/Pages/Login"));
const CreateAccount = lazy(() => import("./App/Pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./App/Pages/ForgotPassword"));
const UserProfile = lazy(() => import("./App/Pages/UserProfile"));
const CreateProfile = lazy(() => import("./App/Pages/CreateProfile"));
const ProductDetail = lazy(() => import("./App/Pages/ProductDetail"));
const Product = lazy(() => import("./App/Pages/Product"));
const MyCart = lazy(() => import("./App/Pages/MyCart"));
const Profile = lazy(() => import("./App/Pages/profile"));
const ContactUs = lazy(() => import("./App/Pages/ContactUs"));
const MyOffers = lazy(() => import("./App/Pages/MyOffers"));
const Favorites = lazy(() => import("./App/Pages/Favorites"));
const Chat = lazy(() => import("./App/Pages/Chat"));
const Alerts = lazy(() => import("./App/Pages/Alerts"));
const SellPost = lazy(() => import("./App/Pages/SellPost"));
const BuyPost = lazy(() => import("./App/Pages/BuyPost"));
const DownloadApp = lazy(() => import("./App/Pages/DownloadApp"));
const TermsAndCondition = lazy(() => import("./App/Pages/TermsAndCondition"));
const PrivacyPolicy = lazy(() => import("./App/Pages/PrivacyPolicy"));
const Logistic = lazy(() => import("./App/Pages/Logistic"));
const SearchResult = lazy(() => import("./App/Pages/SearchResult"));
const Notification = lazy(() => import("./App/Pages/Notification"));

function App() {
  // Set data in Redux
  const dispatch = useDispatch();
  const { setImageFile } = authActions;
  const { userData } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.language);

  const [installAppVisible, setInstallAppVisible] = useState(true);

  useEffect(() => {
    const authState = store?.getState() || {};
    const token = authState?.auth?.accessToken || "";
    const webUrl = window.location.href;
    const webUrlArray = webUrl.split("/");
    const length = !_.isEmpty(webUrlArray) ? webUrlArray.length - 1 : null;
    const pageName = webUrlArray[length];
    const isNotValid =
      pageName !== ""
        ? pageName.includes("login") ||
          pageName.includes("create-account") ||
          pageName.includes("forgot-password") ||
          (userData?.isProfileComplete &&
            pageName.includes("create-profile")) ||
          (!userData?.isProfileComplete && pageName.includes("favorites")) ||
          (!userData?.isProfileComplete && pageName.includes("chat")) ||
          (!userData?.isProfileComplete && pageName.includes("alert")) ||
          (!userData?.isProfileComplete && pageName.includes("sell-post")) ||
          (!userData?.isProfileComplete && pageName.includes("buy-post")) ||
          (!userData?.isProfileComplete && pageName.includes("logistic")) ||
          (!userData?.isProfileComplete && pageName.includes("notification")) ||
          (!userData?.isProfileComplete && pageName === "profile") ||
          (!userData?.isProfileComplete && pageName.includes("buy-post")) ||
          (!userData?.isProfileComplete &&
            pageName.includes("edit-sell-post")) ||
          (!userData?.isProfileComplete && pageName.includes("edit-buy-post"))
        : false;

    const isNotValidWithoutLogin =
      pageName !== ""
        ? pageName.includes("my-cart") ||
          pageName.includes("create-profile") ||
          (!userData?.isProfileComplete &&
            !webUrl.includes("product-detail") &&
            pageName.includes("profile")) ||
          (!userData?.isProfileComplete && pageName.includes("favorites")) ||
          (!userData?.isProfileComplete && pageName.includes("myOffers")) ||
          (!userData?.isProfileComplete && pageName.includes("sell-post")) ||
          (!userData?.isProfileComplete && pageName.includes("buy-post")) ||
          (!userData?.isProfileComplete &&
            pageName.includes("edit-sell-post")) ||
          (!userData?.isProfileComplete &&
            pageName.includes("edit-buy-post")) ||
          (!userData?.isProfileComplete && pageName.includes("chat")) ||
          (!userData?.isProfileComplete && pageName.includes("alert")) ||
          (!userData?.isProfileComplete && pageName.includes("logistic")) ||
          (!userData?.isProfileComplete && pageName.includes("notification"))
        : true;

    if (isMobile || isTablet) {
      setInstallAppVisible(true);
    }

    const page = pageName?.split("?");

    if (pageName != "" && page[0] == "downloadapp") {
      const system_os = getOS();
      const appLink = ["iPhone", "iPod", "iPad"].includes(system_os)
        ? "https://apps.apple.com/in/app/scrapc/id6453885238"
        : "https://play.google.com/store/apps/details?id=com.scrapc";
      window.location.replace(appLink);
    } else {
      if (!_.isEmpty(token)) {
        if (isNotValid) {
          window.location.replace(`/`);
        } else if (
          webUrlArray.includes("product-detail") &&
          webUrlArray[length - 1] !== "product-detail" &&
          pageName !== "product-detail"
        ) {
          window.location.replace(`/product-detail/${pageName}`);
        }
      } else if (_.isEmpty(token)) {
        if (isNotValidWithoutLogin) {
          window.location.replace(`/`);
        } else if (
          webUrlArray.includes("product-detail") &&
          webUrlArray[length - 1] !== "product-detail" &&
          pageName !== "product-detail"
        ) {
          window.location.replace(`/product-detail/${pageName}`);
        }
      }
    }
  }, []);

  useEffect(() => {
    // if (
    //   !_.isEmpty(userData) &&
    //   !_.isUndefined(userData?.personal_info?.id) &&
    //   !_.isNull(userData?.personal_info?.id)
    // ) {
    //   socket.connect();
    //   const data = {
    //     user_id: userData?.personal_info?.id,
    //   };
    //   socket.emit("userConnect", data, (callBackData) => {});
    // }
  }, [userData]);

  useEffect(() => {
    initTranslate(store);
    GetStaticImageApi();
    // GetLanguageList();
    if (_.isNull(language?.code) || language?.code == "en") {
      GetLanguageApi();
    }
  }, []);

  const GetStaticImageApi = () => {
    const endpoint = BaseSetting.endpoint.staticImages;

    const data = {};

    getApiData(endpoint, "get", data)
      .then((result) => {
        if (result) {
          dispatch(setImageFile(result?.data));
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const GetLanguageApi = (item) => {
    const endpoint = BaseSetting.endpoint.getLanguage;
    const data = {
      lang_id: "en-US",
    };
    const {
      language: { language },
    } = store.getState();
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          store.dispatch(
            languageAction.setLanguage({
              ...language,
              code: "en-US",
              json: result?.data,
            })
          );
          initTranslate(store);
        } else {
        }
      })
      .catch((err) => {});
  };

  return (
    <div
      style={{
        height: "100vh",
        background: BaseColor.whiteColor,
      }}
    >
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              width: "100vw",
              background: BaseColor.whiteColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} color="primary" />
          </div>
        }
      >
        <Routes>
          <Route exact path={"/login"} element={<Login />} />
          <Route path={"/forgot-password"} element={<ForgotPassword />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path={"/my-cart"} element={<MyCart />} />
          <Route path={"/create-profile"} element={<CreateProfile />} />
          <Route path={"/profile"} element={<Profile />} />
          <Route path={"/myOffers"} element={<MyOffers />} />
          <Route path={"/favorites"} element={<Favorites />} />
          <Route path={"/sell-post"} element={<SellPost />} />
          <Route path={"/buy-post"} element={<BuyPost />} />
          <Route path={"/edit-sell-post"} element={<EditSellPost />} />
          <Route path={"/edit-buy-post"} element={<EditBuyPost />} />
          <Route path={"/chat"} element={<Chat />} />
          <Route path={"/alert"} element={<Alerts />} />
          <Route path={"/logistic"} element={<Logistic />} />
          <Route path={"/notification"} element={<Notification />} />
          <Route
            exact
            path={"/"}
            element={<Navigate to="/products" replace={true} />}
          />
          <Route exact path={"/products"} element={<Product />} />
          <Route path={"/search-result"} element={<SearchResult />} />
          <Route path={"/product-detail/:id"} element={<ProductDetail />} />
          <Route path={"/user-profile/:id"} element={<UserProfile />} />
          <Route
            path={"/terms-and-conditions"}
            element={<TermsAndCondition />}
          />
          <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={"/contact-us"} element={<ContactUs />} />
          <Route path={"/downloadapp"} element={<DownloadApp />} />
          <Route path={"/about-us"} element={<AboutUs />} />
          <Route path={"/sitemap"} element={<SiteMap />} />
          {/* <Route
            path="*"
            element={<Navigate to="/error-page" replace={true} />}
          /> */}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
      {(isMobile || isTablet) && (
        <div
          style={{ position: "fixed", bottom: 0, zIndex: 10 }}
          className={installAppVisible ? "slide-in" : "slide-out"}
        >
          <CDownloadApp
            onClose={() => {
              setInstallAppVisible(false);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default App;
