/* eslint-disable global-require */
/* eslint-disable quotes */
import i18n from "i18n-js";
import actions from "../Redux/Reducers/language/actions";

const translationGetters = {
  en: () => require("./en.json"),
  hi: () => require("./hi.json"),
  zh: () => require("./zh.json"),
};

export const translate = (key, config) => {
  if (!config) {
    config = {};
  }
  config.defaultValue = key;
  return i18n.t(key, config);
};

const setI18nConfig = (language = "en", store, bool, lang) => {
  const isRTL = false;
  let appLanguage = language;
  if (language === null) {
    appLanguage = "en";
    store.dispatch({
      type: actions.SET_LANGUAGE,
      code: appLanguage,
    });
  }

  // if (appLanguage === 'ar') {
  //   isRTL = true;
  // }

  // const ReactNative = require("react-native");
  // try {
  //   ReactNative.I18nManager.allowRTL(isRTL);
  //   ReactNative.I18nManager.forceRTL(isRTL);
  // } catch (e) {
  // }
  const {
    language: {
      language: { code, json },
    },
  } = store.getState();

  i18n.translations = { [appLanguage]: json };
  i18n.locale = appLanguage;
  store.dispatch({
    type: actions.UPDATE_LANGUAGE,
    updateLanguage: !lang,
  });
  if (bool) {
    window.location.reload();
  }
};

export const initTranslate = (store, bool = false) => {
  const {
    language: { code, updateLanguage },
  } = store.getState();
  setI18nConfig(code, store, bool, updateLanguage);
};
