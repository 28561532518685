import { Link } from "@mui/material";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/Color";
import PropTypes from "prop-types";

const CLink = (props) => {
  const {
    title = "",
    onClick = () => {},
    disabled = false,
    style = {},
  } = props;
  return (
    <Link
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      sx={[
        {
          fontFamily: FontFamily.SemiBold,
          fontWeight: "900",
          cursor: !disabled && "pointer",
          color: disabled ? BaseColor.disablePrimary : BaseColor.primary,
          pointerEvents: disabled && "none",
        },
        style,
      ]}
    >
      {title}
    </Link>
  );
};

CLink.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};
export default CLink;
