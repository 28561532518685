import types from "./actions";
// import actions from './actions';

const initialState = {
  language: { code: "en", json: {} },
  selectLanguage: false,
  updateLanguage: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case types.SELECT_LANGUAGE:
      return {
        ...state,
        selectLanguage: action.selectLanguage,
      };
    case types.UPDATE_LANGUAGE:
      return {
        ...state,
        updateLanguage: action.updateLanguage,
      };
    default:
      return state;
  }
}
