import {
  ButtonBase,
  CircularProgress,
  Grid,
  InputBase,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import BaseColor from "../../Config/Color";
import styles from "./styles";
import Files from "../../Config/Files";
import BaseSetting from "../../Apis/setting";
import { TfiFacebook, TfiTwitterAlt, TfiLinkedin } from "react-icons/tfi";
import { BsInstagram } from "react-icons/bs";
import theme, { FontFamily } from "../../Config/theme";
import _, { isArray, isEmpty, isNull, isUndefined } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import authActions from "../../Redux/Reducers/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { getApiData } from "../../Apis/apiHelper";
import COtpInput from "../../Components/COtpInput";
import CInput from "../../Components/CInput";
import CModal from "../../Components/CModal";
import CCheckBox from "../../Components/CCheckBox";
import CButton from "../../Components/CButton";
import CGoogle from "../../Components/CGoogle";
import { CiMobile3 } from "react-icons/ci";
import { isMobile } from "react-device-detect";
import CIcon from "../CIcon";
import CloseIcon from "@mui/icons-material/Close";
import CResendBtn from "../CResendBtn";
import CAutoComplete from "../CAutoComplete";
import { translate } from "../../lang/Translate";
import { PhoneNumberUtil } from "google-libphonenumber";
import ReCAPTCHA from "react-google-recaptcha";
import { FaCaretRight } from "react-icons/fa";
import CLink from "../CLink";

const CFooter = forwardRef((props, ref) => {
  // Set data in Redux
  const dispatch = useDispatch();
  const { setUserData, setAccessToken, setUserFirstTime, setFirstTimeSkip } =
    authActions;

  const { siteMap = false, isUserFirstTime = false } = props;

  const location = useLocation();

  // Get data from Redux
  const {
    userData,
    accessToken,
    UUID,
    userFirstTime,
    firstTimeSkip,
    popupShowTime,
    profileCompletePopupShowTime,
  } = useSelector((state) => state.auth);

  console.log("popupShowTime======>>>>>", popupShowTime);
  const isProfileComplete = userData?.isProfileComplete
    ? userData?.isProfileComplete
    : false;

  const footerCompanyHeaderData = [
    {
      id: 1,
      title: translate("buy_post"),
      navigate: "/buy-post",
    },
    {
      id: 2,
      title: translate("sell_post"),
      navigate: "/sell-post",
    },
    {
      id: 3,
      title: translate("news_title"),
      navigate: "https://scrapc.com/#news",
    },
    {
      id: 4,
      title: translate("about_title"),
      navigate: "https://scrapc.com/about-us-details/",
    },
    {
      id: 5,
      title: translate("sitemap_title"),
      navigate: "/sitemap",
    },
  ];

  const footerLegalHeaderData = [
    {
      id: 1,
      title: translate("terms_and_condition_text"),
      navigate: "/terms-and-conditions",
    },
    {
      id: 2,
      title: translate("privacy_policy_text"),
      navigate: "/privacy-policy",
    },
    {
      id: 3,
      title: translate("drawer_menu_contact_us"),
      navigate: "/contact-us",
    },
  ];

  const postSiteMapRoutes = [
    {
      id: 1,
      title: "Home",
      navigate: "/products",
    },
    {
      id: 2,
      title: "Sell post",
      navigate: "/sell-post",
    },
    {
      id: 3,
      title: "Buy post",
      navigate: "/buy-post",
    },
    {
      id: 5,
      title: "Logistics",
      navigate: "/logistic",
    },
  ];

  const userSiteMapRoutes = [
    {
      id: 1,
      title: "Profile",
      navigate: "/profile",
    },
    {
      id: 2,
      title: "Cart",
      navigate: "/my-cart",
    },
    {
      id: 3,
      title: "My offers",
      navigate: "/myOffers",
    },
    {
      id: 4,
      title: "Alerts",
      navigate: "/alert",
    },
    {
      id: 5,
      title: "Favorites",
      navigate: "/favorites",
    },
    {
      id: 6,
      title: "Notifications",
      navigate: "/notification",
    },
  ];

  const generalSiteMapRoutes = [
    {
      id: 1,
      title: "Terms & conditions",
      navigate: "/terms-and-conditions",
    },
    {
      id: 2,
      title: "Privacy policy",
      navigate: "/privacy-policy",
    },
    {
      id: 3,
      title: "Contact us",
      navigate: "/contact-us",
    },
    {
      id: 4,
      title: "About us",
      navigate: "/about-us",
    },
  ];

  const chatSiteMapRoutes = [
    {
      id: 1,
      title: "Chat",
      navigate: "/chat",
    },
    {
      id: 2,
      title: "Chat with admin",
      navigate: "/chat",
    },
  ];

  const loginRequired = _.isEmpty(accessToken);
  const classes = styles();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  const loginTimerRef = useRef({});
  const createAccTimerRef = useRef({});
  const FpTimerRef = useRef({});
  const timerRef = useRef({});
  const signupTimerRef = useRef({});

  const [fromDirectLoginModal, setFromDirectLoginModal] = useState(false);

  //modal
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [CreateAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [directLoginModal, setDirectLoginModal] = useState(false);
  console.log("directLoginModal======>>>>>", directLoginModal);

  // direct login
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailDirectLogin, setEmailDirectLogin] = useState("");

  // direct login validation
  const [mobileNoErrorText, setMobileNoErrorText] = useState("");
  const [emailErrorTextDirectLogin, setEmailErrorTextDirectLogin] =
    useState("");
  const [countryPhoneCodeDirectLogin, setCountryPhoneCodeDirectLogin] =
    useState({});
  const [isOtpGenerated, setIsOtpGenerated] = useState(false);
  const [disableOtp, setDisableOtp] = useState(false);
  const [otpDirectLogin, setOtpDirectLogin] = useState("");
  const [otpErrorTextDirectLogin, setOtpErrorTextDirectLogin] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const [generateOTPBtnLoader, setGenerateOTPBtnLoader] = useState(false);
  const [verifyOTPBtnLoader, setVerifyOTPBtnLoader] = useState(false);
  const [emailDisable, setEmailDisable] = useState(false);
  const [mobileNoDisable, setMobileNoDisable] = useState(false);

  const [countryListPhone, setCountryListPhone] = useState([]);

  // ? Value
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [type, setType] = useState("otp");

  // Create Account Modal Value
  const [emailOrMobileCreateAcc, setEmailOrMobileCreateAcc] = useState("");
  const [passwordCreateAcc, setPasswordCreateAcc] = useState("");
  const [rePasswordCreateAcc, setRePasswordCreateAcc] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [otpCreateAcc, setOtpCreateAcc] = useState("");

  // Forgot Password Modal
  const [passwordFP, setPasswordFP] = useState("");
  const [rePasswordFP, setRePasswordFP] = useState("");
  const [emailOrMobileFP, setEmailOrMobileFP] = useState("");
  const [otpFP, setOtpFP] = useState("");
  const [newToken, setNewToken] = useState("");

  // *Bool
  const [showPassword, setShowPassword] = useState(false);
  const [isOTPGenerated, setIsOTPGenerated] = useState(false);

  // Create Account Modal
  const [showPasswordCreateAcc, setShowPasswordCreateAcc] = useState(false);
  const [showRePasswordCreateAcc, setShowRePasswordCreateAcc] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [createAccOtpGenerated, setCreateAccOtpGenerated] = useState(false);

  // Forgot Password Modal
  const [isFPOtpGenerated, setIsFPOtpGenerated] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [showRePasswordFp, setShowRePasswordFp] = useState(false);
  const [countryPhoneCodeFP, setCountryPhoneCodeFP] = useState({});
  const [emailOrMobileDisable, setEmailOrMobileDisable] = useState(false);
  const [emailOrMobileDisableFP, setEmailOrMobileDisableFP] = useState(false);
  const [isCaptcha, setIsCaptcha] = useState(false);

  // * Loader
  const [generateOTPLoginBtnLoader, setGenerateOTPLoginBtnLoader] =
    useState(false);
  const [loginModelPassBtnLoader, setLoginModelPassBtnLoader] = useState(false);
  const [createAccountModalBtnLoader, setCreateAccountModalBtnLoader] =
    useState(false);
  const [createAccGenerateOtpBtnLoader, setCreateAccGenerateOtpBtnLoader] =
    useState(false);
  const [createAccVerifyOtpBtnLoader, setCreateAccVerifyOtpBtnLoader] =
    useState(false);
  const [verifyOTPLoginBtnLoader, setVerifyOTPLoginBtnLoader] = useState(false);
  const [generateOTPFPBtnLoader, setGenerateOTPFPBtnLoader] = useState(false);
  const [fPverifyOtpBtnLoader, setFPverifyOtpBtnLoader] = useState(false);
  const [newPasswordBtnLoader, setNewPasswordBtnLoader] = useState(false);

  // !Error messages
  const [emailOrMobileErrorText, setEmailOrMobileErrorText] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [otpInputErrorText, setOtpInputErrorText] = useState("");

  // Create Account  Modal
  const [emailOrMobileErrorTextCreateAcc, setEmailOrMobileErrorTextCreateAcc] =
    useState("");
  const [passwordErrorTextCreateAcc, setPasswordErrorTextCreateAcc] =
    useState("");
  const [rePasswordErrorTextCreateAcc, setRePasswordErrorTextCreateAcc] =
    useState("");
  const [acceptTermsErrorTextCreateAcc, setAcceptTermsErrorTextCreateAcc] =
    useState("");
  const [createAccOtpErrorText, setCreateAccOtpErrorText] = useState("");

  // Forgot Password
  const [emailOrMobileFPErrorText, setEmailOrMobileFPErrorText] = useState("");
  const [otpInputFGErrorText, setOtpInputFGErrorText] = useState("");
  const [newPasswordFPErrorText, setNewPasswordFPErrorText] = useState("");
  const [rePasswordFPErrorText, setRePasswordFPErrorText] = useState("");
  const [email, setEmail] = useState("");

  //Signup using otp
  const [openSignupUsingOtpModal, setOpenSignupUsingOtpModal] = useState(false);
  const [signupEmailOrMobile, setSignupEmailOrMobile] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupCountryCode, setSignupCountryCode] = useState("");
  const [signupEmailOrMobileErrorText, setSignupEmailOrMobileErrorText] =
    useState("");
  const [signupEmailErrorText, setSignupEmailErrorText] = useState("");
  const [signupShowEmail, setSignupShowEmail] = useState(false);
  const [signupEmailOrMobileNoDisable, setSignupEmailOrMobileNoDisable] =
    useState(false);
  const [signUpEmailDisabled, setSignUpEmailDisabled] = useState(false);
  const [signupOtp, setSignupOtp] = useState("");
  const [isSignupOtpGenerated, setIsSignupOtpGenerated] = useState("");
  const [signupGenerateOTPBtnLoader, setSignupGenerateOTPBtnLoader] =
    useState("");
  const [signupOtpErrorText, setSignupOtpErrorText] = useState("");
  const [signupVerifyOTPBtnLoader, setSignupVerifyOTPBtnLoader] =
    useState(false);
  const [signupDisableOtp, setSignupDisableOtp] = useState(false);

  const [AcceptTermsErrorTextSignup, setAcceptTermsErrorTextSignup] =
    useState("");
  const [AcceptTermsSignup, setAcceptTermsSignup] = useState(false);

  // social Links
  const [socialLinkData, setSocialLinkData] = useState();

  //remember me
  const [rememberMe, setRememberMe] = useState(false);
  const [countryPhoneCode, setCountryPhoneCode] = useState({});
  const [countryPhoneCodeCreateAcc, setCountryPhoneCodeCreateAcc] = useState(
    {}
  );

  useEffect(() => {
    setIsCaptcha(false);
  }, [loginModalOpen, CreateAccountModalOpen]);

  useEffect(() => {
    getCountriesList();
    getSocialMediaLinks();
  }, []);

  useEffect(() => {
    const success = !isEmpty(countryListPhone) && isArray(countryListPhone);
    if (success) {
      const phoneCode = countryListPhone?.filter(
        (v) => v?.phone_code === "+91"
      );
      let data;
      phoneCode.map((item) => {
        data = item;
      });
      setCountryPhoneCodeFP(data);
      setCountryPhoneCodeCreateAcc(data);
      setCountryPhoneCode(data);
      setCountryPhoneCodeDirectLogin(data);
      setSignupCountryCode(data);
    }
  }, [countryListPhone]);

  function getCountriesList() {
    const endpoint = `${BaseSetting.endpoint.countriesList}`;
    getApiData(endpoint, "post", { download: true, type: "web" })
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setCountryListPhone(response?.countryData);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  // remember me in login modal
  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedPassword = localStorage.getItem("password");
    const storedRemember = localStorage.getItem("remember");

    if (storedUsername && storedPassword && storedRemember) {
      setEmailOrMobile(storedUsername);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    LoginModalOpen(bool) {
      setLoginModalOpen(bool);
    },
    DirectLoginModalOpen(bool) {
      if (bool) {
        if (loginModalOpen) {
          setLoginModalOpen(true);
        } else if (openSignupUsingOtpModal) {
          setOpenSignupUsingOtpModal(true);
        } else if (CreateAccountModalOpen) {
          setCreateAccountModalOpen(true);
        } else if (forgotPasswordModalOpen) {
          setForgotPasswordModalOpen(true);
        } else {
          setDirectLoginModal(true);
        }
      }
    },
  }));

  // open login modal after some time if user is not logged in
  useEffect(() => {
    if (
      firstTimeSkip === true &&
      _.isEmpty(accessToken) &&
      location?.pathname !== "/create-profile"
    ) {
      const timer = setTimeout(() => {
        if (loginModalOpen) {
          setLoginModalOpen(true);
        } else if (openSignupUsingOtpModal) {
          setOpenSignupUsingOtpModal(true);
        } else if (CreateAccountModalOpen) {
          setCreateAccountModalOpen(true);
        } else if (forgotPasswordModalOpen) {
          setForgotPasswordModalOpen(true);
        } else {
          setDirectLoginModal(true);
        }
      }, popupShowTime * 1000);

      return () => clearTimeout(timer);
    }
  }, [
    directLoginModal,
    loginModalOpen,
    openSignupUsingOtpModal,
    CreateAccountModalOpen,
    forgotPasswordModalOpen,
  ]);

  // show complete profile modal if user's profile is incomplete
  useEffect(() => {
    if (!_.isEmpty(accessToken)) {
      if (
        userData?.isProfileComplete === false &&
        location?.pathname !== "/create-profile" &&
        !_.isEmpty(String(profileCompletePopupShowTime)) &&
        !_.isNull(profileCompletePopupShowTime) &&
        !_.isUndefined(profileCompletePopupShowTime)
      ) {
        const timer = setTimeout(() => {
          setProfileCompleted(true);
        }, profileCompletePopupShowTime * 1000);

        return () => clearTimeout(timer);
      }
    }
  }, [profileCompleted, accessToken, location?.pathname]);

  const [contactUsErrorText, setContactUsErrorText] = useState("");
  const [subscribeBtnLoader, setSubscribeBtnLoader] = useState(false);

  const validation = () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (isEmpty(email)) {
      setContactUsErrorText(translate("empty_email_error"));
    } else if (!emailRegex.test(email)) {
      setContactUsErrorText(translate("valid_email_error"));
    } else {
      subscriptionApi();
    }
  };

  // subscription api integration
  async function subscriptionApi() {
    setSubscribeBtnLoader(true);
    try {
      const data = {
        email: email,
      };
      const response = await getApiData(
        BaseSetting.endpoint.subscribeApi,
        "POST",
        data
      );
      if (response.status) {
        toast(response.message, { type: "success" });
        setEmail("");
        setSubscribeBtnLoader(false);
      } else {
        toast(response?.message, { type: "error" });
        setSubscribeBtnLoader(false);
      }
    } catch (error) {
      setSubscribeBtnLoader(false);
    }
  }

  const loginValidation = (type) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegex = /^[6-9][0-9]{9}$/;
    const characterExistRegex = /^[a-zA-Z]*$/;
    const onlyNumberRegex = /^[0-9]*$/;

    if (emailOrMobile.trim() === "") {
      window.scrollTo(0, 0);
      loginAllErrorFalse();
      setEmailOrMobileErrorText(translate("empty_email_or_mobile_error"));
    } else if (
      characterExistRegex.test(String(emailOrMobile)) &&
      !emailRegex.test(String(emailOrMobile))
    ) {
      window.scrollTo(0, 0);
      loginAllErrorFalse();
      setEmailOrMobileErrorText(translate("valid_email_error"));
    } else if (
      onlyNumberRegex.test(String(emailOrMobile)) &&
      !phoneRegex.test(String(emailOrMobile)) &&
      countryPhoneCode?.phone_code === "+91"
    ) {
      window.scrollTo(0, 0);
      loginAllErrorFalse();
      setEmailOrMobileErrorText(translate("valid_mobile_error"));
    } else if (
      onlyNumberRegex.test(String(emailOrMobile)) &&
      String(emailOrMobile).length < 7
    ) {
      window.scrollTo(0, 0);
      loginAllErrorFalse();
      setEmailOrMobileErrorText(translate("minimum_mobile_error"));
    } else if (
      onlyNumberRegex.test(String(emailOrMobile)) &&
      String(emailOrMobile).length > 12
    ) {
      window.scrollTo(0, 0);
      loginAllErrorFalse();
      setEmailOrMobileErrorText(translate("maximum_mobile_error"));
    } else if (type === "password" && password.trim() === "") {
      window.scrollTo(0, 0);
      loginAllErrorFalse();
      setPasswordErrorText(translate("password_empty_error"));
    } else if (type === "otp" && otp.trim() === "") {
      loginAllErrorFalse();
      setOtpInputErrorText(translate("empty_otp_error"));
    } else if (type === "otp" && otp < 6) {
      loginAllErrorFalse();
      setOtpInputErrorText(translate("valid_otp_error"));
    } else if (type === "otp" && !otp.match(/^[0-9]{6}$/)) {
      loginAllErrorFalse();
      setOtpInputErrorText(translate("only_character_otp_error"));
    } else {
      loginAllErrorFalse();
      LoginApiCall();
    }
  };

  const loginAllErrorFalse = () => {
    setEmailOrMobileErrorText("");
    setEmailOrMobileFPErrorText("");
    setPasswordErrorText("");
    setOtpInputFGErrorText("");
    setOtpInputErrorText("");
  };

  const LoginApiCall = () => {
    setLoginModelPassBtnLoader(true);
    const phoneRegex = /^[6-9][0-9]{9}$/;

    const data = {
      emailOrPhone: emailOrMobile,
      password: password,
      uuid: UUID,
    };
    if (phoneRegex.test(String(emailOrMobile))) {
      data.country_phone_code = countryPhoneCode?.phone_code;
    }
    const endPoint = BaseSetting.endpoint.login;
    getApiData(endPoint, "post", data)
      .then((result) => {
        if (result.status) {
          if (rememberMe) {
            localStorage.setItem("username", emailOrMobile);
            localStorage.setItem("password", password);
            localStorage.setItem("remember", true);
          } else {
            localStorage.setItem("username", "");
            localStorage.setItem("password", "");
            localStorage.setItem("remember", false);
          }
          const token =
            !_.isEmpty(result?.data?.token) &&
            !_.isUndefined(result?.data?.token) &&
            !_.isNull(result?.data?.token)
              ? result?.data?.token
              : "";
          const resultUserData =
            !_.isEmpty(result?.data?.userData) &&
            !_.isUndefined(result?.data?.userData) &&
            !_.isNull(result?.data?.userData)
              ? result?.data?.userData
              : {};
          dispatch(setAccessToken(token));
          dispatch(setUserData(resultUserData));
          setLoginModalOpen(false);
          setLoginModelPassBtnLoader(false);
        } else {
          toast(result?.message, { type: "error" });
          setLoginModelPassBtnLoader(false);
          if (result?.data?.type === "verify_otp") {
            setType("otp");
          }
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setLoginModelPassBtnLoader(false);
      });
  };

  const allErrorFalseCreateAcc = () => {
    setEmailOrMobileErrorTextCreateAcc("");
    setPasswordErrorTextCreateAcc("");
    setRePasswordErrorTextCreateAcc("");
    setAcceptTermsErrorTextCreateAcc("");
  };

  const validationCreateAcc = () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegex = /^[6-9][0-9]{9}$/;
    const characterExistRegex = /^[a-zA-Z]*$/;
    const onlyNumberRegex = /^[0-9]*$/;

    if (emailOrMobileCreateAcc.trim() === "") {
      allErrorFalseCreateAcc();
      setEmailOrMobileErrorTextCreateAcc(
        translate("empty_email_or_mobile_error")
      );
    } else if (
      characterExistRegex.test(String(emailOrMobileCreateAcc)) &&
      !emailRegex.test(String(emailOrMobileCreateAcc))
    ) {
      allErrorFalseCreateAcc();
      setEmailOrMobileErrorTextCreateAcc(translate("valid_email_error"));
    } else if (
      onlyNumberRegex.test(String(emailOrMobileCreateAcc)) &&
      !phoneRegex.test(String(emailOrMobileCreateAcc)) &&
      countryPhoneCodeCreateAcc?.phone_code === "+91"
    ) {
      allErrorFalseCreateAcc();
      setEmailOrMobileErrorTextCreateAcc(translate("valid_mobile_error"));
    } else if (
      onlyNumberRegex.test(String(emailOrMobileCreateAcc)) &&
      String(emailOrMobileCreateAcc).length < 7 &&
      countryPhoneCodeCreateAcc?.phone_code !== "+91"
    ) {
      allErrorFalseCreateAcc();
      setEmailOrMobileErrorTextCreateAcc(translate("minimum_mobile_error"));
    } else if (
      onlyNumberRegex.test(String(emailOrMobileCreateAcc)) &&
      String(emailOrMobileCreateAcc).length > 12 &&
      countryPhoneCodeCreateAcc?.phone_code !== "+91"
    ) {
      allErrorFalseCreateAcc();
      setEmailOrMobileErrorTextCreateAcc(translate("maximum_mobile_error"));
    } else if (passwordCreateAcc.trim() === "") {
      allErrorFalseCreateAcc();
      setPasswordErrorTextCreateAcc(translate("password_empty_error"));
    } else if (rePasswordCreateAcc.trim() === "") {
      allErrorFalseCreateAcc();
      setRePasswordErrorTextCreateAcc(
        translate("empty_confirm_password_error")
      );
    } else if (!isValidPassword(passwordCreateAcc)) {
      window.scrollTo(0, 0);
      allErrorFalseCreateAcc();
      setPasswordErrorTextCreateAcc(translate("password_length_error"));
    } else if (passwordCreateAcc !== rePasswordCreateAcc) {
      allErrorFalseCreateAcc();
      setRePasswordErrorTextCreateAcc(translate("password_match"));
    } else if (!acceptTerms) {
      allErrorFalseCreateAcc();
      setAcceptTermsErrorTextCreateAcc(translate("agree_terms"));
    } else {
      allErrorFalseCreateAcc();
      SignUpApiCall();
    }
  };

  function isValidPassword(password) {
    if (password.length < 6) {
      return false;
    }
    return true;
  }

  const SignUpApiCall = () => {
    setCreateAccountModalBtnLoader(true);

    const phoneRegex = /^[6-9][0-9]{9}$/;
    const data = {
      emailOrPhone: emailOrMobileCreateAcc,
      type: phoneRegex.test(String(emailOrMobileCreateAcc)) ? "phone" : "email",
      password: passwordCreateAcc,
      re_password: rePasswordCreateAcc,
      uuid: UUID,
      plateform_type: "web",
    };
    if (phoneRegex.test(String(emailOrMobileCreateAcc))) {
      data.country_phone_code = countryPhoneCodeCreateAcc?.phone_code;
    }
    const endPoint = BaseSetting.endpoint.signUp;
    getApiData(endPoint, "post", data)
      .then((result) => {
        if (result.status) {
          setCreateAccountModalBtnLoader(false);
          setIsAccountCreated(true);
        } else {
          if (result?.data?.type == "verify_otp") {
            setIsAccountCreated(true);
          }
          setCreateAccountModalBtnLoader(false);
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        setCreateAccountModalBtnLoader(false);
      });
  };

  const generateOTPValidation = (type) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegex = /^[6-9][0-9]{9}$/;
    const characterExistRegex = /^[a-zA-Z]*$/;
    const onlyNumberRegex = /^[0-9]*$/;

    if (type === "login") {
      if (emailOrMobile.trim() === "") {
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("empty_email_or_mobile_error"));
      } else if (
        characterExistRegex.test(String(emailOrMobile)) &&
        !emailRegex.test(String(emailOrMobile))
      ) {
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("valid_email_error"));
      } else if (
        onlyNumberRegex.test(String(emailOrMobile)) &&
        !phoneRegex.test(String(emailOrMobile)) &&
        countryPhoneCode?.phone_code === "+91"
      ) {
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("valid_mobile_error"));
      } else if (
        onlyNumberRegex.test(String(emailOrMobile)) &&
        String(emailOrMobile).length < 7
      ) {
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("minimum_mobile_error"));
      } else if (
        onlyNumberRegex.test(String(emailOrMobile)) &&
        String(emailOrMobile).length > 12
      ) {
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("maximum_mobile_error"));
      } else {
        loginAllErrorFalse();
        setEmailOrMobileDisable(true);
        GenerateOTPApiCall(type);
      }
    } else if (type === "forgotPassword") {
      if (emailOrMobileFP.trim() === "") {
        loginAllErrorFalse();
        setEmailOrMobileFPErrorText(translate("empty_email_or_mobile_error"));
      } else if (
        characterExistRegex.test(String(emailOrMobileFP)) &&
        !emailRegex.test(String(emailOrMobileFP))
      ) {
        loginAllErrorFalse();
        setEmailOrMobileFPErrorText(translate("valid_email_error"));
      } else if (
        onlyNumberRegex.test(String(emailOrMobileFP)) &&
        !phoneRegex.test(String(emailOrMobileFP)) &&
        countryPhoneCodeFP?.phone_code === "+91"
      ) {
        loginAllErrorFalse();
        setEmailOrMobileFPErrorText(translate("valid_mobile_error"));
      } else if (
        onlyNumberRegex.test(String(emailOrMobileFP)) &&
        String(emailOrMobileFP).length < 7
      ) {
        loginAllErrorFalse();
        setEmailOrMobileFPErrorText(translate("minimum_mobile_error"));
      } else if (
        onlyNumberRegex.test(String(emailOrMobileFP)) &&
        String(emailOrMobileFP).length > 12
      ) {
        loginAllErrorFalse();
        setEmailOrMobileFPErrorText(translate("maximum_mobile_error"));
      } else {
        GenerateOTPApiCall(type);
      }
    } else {
      if (emailOrMobileCreateAcc.trim() === "") {
        window.scrollTo(0, 0);
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("empty_email_or_mobile_error"));
      } else if (
        characterExistRegex.test(String(emailOrMobileCreateAcc)) &&
        !emailRegex.test(String(emailOrMobileCreateAcc))
      ) {
        window.scrollTo(0, 0);
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("valid_email_error"));
      } else if (
        onlyNumberRegex.test(String(emailOrMobileCreateAcc)) &&
        !phoneRegex.test(String(emailOrMobileCreateAcc)) &&
        countryPhoneCodeCreateAcc?.phone_code === "+91"
      ) {
        window.scrollTo(0, 0);
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("valid_mobile_error"));
      } else if (
        onlyNumberRegex.test(String(emailOrMobileCreateAcc)) &&
        String(emailOrMobileCreateAcc).length < 7
      ) {
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("minimum_mobile_error"));
      } else if (
        onlyNumberRegex.test(String(emailOrMobileCreateAcc)) &&
        String(emailOrMobileCreateAcc).length > 12
      ) {
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("maximum_mobile_error"));
      } else {
        GenerateOTPApiCall();
      }
    }
  };
  const GenerateOTPApiCall = (type) => {
    if (type === "forgotPassword") {
      setGenerateOTPFPBtnLoader(true);
    } else if (type === "login") {
      setGenerateOTPLoginBtnLoader(true);
    } else {
      setCreateAccGenerateOtpBtnLoader(true);
    }

    const data = {
      emailOrPhone:
        type === "forgotPassword"
          ? emailOrMobileFP
          : type === "login"
          ? emailOrMobile
          : emailOrMobileCreateAcc,
    };
    const endPoint = BaseSetting.endpoint.generateOtp;
    getApiData(endPoint, "post", data)
      .then((result) => {
        if (result.status) {
          if (type === "forgotPassword") {
            setOtpFP("");
            setIsFPOtpGenerated(true);
            setGenerateOTPFPBtnLoader(false);
            setEmailOrMobileDisableFP(true);
            const expiredAt = result?.data?.expiredAt;
            setTimeout(() => {
              FpTimerRef?.current?.stop();
              FpTimerRef?.current?.start(expiredAt);
            }, 50);
          } else if (type === "login") {
            setOtp("");
            setIsOTPGenerated(true);
            const expiredAt = result?.data?.expiredAt;
            setTimeout(() => {
              loginTimerRef?.current?.stop();
              loginTimerRef?.current?.start(expiredAt);
            }, 50);
            setGenerateOTPLoginBtnLoader(false);
          } else {
            setOtp("");
            setCreateAccOtpGenerated(true);
            setCreateAccGenerateOtpBtnLoader(false);
            const expiredAt = result?.data?.expiredAt;
            setTimeout(() => {
              createAccTimerRef?.current?.stop();
              createAccTimerRef?.current?.start(expiredAt);
            }, 50);
          }
        } else {
          toast(result?.message, { type: "error" });
          if (type === "forgotPassword") {
            setGenerateOTPFPBtnLoader(false);
          } else if (type === "login") {
            setGenerateOTPLoginBtnLoader(false);
          } else {
            setCreateAccGenerateOtpBtnLoader(false);
          }
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        if (type === "forgotPassword") {
          setGenerateOTPFPBtnLoader(false);
        } else if (type === "login") {
          setGenerateOTPLoginBtnLoader(false);
        } else {
          setCreateAccGenerateOtpBtnLoader(false);
        }
      });
  };

  const verifyOTPValidation = (type, otp) => {
    const otpRegex = /^[0-9]{6}$/;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (type === "login") {
      if (emailOrMobile.trim() === "") {
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("empty_email_or_mobile_error"));
      } else if (
        emailOrMobile.charAt(0).match(/^[A-Za-z]+$/) &&
        !emailRegex.test(emailOrMobile)
      ) {
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("valid_email_error"));
      } else if (
        emailOrMobile.charAt(0).match(/^[6-9]$/) &&
        !emailOrMobile.match(/^[6-9][0-9]{9}$/) &&
        !/[a-z0-9]/.test(String(emailOrMobile))
      ) {
        loginAllErrorFalse();
        setEmailOrMobileErrorText(translate("valid_mobile_error"));
      } else if (otp.trim() === "") {
        loginAllErrorFalse();
        setOtpInputErrorText(translate("empty_otp_error"));
      } else if (otp.length < 6) {
        loginAllErrorFalse();
        setOtpInputErrorText(translate("valid_otp_error"));
      } else if (!otpRegex.test(String(otp))) {
        loginAllErrorFalse();
        setOtpInputErrorText(translate("only_character_otp_error"));
      } else {
        loginAllErrorFalse();
        VerifyOTPApiCall(type, otp);
      }
    } else if (type === "forgotPassword") {
      if (emailOrMobileFP.trim() === "") {
        loginAllErrorFalse();
        setEmailOrMobileFPErrorText(translate("empty_email_or_mobile_error"));
      } else if (
        emailOrMobileFP.charAt(0).match(/^[A-Za-z]+$/) &&
        !emailRegex.test(emailOrMobileFP)
      ) {
        loginAllErrorFalse();
        setEmailOrMobileFPErrorText(translate("valid_email_error"));
      } else if (
        emailOrMobileFP.charAt(0).match(/^[6-9]$/) &&
        !emailOrMobileFP.match(/^[6-9][0-9]{9}$/)
      ) {
        loginAllErrorFalse();
        setEmailOrMobileFPErrorText(translate("valid_mobile_error"));
      } else if (otp.trim() === "") {
        loginAllErrorFalse();
        setOtpInputFGErrorText(translate("empty_otp_error"));
      } else if (otp.length < 6) {
        loginAllErrorFalse();
        setOtpInputFGErrorText(translate("valid_otp_error"));
      } else if (!otpRegex.test(String(otp))) {
        loginAllErrorFalse();
        setOtpInputFGErrorText(translate("only_character_otp_error"));
      } else {
        loginAllErrorFalse();
        VerifyOTPApiCall(type, otp);
      }
    } else {
      if (otp.trim() === "") {
        setCreateAccOtpErrorText(translate("empty_otp_error"));
      } else if (otp.length < 6) {
        setCreateAccOtpErrorText(translate("valid_otp_error"));
      } else if (!otpRegex.test(String(otp))) {
        setCreateAccOtpErrorText(translate("only_character_otp_error"));
      } else {
        setCreateAccOtpErrorText("");
        VerifyOTPApiCall("createAccount", otp);
      }
    }
  };

  const VerifyOTPApiCall = (type, otp) => {
    if (type === "forgotPassword") {
      setFPverifyOtpBtnLoader(true);
    } else if (type === "login") {
      setVerifyOTPLoginBtnLoader(true);
    } else {
      setCreateAccVerifyOtpBtnLoader(true);
    }

    const data = {
      emailOrPhone:
        type === "forgotPassword"
          ? emailOrMobileFP
          : type === "login"
          ? emailOrMobile
          : emailOrMobileCreateAcc,
      otp: otp,
      type: type === "forgotPassword" ? "forgot_password" : "",
      uuid: UUID,
    };
    const endPoint = BaseSetting.endpoint.verifyOtp;
    getApiData(endPoint, "post", data)
      .then((result) => {
        if (result.status) {
          const token =
            !_.isEmpty(result?.data?.token) &&
            !_.isUndefined(result?.data?.token) &&
            !_.isNull(result?.data?.token)
              ? result?.data?.token
              : "";
          const resultUserData =
            !_.isEmpty(result?.data?.userData) &&
            !_.isUndefined(result?.data?.userData) &&
            !_.isNull(result?.data?.userData)
              ? result?.data?.userData
              : {};

          if (type === "forgotPassword") {
            setIsFPOtpGenerated(false);
            setIsUserVerified(true);
            setFPverifyOtpBtnLoader(false);
            FpTimerRef?.current?.stop();
            setNewToken(token);
            dispatch(setUserData({ ...resultUserData }));
          } else if (type === "login") {
            setIsOTPGenerated(false);
            loginTimerRef?.current?.stop();
            setVerifyOTPLoginBtnLoader(false);
            setType("");
            setLoginModalOpen(false);
            dispatch(setAccessToken(token));
            dispatch(setUserData({ ...resultUserData }));
          } else {
            setIsAccountCreated(false);
            createAccTimerRef?.current?.stop();
            setCreateAccountModalOpen(false);
            setCreateAccVerifyOtpBtnLoader(false);
            dispatch(setAccessToken(token));
            dispatch(setUserData({ ...resultUserData }));
            dispatch(setUserFirstTime(true));
            navigate("/create-profile", { state: { isEditable: true } });
          }
          toast(result.message, { type: "success" });
        } else {
          toast(result.message, { type: "error" });
          if (type === "forgotPassword") {
            setFPverifyOtpBtnLoader(false);
          } else if (type === "login") {
            setIsOTPGenerated(false);
            setVerifyOTPLoginBtnLoader(false);
          } else {
            setCreateAccVerifyOtpBtnLoader(false);
          }
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
        if (type === "forgotPassword") {
          setFPverifyOtpBtnLoader(false);
        } else if (type === "login") {
          setIsOTPGenerated(false);
          setVerifyOTPLoginBtnLoader(false);
        } else {
          setCreateAccVerifyOtpBtnLoader(false);
        }
      });
  };

  const createPasswordAllErrorFalse = () => {
    setNewPasswordFPErrorText("");
    setRePasswordFPErrorText("");
  };
  const createPasswordValidation = () => {
    if (passwordFP.trim() === "") {
      createPasswordAllErrorFalse();
      setNewPasswordFPErrorText(translate("password_empty_error"));
    } else if (!isValidPassword(passwordFP)) {
      createPasswordAllErrorFalse();
      setNewPasswordFPErrorText(translate("password_length_error"));
    } else if (rePasswordFP.trim() === "") {
      createPasswordAllErrorFalse();
      setRePasswordFPErrorText(translate("empty_confirm_password_error"));
    } else if (passwordFP !== rePasswordFP) {
      createPasswordAllErrorFalse();
      setRePasswordFPErrorText(translate("password_match"));
    } else {
      createPasswordAllErrorFalse();
      resetPasswordApi();
    }
  };

  const resetPasswordApi = () => {
    setNewPasswordBtnLoader(true);

    const data = {
      password: passwordFP,
      confirmPassword: rePasswordFP,
      user_id: userData?.personal_info?.id,
      token: newToken,
      emailOrPhone: emailOrMobileFP,
    };

    const endPoint = BaseSetting.endpoint.resetPassword;
    getApiData(endPoint, "post", data)
      .then((result) => {
        if (result.status) {
          loginAllErrorFalse();
          setIsFPOtpGenerated(false);
          setIsUserVerified(false);
          setNewPasswordBtnLoader(false);
          setForgotPasswordModalOpen(false);
          setLoginModalOpen(true);
        } else {
          toast(result?.message, { type: "error" });
          setNewPasswordBtnLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setNewPasswordBtnLoader(false);
      });
  };

  const getSocialMediaLinks = () => {
    const endpoint = BaseSetting.endpoint.socialLinks;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result.status) {
          const response = result?.data;
          if (!isEmpty(response)) {
            setSocialLinkData(response);
          }
        } else {
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  // Direct login with otp
  const directLoginAllErrorFalse = () => {
    setMobileNoErrorText("");
    setEmailErrorTextDirectLogin("");
    setOtpErrorTextDirectLogin("");
  };

  const checkValidData = () => {
    const onlyNumberRegex = /^[0-9]*$/;
    if (countryPhoneCodeDirectLogin?.phone_code === "+91") {
      checkValidationForMobileNumber();
    } else if (
      countryPhoneCodeDirectLogin?.phone_code !== "+91" &&
      mobileNumber.trim() === ""
    ) {
      setMobileNoErrorText(translate("empty_email_error"));
    } else if (
      countryPhoneCodeDirectLogin?.phone_code !== "+91" &&
      !_.isEmpty(mobileNumber)
    ) {
      checkValidationForEmail();

      // const phoneUtil =
      //   mobileNumber.length > 2 && onlyNumberRegex.test(mobileNumber)
      //     ? PhoneNumberUtil.getInstance()
      //     : null;
      // const phoneNumber1 =
      //   mobileNumber.length > 2 && onlyNumberRegex.test(mobileNumber)
      //     ? phoneUtil.parse(
      //         mobileNumber,
      //         countryPhoneCodeDirectLogin?.country_code
      //       )
      //     : null;
      // const isValid =
      //   mobileNumber.length > 2 && onlyNumberRegex.test(mobileNumber)
      //     ? phoneUtil.isValidNumber(phoneNumber1)
      //     : false;
      // if (
      //   mobileNumber.trim() !== "" &&
      //   countryPhoneCodeDirectLogin?.phone_code !== "+91" &&
      //   !isValid
      // ) {
      //   directLoginAllErrorFalse();
      //   setMobileNoErrorText(translate("valid_mobile_error"));
      // } else {
      // setShowEmail(true);
      // }
    }
  };

  const checkValidationForMobileNumber = () => {
    const phoneNumberRegex = /^[6-9][0-9]{9}$/;
    if (
      countryPhoneCodeDirectLogin?.phone_code === "+91" &&
      _.isEmpty(mobileNumber)
    ) {
      directLoginAllErrorFalse();
      setMobileNoErrorText(translate("empty_phone_number_error"));
    } else if (
      countryPhoneCodeDirectLogin?.phone_code === "+91" &&
      !_.isEmpty(mobileNumber) &&
      !phoneNumberRegex?.test(String(mobileNumber))
    ) {
      directLoginAllErrorFalse();
      setMobileNoErrorText(translate("valid_mobile_error"));
    } else if (
      countryPhoneCodeDirectLogin?.phone_code === "+91" &&
      !_.isEmpty(mobileNumber) &&
      phoneNumberRegex?.test(String(mobileNumber))
    ) {
      generateOtpForDirectLogin();
    }
  };

  const checkValidationForEmail = () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mobileNumber?.trim() === "") {
      directLoginAllErrorFalse();
      setMobileNoErrorText(translate("empty_email_error"));
    } else if (!emailRegex?.test(mobileNumber)) {
      directLoginAllErrorFalse();
      setMobileNoErrorText(translate("valid_email_error"));
    } else {
      generateOtpForDirectLogin();
    }
  };

  const verifyOTPValidationDirectLogin = (otp) => {
    const otpRegex = /^[0-9]{6}$/;
    if (otp.trim() === "") {
      directLoginAllErrorFalse();
      setOtpErrorTextDirectLogin(translate("empty_otp_error"));
    } else if (otp.length < 6) {
      directLoginAllErrorFalse();
      setOtpErrorTextDirectLogin(translate("valid_otp_error"));
    } else if (!otpRegex.test(String(otp))) {
      directLoginAllErrorFalse();
      setOtpErrorTextDirectLogin(translate("only_character_otp_error"));
    } else {
      directLoginAllErrorFalse();
      VerifyOTPApiDirectLogin(otp);
    }
  };
  const verifyOTPValidationSignup = (otp) => {
    const otpRegex = /^[0-9]{6}$/;
    if (otp.trim() === "") {
      directLoginAllErrorFalse();
      setOtpErrorTextDirectLogin(translate("empty_otp_error"));
    } else if (otp.length < 6) {
      directLoginAllErrorFalse();
      setOtpErrorTextDirectLogin(translate("valid_otp_error"));
    } else if (!otpRegex.test(String(otp))) {
      directLoginAllErrorFalse();
      setOtpErrorTextDirectLogin(translate("only_character_otp_error"));
    } else {
      directLoginAllErrorFalse();
      VerifyOTPSignupApiCall("", otp);
    }
  };

  const generateOtpForDirectLogin = () => {
    setGenerateOTPBtnLoader(true);
    const data = {
      uuid: UUID,
    };

    if (!_.isEmpty(mobileNumber)) {
      data.phone_code = countryPhoneCodeDirectLogin?.phone_code;
    }

    if (countryPhoneCodeDirectLogin?.phone_code !== "+91") {
      data.email = mobileNumber;
    }

    if (countryPhoneCodeDirectLogin?.phone_code === "+91") {
      data.phone = mobileNumber;
    }

    const endPoint = BaseSetting.endpoint.directLogin;
    getApiData(endPoint, "post", data)
      .then((result) => {
        if (result.status) {
          if (
            !isEmpty(result?.data) &&
            !isNull(result?.data) &&
            !isUndefined(result?.data)
          ) {
            const expiredAt = result?.data?.otp_expired_at;
            setTimeout(() => {
              timerRef.current.stop();
              timerRef.current.start(expiredAt);
            }, 50);
            setDisableOtp(false);
            setIsOtpGenerated(true);
            setEmailDisable(true);
            setMobileNoDisable(true);
          }
          setGenerateOTPBtnLoader(false);
        } else {
          toast(result?.message, { type: "error" });
          setGenerateOTPBtnLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setGenerateOTPBtnLoader(false);
      });
  };

  const VerifyOTPApiDirectLogin = (otp) => {
    setVerifyOTPBtnLoader(true);
    const data = {
      emailOrPhone: mobileNumber,
      uuid: UUID,
      otp: otp,
      type: "web",
    };

    const endPoint = BaseSetting.endpoint.verifyOtp;
    getApiData(endPoint, "post", data)
      .then((result) => {
        if (result.status) {
          const token =
            !_.isEmpty(result?.data?.token) &&
            !_.isUndefined(result?.data?.token) &&
            !_.isNull(result?.data?.token)
              ? result?.data?.token
              : "";
          const resultUserData =
            !_.isEmpty(result?.data?.userData) &&
            !_.isUndefined(result?.data?.userData) &&
            !_.isNull(result?.data?.userData)
              ? result?.data?.userData
              : {};
          dispatch(setAccessToken(token));
          dispatch(setUserData(resultUserData));
          setVerifyOTPBtnLoader(false);
          if (
            _.isEmpty(resultUserData?.personal_info?.first_name) ||
            _.isNull(resultUserData?.personal_info?.first_name) ||
            _.isUndefined(resultUserData?.personal_info?.first_name)
          ) {
            dispatch(setUserFirstTime(true));
          }
          timerRef.current.stop();
          setIsOtpGenerated(false);
          setDirectLoginModal(false);
        } else {
          toast(result?.message, { type: "error" });
          setVerifyOTPBtnLoader(false);
          setOtp("");
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setVerifyOTPBtnLoader(false);
      });
  };

  // signup using OTP
  const signupUsingOtpAllErrorFalse = () => {
    setSignupEmailErrorText("");
    setSignupEmailOrMobileErrorText("");
  };

  const checkValidDataSignup = () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegex = /^[6-9][0-9]{9}$/;
    const characterExistRegex = /^[a-zA-Z]*$/;
    const onlyNumberRegex = /^[0-9]*$/;
    if (signupEmailOrMobile?.trim() === "") {
      signupUsingOtpAllErrorFalse();
      setSignupEmailOrMobileErrorText(translate("empty_email_or_mobile_error"));
    } else if (
      characterExistRegex.test(String(signupEmailOrMobile)) &&
      !emailRegex.test(String(signupEmailOrMobile))
    ) {
      signupUsingOtpAllErrorFalse();
      setSignupEmailOrMobileErrorText(translate("valid_email_error"));
    } else if (
      signupCountryCode?.phone_code === "+91" &&
      !phoneRegex.test(String(signupEmailOrMobile)) &&
      onlyNumberRegex.test(String(signupEmailOrMobile))
    ) {
      signupUsingOtpAllErrorFalse();
      setSignupEmailOrMobileErrorText(translate("valid_mobile_error"));
    } else if (
      signupCountryCode?.phone_code !== "+91" &&
      onlyNumberRegex.test(String(signupEmailOrMobile))
    ) {
      const phoneUtil =
        signupEmailOrMobile.length > 2 ? PhoneNumberUtil.getInstance() : null;
      const phoneNumber1 =
        signupEmailOrMobile.length > 2
          ? phoneUtil.parse(
              signupEmailOrMobile,
              signupCountryCode?.country_code
            )
          : null;
      const isValid =
        signupEmailOrMobile.length > 2
          ? phoneUtil.isValidNumber(phoneNumber1)
          : false;

      if (
        signupEmailOrMobile.trim() !== "" &&
        signupCountryCode?.phone_code !== "+91" &&
        !isValid
      ) {
        signupUsingOtpAllErrorFalse();
        setSignupEmailOrMobileErrorText(translate("valid_mobile_error"));
      } else {
        setSignupShowEmail(true);
        checkValidationForEmailSignup();
      }
    } else if (!AcceptTermsSignup) {
      setAcceptTermsErrorTextSignup(translate("agree_terms"));
    } else {
      generateOtpForSignup();
    }
  };

  const generateOtpForSignup = () => {
    setSignupGenerateOTPBtnLoader(true);
    const onlyNumberRegex = /^[0-9]*$/;
    let data = {
      emailOrPhone: signupEmailOrMobile,
      type: onlyNumberRegex.test(signupEmailOrMobile) ? "phone" : "email",
      uuid: UUID,
      plateform_type: "web",
    };

    if (onlyNumberRegex.test(String(signupEmailOrMobile))) {
      data.country_phone_code = signupCountryCode?.phone_code;
    }
    if (signupShowEmail) {
      data.email = signupEmail;
    }
    const endPoint = BaseSetting.endpoint.signupUsingOtp;
    getApiData(endPoint, "post", data)
      .then((result) => {
        if (result.status) {
          setSignupGenerateOTPBtnLoader(false);
          setSignupEmailOrMobileNoDisable(true);
          setIsSignupOtpGenerated(true);
          setSignUpEmailDisabled(true);
          const expiredAt = result?.data?.otp_expired_at;
          setTimeout(() => {
            signupTimerRef.current.stop();
            signupTimerRef.current.start(expiredAt);
          }, 50);
        } else {
          toast(result?.message, { type: "error" });
          setSignupGenerateOTPBtnLoader(false);
        }
      })
      .catch((err) => {
        setSignupGenerateOTPBtnLoader(false);
      });
  };

  const checkValidationForEmailSignup = () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (signupEmail?.trim() === "") {
      signupUsingOtpAllErrorFalse();
      setSignupEmailErrorText(translate("empty_email_error"));
    } else if (!emailRegex?.test(signupEmail)) {
      signupUsingOtpAllErrorFalse();
      setSignupEmailErrorText(translate("valid_email_error"));
    } else if (!AcceptTermsSignup) {
      setAcceptTermsErrorTextSignup(translate("agree_terms"));
    } else {
      generateOtpForSignup();
    }
  };

  const VerifyOTPSignupApiCall = (type, otp) => {
    setSignupVerifyOTPBtnLoader(true);
    const data = {
      emailOrPhone: signupShowEmail ? signupEmail : signupEmailOrMobile,
      otp: otp,
      type: type === "forgotPassword" ? "forgot_password" : "",
      uuid: UUID,
      account_create: true,
    };
    const endPoint = BaseSetting.endpoint.verifyOtp;
    getApiData(endPoint, "post", data)
      .then((result) => {
        if (result.status) {
          const token =
            !_.isEmpty(result?.data?.token) &&
            !_.isUndefined(result?.data?.token) &&
            !_.isNull(result?.data?.token)
              ? result?.data?.token
              : "";
          const resultUserData =
            !_.isEmpty(result?.data?.userData) &&
            !_.isUndefined(result?.data?.userData) &&
            !_.isNull(result?.data?.userData)
              ? result?.data?.userData
              : {};
          dispatch(setAccessToken(token));
          dispatch(setUserData(resultUserData));
          dispatch(setUserFirstTime(true));
          setIsSignupOtpGenerated(false);
          setOpenSignupUsingOtpModal(false);
          setSignupVerifyOTPBtnLoader(false);
          navigate("/create-profile", { state: { isEditable: true } });
          toast(result.message, { type: "success" });
        } else {
          toast(result.message, { type: "error" });
          setSignupVerifyOTPBtnLoader(false);
          setSignupOtp("");
        }
      })
      .catch((err) => {
        setSignupVerifyOTPBtnLoader(false);
      });
  };

  return (
    <Grid container>
      {siteMap && (
        <Grid container className={classes.siteMapContainer}>
          <Grid
            container
            className={classes.siteMapTitleContainer}
            gap={1.5}
            marginTop={"10px"}
          >
            <Grid item xs={0.8} className={classes.titleSide}></Grid>
            <Grid
              item
              xs={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                style={{
                  fontFamily: FontFamily.Bold,
                  fontSize: sm ? "20px" : md ? "25px" : "30px",
                }}
              >
                {translate("sitemap_title")}
              </Typography>
            </Grid>
            <Grid item xs={0.8} className={classes.titleSide}></Grid>
          </Grid>
          <Grid container mb={"10px"} mt={"20px"}>
            <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
            <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>
              <Grid
                container
                display={"flex"}
                flexWrap={sm && "wrap"}
                marginBottom={"20px"}
              >
                <Grid item xs={6} sm={3} md={3} paddingLeft={"5px"}>
                  <Grid container>
                    <span style={{ fontSize: sm ? 16 : 18 }}>Post</span>
                  </Grid>
                  <Grid container rowSpacing={2}>
                    {postSiteMapRoutes.map((item) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div>
                            <FaCaretRight
                              style={{ color: BaseColor.primary }}
                            />
                          </div>
                          <a
                            href={
                              item?.id !== 1 &&
                              (loginRequired || !isProfileComplete)
                                ? "javascript:;"
                                : item?.navigate
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              key={`legal_${item.id}`}
                              className={classes.companyAndLegalContainer}
                            >
                              <Link
                                underline="hover"
                                style={{
                                  fontSize: sm ? 14 : 16,
                                  lineHeight: 2,
                                  fontFamily: FontFamily.Regular,
                                  textAlign: "left",
                                  color: BaseColor.textColor,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (item.id == 1) {
                                    navigate(item.navigate);
                                  } else if (item.id == 2 || item.id == 3) {
                                    if (loginRequired) {
                                      setLoginModalOpen(true);
                                    } else {
                                      navigate(item.navigate);
                                    }
                                  } else {
                                    if (loginRequired) {
                                      setLoginModalOpen(true);
                                    } else if (!isProfileComplete) {
                                      setProfileCompleted(true);
                                    } else {
                                      navigate(item.navigate);
                                    }
                                  }
                                }}
                              >
                                {item.title}
                              </Link>
                            </div>
                          </a>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={3} md={3} paddingLeft={"5px"}>
                  <Grid container>
                    <span style={{ fontSize: sm ? 16 : 18 }}>My account</span>
                  </Grid>
                  <Grid container rowSpacing={2}>
                    {userSiteMapRoutes.map((item) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div>
                            <FaCaretRight
                              style={{ color: BaseColor.primary }}
                            />
                          </div>
                          <a
                            href={
                              loginRequired || !isProfileComplete
                                ? "javascript:;"
                                : item?.navigate
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              key={`legal_${item.id}`}
                              className={classes.companyAndLegalContainer}
                            >
                              <Link
                                underline="hover"
                                style={{
                                  fontSize: sm ? 14 : 16,
                                  lineHeight: 2,
                                  fontFamily: FontFamily.Regular,
                                  textAlign: "left",
                                  color: BaseColor.textColor,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (!_.isUndefined(item.navigate)) {
                                    if (item.id == 3) {
                                      if (loginRequired) {
                                        setLoginModalOpen(true);
                                      } else {
                                        navigate(item.navigate);
                                      }
                                    } else {
                                      if (loginRequired) {
                                        setLoginModalOpen(true);
                                      } else if (!isProfileComplete) {
                                        setProfileCompleted(true);
                                      } else {
                                        navigate(item.navigate);
                                      }
                                    }
                                  }
                                }}
                              >
                                {item.title}
                              </Link>
                            </div>
                          </a>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={3}
                  md={3}
                  style={{ marginTop: sm && "15px" }}
                  paddingLeft={"5px"}
                >
                  <Grid container>
                    <span style={{ fontSize: sm ? 16 : 18 }}>Overview</span>
                  </Grid>
                  <Grid container rowSpacing={2}>
                    {generalSiteMapRoutes.map((item) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div>
                            <FaCaretRight
                              style={{ color: BaseColor.primary }}
                            />
                          </div>
                          <a
                            href={item?.navigate}
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              key={`legal_${item.id}`}
                              className={classes.companyAndLegalContainer}
                            >
                              <Link
                                underline="hover"
                                style={{
                                  fontSize: sm ? 14 : 16,
                                  lineHeight: 2,
                                  fontFamily: FontFamily.Regular,
                                  textAlign: "left",
                                  color: BaseColor.textColor,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(item.navigate);
                                }}
                              >
                                {item.title}
                              </Link>
                            </div>
                          </a>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={3}
                  md={3}
                  style={{ marginTop: sm && "15px" }}
                  paddingLeft={"5px"}
                >
                  <Grid container>
                    <span style={{ fontSize: sm ? 16 : 18 }}>Chat</span>
                  </Grid>
                  <Grid container rowSpacing={2}>
                    {chatSiteMapRoutes.map((item, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div>
                            <FaCaretRight
                              style={{ color: BaseColor.primary }}
                            />
                          </div>
                          <a
                            href={
                              loginRequired || !isProfileComplete
                                ? "javascript:;"
                                : item.navigate
                            }
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              key={`legal_${item.id}`}
                              className={classes.companyAndLegalContainer}
                            >
                              <Link
                                underline="hover"
                                style={{
                                  fontSize: sm ? 14 : 16,
                                  lineHeight: 2,
                                  fontFamily: FontFamily.Regular,
                                  textAlign: "left",
                                  color: BaseColor.textColor,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (loginRequired) {
                                    setLoginModalOpen(true);
                                  } else if (!isProfileComplete) {
                                    setProfileCompleted(true);
                                  } else {
                                    if (item?.id === 2) {
                                      navigate("/chat", {
                                        state: { type: "Admin" },
                                      });
                                    } else {
                                      navigate(item.navigate);
                                    }
                                  }
                                }}
                              >
                                {item.title}
                              </Link>
                            </div>
                          </a>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
          </Grid>
        </Grid>
      )}

      <Grid container className={classes.container}>
        <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
        <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>
          <Grid
            container
            className={classes.topGridContainer}
            display={"flex"}
            justifyContent={"space-between"}
            wrap={md ? "wrap" : "nowrap"}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ marginBottom: 20 }}
            >
              <Grid container>
                <Grid item xs={12} sm={7} md={7} lg={8}>
                  <div style={{ marginBottom: "10px" }}>
                    <img
                      alt={"logo"}
                      src={Files.images.logo}
                      className={classes.logo}
                      style={{ width: md ? 150 : 200 }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2.5}
                  md={11}
                  lg={5}
                  padding={"5px"}
                  style={{ cursor: "pointer" }}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.scrapc"
                    target="_blank"
                    rel="noopener"
                    onClick={() => {
                      if (isUserFirstTime) {
                        dispatch(setUserFirstTime(false));
                      }
                    }}
                  >
                    <img
                      class="CToWUd"
                      style={{ height: "45px", width: "100%" }}
                      src="https://scrapc.sgp1.digitaloceanspaces.com/live/important_files/playstore.png"
                      alt="ScrpaC app playstore link"
                    ></img>
                  </a>{" "}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2.5}
                  md={11}
                  lg={5}
                  padding={"5px"}
                  style={{ cursor: "pointer" }}
                >
                  <a
                    href="https://apps.apple.com/in/app/scrapc/id6453885238"
                    target="_blank"
                    rel="noopener"
                    onClick={() => {
                      if (isUserFirstTime) {
                        dispatch(setUserFirstTime(false));
                      }
                    }}
                  >
                    <img
                      class="CToWud"
                      style={{ height: "45px", width: "100%" }}
                      src="https://scrapc.sgp1.digitaloceanspaces.com/live/important_files/applestore.png"
                      alt="ScrapC appstore link"
                    ></img>
                  </a>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Grid container display={"flex"} justifyContent={"space-between"}>
                <Grid item xs={5.5} sm={2.5} md={3.5}>
                  <div className={classes.companyAndLegalHeaderTextContainer}>
                    <span className={classes.companyAndLegalHeaderText}>
                      {translate("company_title")}
                    </span>
                  </div>
                  {footerCompanyHeaderData.map((item) => {
                    return (
                      <a
                        href={
                          (item.id === 1 || item.id === 2) &&
                          (loginRequired || !isProfileComplete)
                            ? "javascript:;"
                            : item?.navigate
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          key={`company ${item.id}`}
                          className={classes.companyAndLegalContainer}
                        >
                          <Link
                            style={{
                              fontSize: sm ? 14 : 16,
                              lineHeight: 2,
                              fontFamily: FontFamily.Regular,
                              textAlign: "left",
                              color: BaseColor.whiteColor,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!_.isUndefined(item.navigate)) {
                                if (item.id === 1 || item.id === 2) {
                                  if (loginRequired) {
                                    setLoginModalOpen(true);
                                  }
                                  // else if (!isProfileComplete) {
                                  //   setProfileCompleted(true);
                                  // }
                                  else {
                                    navigate(item.navigate);
                                  }
                                } else {
                                  window.location.replace(item.navigate);
                                  if (isUserFirstTime) {
                                    dispatch(setUserFirstTime(false));
                                  }
                                }
                              }
                            }}
                          >
                            {item.title}
                          </Link>
                        </div>
                      </a>
                    );
                  })}
                </Grid>
                <Grid item xs={5.5} sm={2.5} md={3.5}>
                  <div className={classes.companyAndLegalHeaderTextContainer}>
                    <span className={classes.companyAndLegalHeaderText}>
                      {translate("legal_title")}
                    </span>
                  </div>
                  {footerLegalHeaderData.map((item) => {
                    return (
                      <a
                        href={item?.navigate}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          key={`legal_${item.id}`}
                          className={classes.companyAndLegalContainer}
                        >
                          <Link
                            style={{
                              fontSize: sm ? 14 : 16,
                              lineHeight: 2,
                              fontFamily: FontFamily.Regular,
                              textAlign: "left",
                              color: BaseColor.whiteColor,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate(item.navigate);
                              if (isUserFirstTime) {
                                dispatch(setUserFirstTime(false));
                              }
                            }}
                          >
                            {item.title}
                          </Link>
                        </div>
                      </a>
                    );
                  })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  className={classes.newsLetterMainContainer}
                >
                  <div
                    className={classes.newsLetterTextContainer}
                    style={{ marginTop: sm && "10px" }}
                  >
                    <span className={classes.newsletterText}>
                      Registered Branch (Singapore)
                    </span>

                    <span className={classes.newsletterDescriptionText}>
                      68 Circular Road #02-01 -Singapore (049422) Singapore.
                    </span>
                  </div>
                  <div
                    className={classes.newsLetterTextContainer}
                    style={{ marginTop: "10px" }}
                  >
                    <span className={classes.newsletterText}>
                      Branch Office (India)
                    </span>
                    <span className={classes.newsletterDescriptionText}>
                      Mega iscon city Gate no- 5 Beside Basil Park hotel ,5B,
                      opp. Victoria Park, Bhavnagar, Gujarat –364002.
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className={classes.emailInputContainer}
                      style={{
                        border:
                          !_.isEmpty(contactUsErrorText) &&
                          `1px solid ${BaseColor.errorRed}`,
                      }}
                    >
                      <InputBase
                        style={{
                          width: "70%",
                          background: BaseColor.whiteColor,
                          color: BaseColor.blackColor,
                          marginLeft: 15,
                          fontSize: isMobile ? 12 : 16, // Apply dynamic font-size
                          paddingTop: isMobile ? 2 : "",
                        }}
                        value={email}
                        onChange={(val) => {
                          setContactUsErrorText("");
                          setEmail(val.target.value);
                        }}
                        placeholder={translate("enter_email_placeholder")}
                        inputProps={{ "aria-label": "description" }}
                      />
                      <ButtonBase
                        style={{
                          width: "30%",
                          padding: "12px",
                          background: BaseColor.primary,
                        }}
                        onClick={() => {
                          validation();
                        }}
                      >
                        {subscribeBtnLoader ? (
                          <CircularProgress size={20} color="secondary" />
                        ) : (
                          <span className={classes.subscribeBtnText}>
                            {translate("subscribe_button")}
                          </span>
                        )}
                      </ButtonBase>
                    </div>
                    {!_.isEmpty(contactUsErrorText) && (
                      <div className={classes.errorMsgContainer}>
                        <span className={classes.errorMsgStarText}>*</span>
                        <span className={classes.errorMsgText}>
                          {contactUsErrorText}
                        </span>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            borderBottom={"1px solid white"}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <Grid
            container
            className={classes.bottomGridContainer}
            style={{ marginRight: 10 }}
          >
            <Grid
              style={{
                marginBottom: 10,
                display: "flex",
                justifyContent: sm && "center",
                width: sm && "100%",
              }}
            >
              <span className={classes.copyRightText}>
                {BaseSetting.copyRightText}
              </span>
            </Grid>
            <Grid className={classes.socialMainIconContainer}>
              <a
                href={
                  !isEmpty(socialLinkData?.facebook)
                    ? socialLinkData?.facebook?.value
                    : "https://www.facebook.com/"
                }
                onClick={() => {
                  if (isUserFirstTime) {
                    dispatch(setUserFirstTime(false));
                  }
                }}
              >
                <div
                  className={classes.socialIconContainer}
                  style={{ marginRight: 8 }}
                >
                  <TfiFacebook className={classes.socialIcon} />
                </div>
              </a>
              <a
                href={
                  !isEmpty(socialLinkData?.instagram)
                    ? socialLinkData?.instagram?.value
                    : "https://www.instagram.com"
                }
                onClick={() => {
                  if (isUserFirstTime) {
                    dispatch(setUserFirstTime(false));
                  }
                }}
              >
                <div
                  className={classes.socialIconContainer}
                  style={{ marginRight: 8 }}
                >
                  <BsInstagram className={classes.socialIcon} />
                </div>
              </a>

              <a
                href={
                  !isEmpty(socialLinkData?.twitter)
                    ? socialLinkData?.twitter?.value
                    : "https://twitter.com/i/flow/login"
                }
                onClick={() => {
                  if (isUserFirstTime) {
                    dispatch(setUserFirstTime(false));
                  }
                }}
              >
                <div
                  className={classes.socialIconContainer}
                  style={{ marginRight: 8 }}
                >
                  <TfiTwitterAlt className={classes.socialIcon} />
                </div>
              </a>
              <a
                href={
                  !isEmpty(socialLinkData?.linkdin)
                    ? socialLinkData?.linkdin?.value
                    : "https://in.linkedin.com"
                }
                onClick={() => {
                  if (isUserFirstTime) {
                    dispatch(setUserFirstTime(false));
                  }
                }}
              >
                <div className={classes.socialIconContainer}>
                  <TfiLinkedin className={classes.socialIcon} />
                </div>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />

        {/* is profile complete modal */}
        <CModal
          visible={profileCompleted}
          onClose={() => setProfileCompleted(false)}
          children={
            <div style={{ padding: 20 }}>
              <img
                alt="ScrapC"
                src={Files?.images?.colorLogo}
                style={{ height: 100, width: 300, objectFit: "contain" }}
              />

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <span
                  style={{
                    fontSize: 20,
                    color: BaseColor.blackColor,
                    fontFamily: FontFamily.SemiBold,
                    fontWeight: "900",
                  }}
                >
                  {translate("profile_complete_warning_modal_title")}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CButton
                  onClick={() =>
                    navigate("/create-profile", {
                      state: { isEditable: true },
                    })
                  }
                  style={{ width: "90%" }}
                >
                  {translate("go_to_profile")}
                </CButton>
                <CButton
                  onClick={() => setProfileCompleted(false)}
                  style={{ width: "90%" }}
                >
                  {translate("cancel_button")}
                </CButton>
              </div>
            </div>
          }
        />

        {/* Login Modal */}
        <CModal
          visible={loginModalOpen}
          onClose={() => {
            // if (fromDirectLoginModal === false) {
            loginAllErrorFalse();
            setEmailOrMobile("");
            setPassword("");
            setOtp("");
            setLoginModalOpen(false);
            setType("otp");
            setEmailOrMobileDisable(false);
            setIsOTPGenerated(false);
            const phoneCode = countryListPhone?.filter(
              (v) => v?.phone_code === "+91"
            );
            let data;
            phoneCode.map((item) => {
              data = item;
            });
            setCountryPhoneCode(data);
            dispatch(setFirstTimeSkip(true));
            setLoginModalOpen(false);
            // }
          }}
          children={
            <Grid style={{ position: "relative" }}>
              <Grid
                container
                style={{
                  width: md ? "90vw" : "600px",
                  maxHeight: "81vh",
                  overflowY: "scroll",
                }}
                className={classes.scrollBar}
              >
                {/* {fromDirectLoginModal === false && ( */}
                <CloseIcon
                  onClick={() => {
                    loginAllErrorFalse();
                    setEmailOrMobile("");
                    setPassword("");
                    setOtp("");
                    setLoginModalOpen(false);
                    setType("otp");
                    setEmailOrMobileDisable(false);
                    setIsOTPGenerated(false);
                    const phoneCode = countryListPhone?.filter(
                      (v) => v?.phone_code === "+91"
                    );
                    let data;
                    phoneCode.map((item) => {
                      data = item;
                    });
                    setCountryPhoneCode(data);
                  }}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 15,
                    fontSize: 30,
                    cursor: "pointer",
                    color: BaseColor.primary,
                    zIndex: 1,
                  }}
                />
                {/* )} */}

                <div
                  style={{
                    padding: md ? "20px 20px 0px 20px" : "30px 30px 18px 30px",
                    position: "relative",
                  }}
                >
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item xs={12} textAlign={"center"}>
                      <span
                        style={{
                          fontFamily: FontFamily.Bold,
                          fontSize: md ? "25px" : xs ? "20px" : "35px",
                        }}
                      >
                        {translate("login_header_text")}
                      </span>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                      <p
                        style={{
                          fontFamily: FontFamily.Medium,
                          fontSize: md ? "14px" : "17px",
                        }}
                      >
                        {translate("login_description_text")}
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      marginTop={"20px"}
                      marginBottom={type === "otp" ? 0 : "20px"}
                    >
                      <CInput
                        placeholder={translate("email_or_mobile_placeholder")}
                        value={emailOrMobile}
                        disabled={emailOrMobileDisable}
                        onChange={(val) => setEmailOrMobile(val)}
                        maxLength={
                          emailOrMobile.length >= 9
                            ? /^[0-9]*$/.test(String(emailOrMobile))
                              ? 12
                              : 40
                            : 40
                        }
                        startIcon={
                          <>
                            <CIcon
                              src={Files.svgIcons.phoneEmail}
                              className="CInputStartIcon"
                            />
                            {/^-?\d*\.?\d*$/.test(String(emailOrMobile)) &&
                            emailOrMobile.trim().length >= 5 ? (
                              <>
                                <CAutoComplete
                                  disabled={emailOrMobileDisable}
                                  options={countryListPhone}
                                  // label="phone code"
                                  phoneCode
                                  selectedValue={countryPhoneCode}
                                  onSelect={(val) => {
                                    loginAllErrorFalse();
                                    setCountryPhoneCode(val);
                                  }}
                                  style={{ outline: "none", width: "100px" }}
                                />
                                <div
                                  style={{
                                    height: 25,
                                    borderRight: `1px solid ${BaseColor.primary}`,
                                  }}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        }
                        endIcon={
                          emailOrMobileDisable ? (
                            <CIcon
                              src={Files.svgIcons.editFill}
                              className="CInputStartIcon"
                              onClick={() => {
                                setEmailOrMobileDisable(false);
                                setIsOTPGenerated(false);
                                setGenerateOTPLoginBtnLoader(false);
                                setEmailOrMobileErrorText("");
                              }}
                            />
                          ) : null
                        }
                        errorMsg={emailOrMobileErrorText}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {type === "otp" ? (
                        <>
                          <Grid item xs={12}>
                            <CButton
                              variant={"contained"}
                              style={{
                                marginTop: "10px",
                                marginBottom: 20,
                                width: "100%",
                              }}
                              onClick={() => {
                                if (
                                  /^-?\d*\.?\d*$/.test(String(emailOrMobile))
                                ) {
                                  if (countryPhoneCode?.phone_code === "+91") {
                                    generateOTPValidation("login");
                                  } else {
                                    toast(
                                      "Your country is not supported for OTP, please login with email",
                                      {
                                        type: "error",
                                      }
                                    );
                                  }
                                } else {
                                  generateOTPValidation("login");
                                }
                              }}
                              disabled={
                                isOTPGenerated ||
                                generateOTPLoginBtnLoader ||
                                isEmpty(emailOrMobile)
                              }
                              loading={generateOTPLoginBtnLoader}
                            >
                              {translate("get_otp_btn")}
                            </CButton>
                          </Grid>
                          {isOTPGenerated && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginBottom: "20px",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: FontFamily.SemiBold,
                                    fontWeight: "900",
                                  }}
                                >
                                  {translate("entry_otp_here")}
                                </span>
                                <CResendBtn
                                  ref={loginTimerRef}
                                  isOTPGenerated={isOTPGenerated}
                                  onClick={() => {
                                    generateOTPValidation("login");
                                  }}
                                />
                              </div>
                              <COtpInput
                                value={otp}
                                onChange={(val) => {
                                  setOtp(val);
                                  if (val.length >= 6) {
                                    verifyOTPValidation("login", val);
                                  }
                                }}
                                errorMsg={otpInputErrorText}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <CInput
                          placeholder={translate("password_placeholder")}
                          F
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(val) => setPassword(val?.trim())}
                          startIcon={
                            <CIcon
                              src={Files.svgIcons.lock}
                              className="CInputStartIcon"
                            />
                          }
                          errorMsg={passwordErrorText}
                          endIcon={
                            !showPassword ? (
                              <AiOutlineEye
                                className="CInputStartIcon"
                                onClick={() => setShowPassword(true)}
                                style={{
                                  color: BaseColor.primary,
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <AiOutlineEyeInvisible
                                className="CInputStartIcon"
                                onClick={() => setShowPassword(false)}
                                style={{
                                  color: BaseColor.primary,
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                              />
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {type === "otp" ? (
                        <>
                          <div
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                          >
                            <Link
                              className={classes.link}
                              style={{
                                fontFamily: FontFamily.SemiBold,
                                fontWeight: "900",
                              }}
                              onClick={() => {
                                setType("login");
                                setEmailOrMobileDisable(false);
                              }}
                            >
                              {translate(`login_with_password`)}
                            </Link>
                          </div>
                          {isOTPGenerated && (
                            <Grid item xs={12}>
                              <CButton
                                disabled={isEmpty(otp)}
                                style={{ marginTop: "10px" }}
                                onClick={() => {
                                  verifyOTPValidation("login", otp);
                                }}
                                loading={verifyOTPLoginBtnLoader}
                                variant="contained"
                              >
                                {translate("login_button")}
                              </CButton>
                            </Grid>
                          )}
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontFamily: FontFamily.Medium,
                                marginTop: "10px",
                                justifyContent: "center",
                              }}
                            >
                              <CCheckBox
                                checked={rememberMe}
                                onChange={(val) => {
                                  setRememberMe(val);
                                }}
                                style={{
                                  color: BaseColor.primary,
                                  padding: 0,
                                  marginRight: 5,
                                }}
                                label="Remember me"
                              />
                            </div>
                            <Link
                              className={classes.link}
                              sx={{
                                textDecoration: "none",
                                fontFamily: FontFamily.SemiBold,
                                fontWeight: "900",

                                marginTop: "10px",
                                fontSize: sm ? 12 : 14,
                              }}
                              onClick={() => {
                                setLoginModalOpen(false);
                                setForgotPasswordModalOpen(true);
                                setEmailOrMobile("");
                                setEmailOrMobileErrorText("");
                                setEmailOrMobileDisable(false);
                                const phoneCode = countryListPhone?.filter(
                                  (v) => v?.phone_code === "+91"
                                );
                                let data;
                                phoneCode.map((item) => {
                                  data = item;
                                });
                                setCountryPhoneCode(data);
                              }}
                            >
                              {translate("forgot_password")}
                            </Link>
                          </div>
                          <div
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                          >
                            <Link
                              className={classes.link}
                              style={{
                                fontFamily: FontFamily.SemiBold,
                                fontWeight: "900",
                              }}
                              onClick={() => {
                                if (
                                  /^-?\d*\.?\d*$/.test(String(emailOrMobile))
                                ) {
                                  if (countryPhoneCode?.phone_code === "+91") {
                                    setType("otp");
                                  } else {
                                    setEmailOrMobileErrorText(
                                      translate("otp_not_supported_message")
                                    );
                                  }
                                } else {
                                  setType("otp");
                                }
                              }}
                            >
                              {translate(`login_with_otp`)}
                            </Link>
                          </div>

                          <Grid container justifyContent={"center"}>
                            <ReCAPTCHA
                              sitekey={BaseSetting.captchaKey}
                              onChange={() => setIsCaptcha(true)}
                              size={isMobile && "compact"}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <CButton
                              style={{ marginTop: "10px" }}
                              onClick={() => {
                                loginValidation("password");
                              }}
                              disabled={!isCaptcha}
                              loading={loginModelPassBtnLoader}
                              variant="contained"
                            >
                              {translate("login_button")}
                            </CButton>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: BaseColor.primary,
                            width: "50%",
                            height: "1px",
                          }}
                        ></div>
                        <div style={{ padding: "0px 5px" }}>
                          {translate("or")}
                        </div>
                        <div
                          style={{
                            backgroundColor: BaseColor.primary,
                            width: "50%",
                            height: "1px",
                          }}
                        ></div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <CGoogle
                        isModal
                        loginPage
                        onLoginSuccess={(bool) => {
                          if (bool) {
                            setLoginModalOpen(false);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <p
                        style={{
                          marginTop: "20px",
                          fontFamily: FontFamily.Medium,
                          fontSize: sm ? 14 : 16,
                        }}
                        align="center"
                      >
                        {translate("do_you_have_account")}{" "}
                        <Link
                          className={classes.link}
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontWeight: "900",
                          }}
                          onClick={() => {
                            setLoginModalOpen(false);
                            setOpenSignupUsingOtpModal(true);
                            setEmailOrMobile("");
                            setPassword("");
                            setEmailOrMobileErrorText("");
                            setPasswordErrorText("");
                            setOtp("");
                            setOtpInputErrorText("");
                            const phoneCode = countryListPhone?.filter(
                              (v) => v?.phone_code === "+91"
                            );
                            let data;
                            phoneCode.map((item) => {
                              data = item;
                            });
                            setCountryPhoneCode(data);
                          }}
                        >
                          {translate("create_account")}
                        </Link>
                      </p>
                    </Grid>
                    {fromDirectLoginModal && (
                      <Grid item xs={12}>
                        <p
                          style={{
                            fontFamily: FontFamily.Medium,
                            fontSize: sm ? 14 : 16,
                          }}
                          align="center"
                        >
                          <Link
                            className={classes.link}
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontWeight: "900",
                            }}
                            onClick={() => {
                              const phoneCode = countryListPhone?.filter(
                                (v) => v?.phone_code === "+91"
                              );
                              let data;
                              phoneCode.map((item) => {
                                data = item;
                              });
                              setCountryPhoneCodeDirectLogin(data);
                              setLoginModalOpen(false);
                              setDirectLoginModal(true);
                              setEmailOrMobile("");
                              setPassword("");
                              setEmailOrMobileErrorText("");
                              setPasswordErrorText("");
                              setOtp("");
                              setOtpInputErrorText("");
                            }}
                          >
                            Direct login using OTP
                          </Link>
                        </p>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          }
        />

        {/* Create Account Modal */}
        <CModal
          visible={CreateAccountModalOpen}
          onClose={() => {
            // if (fromDirectLoginModal === false) {
            setEmailOrMobileCreateAcc("");
            setPasswordCreateAcc("");
            setRePasswordCreateAcc("");
            allErrorFalseCreateAcc();
            setCreateAccountModalOpen(false);
            setIsAccountCreated(false);
            const phoneCode = countryListPhone?.filter(
              (v) => v?.phone_code === "+91"
            );
            let data;
            phoneCode.map((item) => {
              data = item;
            });
            setCountryPhoneCodeCreateAcc(data);
            dispatch(setFirstTimeSkip(true));
            setCreateAccountModalOpen(false);
            // }
          }}
          children={
            <Grid style={{ position: "relative" }}>
              <Grid
                container
                style={{
                  width: md ? "90vw" : "600px",
                  maxHeight: "80vh",
                  overflowY: "scroll",
                }}
                className={classes.scrollBar}
              >
                {/* {fromDirectLoginModal === false && ( */}
                <CloseIcon
                  onClick={() => {
                    setEmailOrMobileCreateAcc("");
                    dispatch(setFirstTimeSkip(true));
                    setCreateAccountModalOpen(false);
                    setPasswordCreateAcc("");
                    setRePasswordCreateAcc("");
                    allErrorFalseCreateAcc();
                    setCreateAccountModalOpen(false);
                    setIsAccountCreated(false);
                    setCreateAccOtpGenerated(false);
                    const phoneCode = countryListPhone?.filter(
                      (v) => v?.phone_code === "+91"
                    );
                    let data;
                    phoneCode.map((item) => {
                      data = item;
                    });
                    setCountryPhoneCodeCreateAcc(data);
                  }}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 15,
                    fontSize: 30,
                    cursor: "pointer",
                    color: BaseColor.primary,
                    zIndex: 1,
                  }}
                />
                {/* )} */}
                <div
                  style={{
                    padding: md ? "20px 20px 20px 20px" : "30px 30px 25px 30px",
                  }}
                >
                  <Grid container style={{ marginTop: 20 }}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Grid item xs={12} textAlign={"center"}>
                              <span
                                style={{
                                  fontFamily: FontFamily.Bold,
                                  fontSize: md ? "25px" : "35px",
                                }}
                              >
                                {translate("create_account_header_text")}
                              </span>
                            </Grid>
                            <Grid item xs={12} textAlign={"center"}>
                              <p
                                style={{
                                  fontFamily: FontFamily.Medium,
                                  fontSize: sm ? "14px" : "17px",
                                }}
                              >
                                {translate("create_account_description_text")}
                              </p>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={12} marginTop={sm ? "10px" : "20px"}>
                              <CInput
                                disabled={
                                  isAccountCreated || createAccOtpGenerated
                                }
                                placeholder={translate(
                                  "email_or_mobile_placeholder"
                                )}
                                value={emailOrMobileCreateAcc}
                                onChange={(val) => {
                                  setEmailOrMobileCreateAcc(val?.trim());
                                  allErrorFalseCreateAcc();
                                }}
                                maxLength={
                                  emailOrMobileCreateAcc.length >= 9
                                    ? /^[0-9]*$/.test(
                                        String(emailOrMobileCreateAcc)
                                      )
                                      ? 12
                                      : 40
                                    : 40
                                }
                                startIcon={
                                  <>
                                    <CIcon
                                      src={Files.svgIcons.phoneEmail}
                                      className="CInputStartIcon"
                                    />
                                    {/^-?\d*\.?\d*$/.test(
                                      String(emailOrMobileCreateAcc)
                                    ) &&
                                    emailOrMobileCreateAcc.trim().length >=
                                      5 ? (
                                      <>
                                        <CAutoComplete
                                          options={countryListPhone}
                                          phoneCode
                                          disabled={
                                            isAccountCreated ||
                                            createAccOtpGenerated
                                          }
                                          selectedValue={
                                            countryPhoneCodeCreateAcc
                                          }
                                          onSelect={(val) => {
                                            setCountryPhoneCodeCreateAcc(val);
                                          }}
                                          style={{
                                            outline: "none",
                                            width: "100px",
                                          }}
                                        />
                                        <div
                                          style={{
                                            height: 25,
                                            borderRight: `1px solid ${BaseColor.primary}`,
                                          }}
                                        />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                }
                                endIcon={
                                  (isAccountCreated ||
                                    createAccOtpGenerated) && (
                                    <CIcon
                                      src={Files.svgIcons.editFill}
                                      className="CInputStartIcon"
                                      onClick={() => {
                                        setIsAccountCreated(false);
                                        setCreateAccOtpGenerated(false);
                                        setCreateAccGenerateOtpBtnLoader(false);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  )
                                }
                                errorMsg={emailOrMobileErrorTextCreateAcc}
                              />
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>

                            {isAccountCreated ? (
                              <>
                                <Grid item xs={12}>
                                  <CButton
                                    variant={"contained"}
                                    style={{ marginTop: "20px", width: "100%" }}
                                    disabled={
                                      createAccOtpGenerated ||
                                      createAccGenerateOtpBtnLoader ||
                                      isEmpty(emailOrMobileCreateAcc)
                                    }
                                    loading={createAccGenerateOtpBtnLoader}
                                    onClick={() => {
                                      generateOTPValidation();
                                    }}
                                  >
                                    {translate("get_otp_btn")}
                                  </CButton>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}></Grid>
                                {createAccOtpGenerated && (
                                  <Grid item xs={12} marginTop={"20px"}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontFamily: FontFamily.SemiBold,
                                          fontWeight: "900",
                                        }}
                                      >
                                        {translate("entry_otp_here")}
                                      </span>
                                      <CResendBtn
                                        ref={createAccTimerRef}
                                        isOTPGenerated={createAccOtpGenerated}
                                        onClick={() => {
                                          generateOTPValidation();
                                        }}
                                      />
                                    </div>
                                    <COtpInput
                                      value={otpCreateAcc}
                                      onChange={(val) => {
                                        setOtpCreateAcc(val);
                                        if (val.length >= 6) {
                                          verifyOTPValidation(
                                            "createAccount",
                                            val
                                          );
                                        }
                                      }}
                                      errorMsg={createAccOtpErrorText}
                                    />
                                  </Grid>
                                )}

                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}></Grid>
                                {createAccOtpGenerated && (
                                  <Grid item xs={12}>
                                    <CButton
                                      variant={"contained"}
                                      style={{
                                        marginTop: "10px",
                                        width: "100%",
                                      }}
                                      disabled={isEmpty(otpCreateAcc)}
                                      loading={createAccVerifyOtpBtnLoader}
                                      onClick={() => {
                                        verifyOTPValidation(
                                          "createAccount",
                                          otpCreateAcc
                                        );
                                      }}
                                    >
                                      {translate("create_account_button")}
                                    </CButton>
                                  </Grid>
                                )}
                              </>
                            ) : (
                              <>
                                <Grid item xs={12} marginTop={"20px"}>
                                  <CInput
                                    type={
                                      showPasswordCreateAcc
                                        ? "text"
                                        : "password"
                                    }
                                    placeholder={translate(
                                      "password_placeholder"
                                    )}
                                    value={passwordCreateAcc}
                                    onChange={(val) => {
                                      setPasswordCreateAcc(val?.trim());
                                      allErrorFalseCreateAcc();
                                    }}
                                    startIcon={
                                      <CIcon
                                        src={Files.svgIcons.lock}
                                        className="CInputStartIcon"
                                      />
                                    }
                                    errorMsg={passwordErrorTextCreateAcc}
                                    endIcon={
                                      showPasswordCreateAcc ? (
                                        <AiOutlineEye
                                          className="CInputStartIcon"
                                          onClick={() =>
                                            setShowPasswordCreateAcc(false)
                                          }
                                          style={{
                                            color: BaseColor.primary,
                                            fontSize: "20px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      ) : (
                                        <AiOutlineEyeInvisible
                                          className="CInputStartIcon"
                                          onClick={() =>
                                            setShowPasswordCreateAcc(true)
                                          }
                                          style={{
                                            color: BaseColor.primary,
                                            fontSize: "20px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={12} marginTop={"20px"}>
                                  <CInput
                                    type={
                                      showRePasswordCreateAcc
                                        ? "text"
                                        : "password"
                                    }
                                    placeholder={translate(
                                      "re_enter_password_placeholder"
                                    )}
                                    value={rePasswordCreateAcc}
                                    onChange={(val) => {
                                      setRePasswordCreateAcc(val?.trim());
                                      allErrorFalseCreateAcc();
                                    }}
                                    startIcon={
                                      <CIcon
                                        src={Files.svgIcons.lock}
                                        className="CInputStartIcon"
                                      />
                                    }
                                    errorMsg={rePasswordErrorTextCreateAcc}
                                    endIcon={
                                      showRePasswordCreateAcc ? (
                                        <AiOutlineEye
                                          className="CInputStartIcon"
                                          onClick={() =>
                                            setShowRePasswordCreateAcc(false)
                                          }
                                          style={{
                                            color: BaseColor.primary,
                                            fontSize: "20px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      ) : (
                                        <AiOutlineEyeInvisible
                                          className="CInputStartIcon"
                                          onClick={() =>
                                            setShowRePasswordCreateAcc(true)
                                          }
                                          style={{
                                            color: BaseColor.primary,
                                            fontSize: "20px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      width: "100%",
                                      fontFamily: FontFamily.Medium,
                                      marginTop: "10px",
                                    }}
                                  >
                                    <CCheckBox
                                      register
                                      checked={acceptTerms}
                                      onChange={(val) => {
                                        setAcceptTerms(val);
                                        setAcceptTermsErrorTextCreateAcc("");
                                      }}
                                      errorMsg={acceptTermsErrorTextCreateAcc}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={12}></Grid>

                                <Grid
                                  container
                                  justifyContent={"center"}
                                  marginTop={"20px"}
                                >
                                  <ReCAPTCHA
                                    sitekey={BaseSetting.captchaKey}
                                    onChange={() => setIsCaptcha(true)}
                                    size={isMobile && "compact"}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <CButton
                                    variant={"contained"}
                                    style={{ marginTop: "20px", width: "100%" }}
                                    loading={createAccountModalBtnLoader}
                                    disabled={!isCaptcha}
                                    onClick={() => {
                                      // validationCreateAcc();
                                      if (
                                        /^-?\d*\.?\d*$/.test(
                                          String(emailOrMobileCreateAcc)
                                        )
                                      ) {
                                        if (
                                          countryPhoneCodeCreateAcc?.phone_code ===
                                          "+91"
                                        ) {
                                          validationCreateAcc();
                                        } else {
                                          toast(
                                            translate(
                                              "otp_not_supported_message"
                                            ),
                                            {
                                              type: "error",
                                            }
                                          );
                                        }
                                      } else {
                                        validationCreateAcc();
                                      }
                                    }}
                                  >
                                    {translate("continue_btn")}
                                  </CButton>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: BaseColor.primary,
                                        width: "50%",
                                        height: "1px",
                                      }}
                                    ></div>
                                    <div style={{ padding: "0px 5px" }}>
                                      {translate("or")}
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: BaseColor.primary,
                                        width: "50%",
                                        height: "1px",
                                      }}
                                    ></div>
                                  </div>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={12}>
                                  <CGoogle
                                    isModal
                                    onSuccess={(bool) => {
                                      if (bool) {
                                        setCreateAccountModalOpen(false);
                                      }
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            {(!isAccountCreated || !createAccOtpGenerated) && (
                              <Grid
                                container
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <Typography
                                  style={{
                                    marginTop: "20px",
                                    fontFamily: FontFamily.Medium,
                                  }}
                                  align="center"
                                >
                                  <Link
                                    className={classes.link}
                                    style={{
                                      fontFamily: FontFamily.SemiBold,
                                      fontWeight: "900",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setOpenSignupUsingOtpModal(true);
                                      setCreateAccountModalOpen(false);
                                      setSignupCountryCode(
                                        countryPhoneCodeCreateAcc
                                      );
                                      setSignupEmailOrMobile(
                                        emailOrMobileCreateAcc
                                      );
                                      setIsAccountCreated(false);
                                      setPasswordCreateAcc("");
                                      setRePasswordCreateAcc("");
                                      setPasswordErrorTextCreateAcc("");
                                      setRePasswordErrorTextCreateAcc("");
                                    }}
                                  >
                                    {translate("signup_using_OTP_link")}
                                  </Link>
                                </Typography>
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              <p
                                style={{
                                  marginTop: "20px",
                                  fontFamily: FontFamily.Medium,
                                  fontSize: sm ? 12 : 16,
                                }}
                                align="center"
                              >
                                {translate("already_have_an_account")}{" "}
                                <Link
                                  className={classes.link}
                                  style={{
                                    fontFamily: FontFamily.SemiBold,
                                    fontWeight: "900",
                                  }}
                                  onClick={() => {
                                    setCreateAccountModalOpen(false);
                                    setLoginModalOpen(true);
                                    setEmailOrMobileCreateAcc("");
                                    setEmailOrMobileErrorTextCreateAcc("");
                                    setPasswordCreateAcc("");
                                    setPasswordErrorTextCreateAcc("");
                                    setRePasswordCreateAcc("");
                                    setRePasswordErrorTextCreateAcc("");
                                    setIsAccountCreated(false);
                                    setCreateAccOtpGenerated(false);
                                    const phoneCode = countryListPhone?.filter(
                                      (v) => v?.phone_code === "+91"
                                    );
                                    let data;
                                    phoneCode.map((item) => {
                                      data = item;
                                    });
                                    setCountryPhoneCodeCreateAcc(data);
                                  }}
                                >
                                  {translate("login")}
                                </Link>
                              </p>
                            </Grid>
                            <Grid item xs={2}></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          }
        />

        {/* Forgot Password */}
        <CModal
          visible={forgotPasswordModalOpen}
          onClose={() => {
            // if (fromDirectLoginModal === false) {
            setEmailOrMobileFP("");
            loginAllErrorFalse();
            setIsFPOtpGenerated(false);
            setIsUserVerified(false);
            setForgotPasswordModalOpen(false);
            const phoneCode = countryListPhone?.filter(
              (v) => v?.phone_code === "+91"
            );
            let data;
            phoneCode.map((item) => {
              data = item;
            });
            setCountryPhoneCodeFP(data);
            dispatch(setFirstTimeSkip(true));
            setForgotPasswordModalOpen(false);
            // }
          }}
          children={
            <Grid style={{ position: "relative" }}>
              <Grid
                container
                style={{
                  width: md ? "90vw" : "600px",
                  maxHeight: "80vh",
                  overflowY: "scroll",
                }}
                className={classes.scrollBar}
              >
                {/* {fromDirectLoginModal === false && ( */}
                <CloseIcon
                  onClick={() => {
                    loginAllErrorFalse();
                    setEmailOrMobileFP("");
                    dispatch(setFirstTimeSkip(true));
                    setForgotPasswordModalOpen(false);
                    setPassword("");
                    setOtp("");
                    setForgotPasswordModalOpen(false);
                    setIsFPOtpGenerated(false);
                    setIsUserVerified(false);
                    setForgotPasswordModalOpen(false);
                    const phoneCode = countryListPhone?.filter(
                      (v) => v?.phone_code === "+91"
                    );
                    let data;
                    phoneCode.map((item) => {
                      data = item;
                    });
                    setCountryPhoneCodeFP(data);
                  }}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 15,
                    fontSize: 30,
                    cursor: "pointer",
                    color: BaseColor.primary,
                    zIndex: 1,
                  }}
                />
                {/* )} */}

                <div style={{ padding: md ? "20px" : "30px" }}>
                  <Grid container>
                    {isUserVerified ? (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={2}></Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              container
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <Grid item xs={12} textAlign={"center"}>
                                <span
                                  style={{
                                    fontFamily: FontFamily.Bold,
                                    fontSize: md ? "25px" : "35px",
                                  }}
                                >
                                  {translate("create_new_password_header_text")}
                                </span>
                              </Grid>
                              <Grid item xs={12} textAlign={"center"}>
                                <p
                                  style={{
                                    fontFamily: FontFamily.Medium,
                                    fontSize: "17px",
                                  }}
                                >
                                  {translate(
                                    "create_new_password_description_text"
                                  )}
                                </p>
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={12} marginTop={"20px"}>
                                <CInput
                                  placeholder={translate(
                                    "password_placeholder"
                                  )}
                                  type={showPassword ? "text" : "password"}
                                  value={passwordFP}
                                  onChange={(val) => setPasswordFP(val?.trim())}
                                  startIcon={
                                    <CiMobile3 className="CInputStartIcon" />
                                  }
                                  endIcon={
                                    showPassword ? (
                                      <AiOutlineEye
                                        className="CInputStartIcon"
                                        onClick={() => setShowPassword(false)}
                                        style={{
                                          color: BaseColor.primary,
                                          fontSize: "20px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    ) : (
                                      <AiOutlineEyeInvisible
                                        className="CInputStartIcon"
                                        onClick={() => setShowPassword(true)}
                                        style={{
                                          color: BaseColor.primary,
                                          fontSize: "20px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    )
                                  }
                                  errorMsg={newPasswordFPErrorText}
                                />
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={12} marginTop={"20px"}>
                                <CInput
                                  placeholder={translate(
                                    "re_enter_password_placeholder"
                                  )}
                                  type={showRePasswordFp ? "text" : "password"}
                                  value={rePasswordFP}
                                  onChange={(val) =>
                                    setRePasswordFP(val?.trim())
                                  }
                                  startIcon={
                                    <CiMobile3 className="CInputStartIcon" />
                                  }
                                  endIcon={
                                    showRePasswordFp ? (
                                      <AiOutlineEye
                                        className="CInputStartIcon"
                                        onClick={() =>
                                          setShowRePasswordFp(false)
                                        }
                                        style={{
                                          color: BaseColor.primary,
                                          fontSize: "20px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    ) : (
                                      <AiOutlineEyeInvisible
                                        className="CInputStartIcon"
                                        onClick={() =>
                                          setShowRePasswordFp(true)
                                        }
                                        style={{
                                          color: BaseColor.primary,
                                          fontSize: "20px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    )
                                  }
                                  errorMsg={rePasswordFPErrorText}
                                />
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={12}>
                                <CButton
                                  variant="contained"
                                  style={{ marginTop: "30px", width: "100%" }}
                                  loading={newPasswordBtnLoader}
                                  onClick={() => {
                                    createPasswordValidation();
                                  }}
                                >
                                  {translate("save_button")}
                                </CButton>
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={2}></Grid>
                            </Grid>
                          </Grid>
                          <Grid xs={2}></Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        justifyContent="center"
                        sx={{ backgroundColor: BaseColor.whiteColor }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Grid item xs={12} textAlign={"center"}>
                              <span
                                style={{
                                  fontFamily: FontFamily.Bold,
                                  fontSize: md ? "25px" : "35px",
                                }}
                              >
                                {translate("forgot_password_header_text")}
                              </span>
                            </Grid>
                            <Grid item xs={12} textAlign={"center"}>
                              <p
                                style={{
                                  fontFamily: FontFamily.Medium,
                                  fontSize: sm ? "14px" : "17px",
                                }}
                              >
                                {translate("forgot_password_description_text")}
                              </p>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={12} marginTop={sm ? "10px" : "20px"}>
                              <CInput
                                placeholder="Email or mobile number"
                                value={emailOrMobileFP}
                                onChange={(val) => {
                                  setEmailOrMobileFP(val?.trim());
                                }}
                                disabled={emailOrMobileDisableFP}
                                maxLength={
                                  emailOrMobileFP.length >= 9
                                    ? /^[0-9]*$/.test(String(emailOrMobileFP))
                                      ? 12
                                      : 40
                                    : 40
                                }
                                style={{ marginBottom: 20, width: "100%" }}
                                endIcon={
                                  emailOrMobileDisableFP ? (
                                    <CIcon
                                      src={Files.svgIcons.editFill}
                                      className="CInputStartIcon"
                                      onClick={() => {
                                        setEmailOrMobileDisableFP(false);
                                        setIsFPOtpGenerated(false);
                                        setGenerateOTPFPBtnLoader(false);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  ) : null
                                }
                                startIcon={
                                  <>
                                    <CiMobile3
                                      style={{
                                        color: BaseColor.primary,
                                        fontSize: "20px",
                                      }}
                                    />
                                    {/^-?\d*\.?\d*$/.test(
                                      String(emailOrMobileFP)
                                    ) && emailOrMobileFP.trim().length >= 5 ? (
                                      <>
                                        <CAutoComplete
                                          options={countryListPhone}
                                          phoneCode
                                          selectedValue={countryPhoneCodeFP}
                                          onSelect={(val) => {
                                            setCountryPhoneCodeFP(val);
                                          }}
                                          style={{
                                            outline: "none",
                                            width: "100px",
                                          }}
                                        />
                                        <div
                                          style={{
                                            height: 25,
                                            borderRight: `1px solid ${BaseColor.primary}`,
                                          }}
                                        />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                }
                                errorMsg={emailOrMobileFPErrorText}
                              />
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>

                            <Grid item xs={12}>
                              <CButton
                                variant={"contained"}
                                style={{ marginTop: "10px", width: "100%" }}
                                onClick={() => {
                                  // generateOTPValidation("forgotPassword");
                                  if (
                                    /^-?\d*\.?\d*$/.test(
                                      String(emailOrMobileFP)
                                    )
                                  ) {
                                    if (
                                      countryPhoneCodeFP?.phone_code === "+91"
                                    ) {
                                      generateOTPValidation("forgotPassword");
                                    } else {
                                      toast(
                                        "Your country is not supported for OTP, login with email address",
                                        {
                                          type: "error",
                                        }
                                      );
                                    }
                                  } else {
                                    generateOTPValidation("forgotPassword");
                                  }
                                }}
                                disabled={
                                  isFPOtpGenerated ||
                                  generateOTPFPBtnLoader ||
                                  isEmpty(emailOrMobileFP)
                                }
                                loading={generateOTPFPBtnLoader}
                              >
                                {translate("get_otp_btn")}
                              </CButton>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            {isFPOtpGenerated && (
                              <Grid item xs={12} marginTop={"20px"}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: FontFamily.SemiBold,
                                      fontWeight: "900",
                                    }}
                                  >
                                    {translate("entry_otp_here")}
                                  </span>
                                  <CResendBtn
                                    ref={FpTimerRef}
                                    isOTPGenerated={isFPOtpGenerated}
                                    onClick={() => {
                                      generateOTPValidation();
                                    }}
                                  />
                                </div>
                                <COtpInput
                                  value={otpFP}
                                  onChange={(val) => {
                                    setOtpFP(val);
                                    if (val.length >= 6) {
                                      verifyOTPValidation(
                                        "forgotPassword",
                                        val
                                      );
                                    }
                                  }}
                                  errorMsg={otpInputFGErrorText}
                                />
                              </Grid>
                            )}

                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            {isFPOtpGenerated && (
                              <Grid item xs={12}>
                                <CButton
                                  variant="contained"
                                  disabled={isEmpty(otpFP)}
                                  style={{ marginTop: "10px", width: "100%" }}
                                  loading={fPverifyOtpBtnLoader}
                                  onClick={() => {
                                    verifyOTPValidation(
                                      "forgotPassword",
                                      otpFP
                                    );
                                  }}
                                >
                                  {translate("verify_btn")}
                                </CButton>
                              </Grid>
                            )}

                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography
                        style={{
                          marginTop: "20px",
                          fontFamily: FontFamily.Medium,
                        }}
                        align="center"
                      >
                        {translate("already_have_an_account")}{" "}
                        <Link
                          className={classes.link}
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontWeight: "900",
                          }}
                          onClick={() => {
                            setForgotPasswordModalOpen(false);
                            setLoginModalOpen(true);
                            setEmailOrMobileDisableFP(false);
                            setEmailOrMobileFP("");
                            setIsFPOtpGenerated(false);
                            const phoneCode = countryListPhone?.filter(
                              (v) => v?.phone_code === "+91"
                            );
                            let data;
                            phoneCode.map((item) => {
                              data = item;
                            });
                            setCountryPhoneCodeFP(data);
                          }}
                        >
                          {translate("login")}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          }
        />
        <CModal
          visible={directLoginModal}
          onClose={() => {
            const phoneCode = countryListPhone?.filter(
              (v) => v?.phone_code === "+91"
            );
            let data;
            phoneCode.map((item) => {
              data = item;
            });
            setCountryPhoneCodeDirectLogin(data);
            dispatch(setFirstTimeSkip(true));
            setDirectLoginModal(false);
            setFromDirectLoginModal(true);
            setIsOtpGenerated(false);
            setMobileNoErrorText("");
            setOtpDirectLogin("");
            setOtpErrorTextDirectLogin("");
            setShowEmail(false);
            setMobileNumber("");
            setEmailDisable(false);
            setMobileNoDisable(false);
          }}
          modalStyle={{ backdropFilter: "blur(5px)" }}
          children={
            <Grid style={{ position: "relative" }}>
              <Grid
                container
                style={{
                  width: md ? "90vw" : "600px",
                  maxHeight: "80vh",
                  overflowY: "scroll",
                }}
                className={classes.scrollBar}
              >
                <div style={{ padding: md ? "20px" : "30px" }}>
                  <CloseIcon
                    onClick={() => {
                      const phoneCode = countryListPhone?.filter(
                        (v) => v?.phone_code === "+91"
                      );
                      let data;
                      phoneCode.map((item) => {
                        data = item;
                      });
                      setCountryPhoneCodeDirectLogin(data);
                      dispatch(setFirstTimeSkip(true));
                      setDirectLoginModal(false);
                      setFromDirectLoginModal(true);
                      setIsOtpGenerated(false);
                      setMobileNoErrorText("");
                      setOtpDirectLogin("");
                      setOtpErrorTextDirectLogin("");
                      setShowEmail(false);
                      setMobileNumber("");
                      setEmailDisable(false);
                      setMobileNoDisable(false);
                    }}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 15,
                      fontSize: 30,
                      cursor: "pointer",
                      color: BaseColor.primary,
                      zIndex: 1,
                    }}
                  />
                  <Grid container mt={"15px"}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={2}></Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Grid item xs={12} textAlign={"center"}>
                              <span
                                style={{
                                  fontFamily: FontFamily.Bold,
                                  fontSize: md ? "25px" : "35px",
                                }}
                              >
                                {translate("direct_login_modal_title")}
                              </span>
                            </Grid>
                            <Grid item xs={12} textAlign={"center"}>
                              <p
                                style={{
                                  fontFamily: FontFamily.Medium,
                                  fontSize: "17px",
                                }}
                              >
                                {translate("direct_login_modal_sub_title")}
                              </p>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={12} marginTop={"20px"}>
                              <CInput
                                disabled={mobileNoDisable}
                                placeholder={
                                  showEmail
                                    ? translate("user_email")
                                    : translate("user_mobile_no")
                                }
                                value={mobileNumber}
                                onChange={(val) => {
                                  setMobileNumber(val?.trim());
                                  directLoginAllErrorFalse();
                                }}
                                startIcon={
                                  <>
                                    <CAutoComplete
                                      disabled={mobileNoDisable}
                                      options={countryListPhone}
                                      phoneCode
                                      selectedValue={
                                        countryPhoneCodeDirectLogin
                                      }
                                      onSelect={(val) => {
                                        if (val?.phone_code === "+91") {
                                          setShowEmail(false);
                                          setEmailDirectLogin("");
                                          setEmailErrorTextDirectLogin("");
                                          setEmailDisable(false);
                                        }
                                        if (val?.phone_code !== "+91") {
                                          setShowEmail(true);
                                          setMobileNumber("");
                                          setMobileNoErrorText("");
                                        }
                                        setCountryPhoneCodeDirectLogin(val);
                                      }}
                                      style={{
                                        outline: "none",
                                        width: "100px",
                                      }}
                                    />
                                    <div
                                      style={{
                                        height: 25,
                                        borderRight: `1px solid ${BaseColor.primary}`,
                                      }}
                                    />
                                  </>
                                }
                                endIcon={
                                  mobileNoDisable ? (
                                    <CIcon
                                      src={Files.svgIcons.editFill}
                                      className="CInputStartIcon"
                                      onClick={() => {
                                        setMobileNoDisable(false);
                                        setIsOtpGenerated(false);
                                        setGenerateOTPBtnLoader(false);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  ) : null
                                }
                                errorMsg={mobileNoErrorText}
                              />
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            {/* {showEmail && (
                                <Grid item xs={12}>
                                  <p
                                    style={{
                                      fontFamily: FontFamily.regular,
                                      fontSize: "15px",
                                      color: BaseColor?.red,
                                    }}
                                  >
                                    {translate("otp_not_supported_message")}
                                  </p>
                                </Grid>
                              )} */}
                            {/* {showEmail && (
                                <Grid item xs={12}>
                                  <CInput
                                    disabled={emailDisable}
                                    placeholder={translate("user_email")}
                                    value={emailDirectLogin}
                                    onChange={(val) => {
                                      setEmailDirectLogin(val);
                                      directLoginAllErrorFalse();
                                    }}
                                    errorMsg={emailErrorTextDirectLogin}
                                    endIcon={
                                      emailDisable ? (
                                        <CIcon
                                          src={Files.svgIcons.editFill}
                                          className="CInputStartIcon"
                                          onClick={() => {
                                            setEmailDisable(false);
                                            setIsOtpGenerated(false);
                                            setGenerateOTPBtnLoader(false);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : null
                                    }
                                  />
                                </Grid>
                              )} */}
                            {isOtpGenerated && (
                              <Grid item xs={12} mt={"30px"}>
                                <div
                                  style={{
                                    marginBottom: "20px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <CResendBtn
                                    ref={timerRef}
                                    isOTPGenerated={isOtpGenerated}
                                    onClick={() => {
                                      checkValidData();
                                      setOtpDirectLogin("");
                                      setOtpErrorTextDirectLogin("");
                                    }}
                                  />
                                </div>
                                <COtpInput
                                  disabled={disableOtp}
                                  value={otpDirectLogin}
                                  onChange={(val) => {
                                    setOtpDirectLogin(val);
                                    setOtpErrorTextDirectLogin("");
                                    if (val.length >= 6) {
                                      verifyOTPValidationDirectLogin(val);
                                    }
                                  }}
                                  errorMsg={otpErrorTextDirectLogin}
                                />
                              </Grid>
                            )}
                            {isOtpGenerated ? (
                              <Grid item xs={12}>
                                <CButton
                                  loading={verifyOTPBtnLoader}
                                  variant="contained"
                                  style={{ width: "100%", marginTop: "20px" }}
                                  onClick={() => {
                                    verifyOTPValidationDirectLogin(
                                      otpDirectLogin
                                    );
                                  }}
                                >
                                  {translate("verify_otp_btn")}
                                </CButton>
                              </Grid>
                            ) : (
                              <>
                                <Grid item xs={12}>
                                  <CButton
                                    variant="contained"
                                    style={{ width: "100%", marginTop: "20px" }}
                                    loading={generateOTPBtnLoader}
                                    onClick={() => {
                                      checkValidData();
                                    }}
                                  >
                                    {translate("continue_btn")}
                                  </CButton>
                                </Grid>
                                {showEmail && (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      mt={"20px"}
                                      display={"flex"}
                                      justifyContent={"center"}
                                      alignItems={"center"}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          height: "1px",
                                          backgroundColor: BaseColor.primary,
                                        }}
                                      ></div>
                                      <div>{translate("or")}</div>
                                      <div
                                        style={{
                                          width: "50%",
                                          height: "1px",
                                          backgroundColor: BaseColor.primary,
                                        }}
                                      ></div>
                                    </Grid>
                                    <Grid item xs={12} mt={"20px"}>
                                      <CGoogle
                                        isModal
                                        onLoginSuccess={(bool) => {
                                          if (bool) {
                                            setDirectLoginModal(false);
                                          }
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              </>
                            )}

                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={2}></Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography
                        style={{
                          marginTop: "20px",
                          fontFamily: FontFamily.Medium,
                        }}
                        align="center"
                      >
                        {translate("already_have_an_account")}{" "}
                        <Link
                          className={classes.link}
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontWeight: "900",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setFromDirectLoginModal(true);
                            setDirectLoginModal(false);
                            setLoginModalOpen(true);
                            setEmailDirectLogin("");
                            setIsOtpGenerated(false);
                            setMobileNoErrorText("");
                            setEmailErrorTextDirectLogin("");
                            setOtpDirectLogin("");
                            setOtpErrorTextDirectLogin("");
                            setShowEmail(false);
                            setMobileNumber("");
                            setEmailDisable(false);
                            setMobileNoDisable(false);
                          }}
                        >
                          {translate("login")}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          }
        />
        <CModal
          visible={openSignupUsingOtpModal}
          modalStyle={{ backdropFilter: "blur(5px)" }}
          onClose={() => {
            // if (fromDirectLoginModal === false) {
            setOpenSignupUsingOtpModal(false);
            // setLoginModalOpen(true);
            setSignupEmailOrMobile("");
            setSignupEmail("");
            const phoneCode = countryListPhone?.filter(
              (v) => v?.phone_code === "+91"
            );
            let data;
            phoneCode.map((item) => {
              data = item;
            });
            setSignupCountryCode(phoneCode);
            setSignupEmailOrMobileErrorText("");
            setSignupEmailErrorText("");
            setSignupShowEmail(false);
            setSignupEmailOrMobileNoDisable(false);
            setSignUpEmailDisabled(false);
            setSignupOtp("");
            setIsSignupOtpGenerated(false);
            setSignupGenerateOTPBtnLoader(false);
            setSignupOtpErrorText("");
            setSignupVerifyOTPBtnLoader(false);
            setSignupDisableOtp(false);
            setSignupGenerateOTPBtnLoader(false);
            dispatch(setFirstTimeSkip(true));
            setOpenSignupUsingOtpModal(false);
            // }
          }}
          children={
            <Grid style={{ position: "relative" }}>
              <Grid
                container
                style={{
                  width: md ? "90vw" : "600px",
                  maxHeight: "80vh",
                  overflowY: "scroll",
                }}
                className={classes.scrollBar}
              >
                {/* {fromDirectLoginModal === false && ( */}
                <CloseIcon
                  onClick={() => {
                    setOpenSignupUsingOtpModal(false);
                    // setLoginModalOpen(true);
                    setSignupEmailOrMobile("");
                    setSignupEmail("");
                    const phoneCode = countryListPhone?.filter(
                      (v) => v?.phone_code === "+91"
                    );
                    let data;
                    phoneCode.map((item) => {
                      data = item;
                    });
                    setSignupCountryCode(phoneCode);
                    setSignupEmailOrMobileErrorText("");
                    setSignupEmailErrorText("");
                    setSignupShowEmail(false);
                    setSignupEmailOrMobileNoDisable(false);
                    setSignUpEmailDisabled(false);
                    setSignupOtp("");
                    setIsSignupOtpGenerated(false);
                    setSignupGenerateOTPBtnLoader(false);
                    setSignupOtpErrorText("");
                    setSignupVerifyOTPBtnLoader(false);
                    setSignupDisableOtp(false);
                    setSignupGenerateOTPBtnLoader(false);
                    dispatch(setFirstTimeSkip(true));
                    setOpenSignupUsingOtpModal(false);
                  }}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 15,
                    fontSize: 30,
                    cursor: "pointer",
                    color: BaseColor.primary,
                    zIndex: 1,
                  }}
                />
                {/* )  } */}
                <div style={{ padding: md ? "20px" : "30px" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={2}></Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Grid item xs={12} textAlign={"center"}>
                              <span
                                style={{
                                  fontFamily: FontFamily.Bold,
                                  fontSize: md ? "25px" : "35px",
                                }}
                              >
                                {translate("create_account_header_text")}
                              </span>
                            </Grid>
                            <Grid item xs={12} textAlign={"center"}>
                              <p
                                style={{
                                  fontFamily: FontFamily.Medium,
                                  fontSize: "17px",
                                }}
                              >
                                {translate("create_account_description_text")}
                              </p>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={12} marginTop={"20px"}>
                              <CInput
                                disabled={signupEmailOrMobileNoDisable}
                                placeholder={translate(
                                  "email_or_mobile_placeholder"
                                )}
                                value={signupEmailOrMobile}
                                onChange={(val) => {
                                  setSignupEmailOrMobile(val?.trim());
                                  signupUsingOtpAllErrorFalse();
                                }}
                                maxLength={
                                  signupEmailOrMobile.length >= 9
                                    ? /^[0-9]*$/.test(
                                        String(signupEmailOrMobile)
                                      )
                                      ? 12
                                      : 40
                                    : 40
                                }
                                startIcon={
                                  <>
                                    <CIcon
                                      src={Files.svgIcons.phoneEmail}
                                      className="CInputStartIcon"
                                    />
                                    {/^-?\d*\.?\d*$/.test(
                                      String(signupEmailOrMobile)
                                    ) &&
                                    signupEmailOrMobile.trim().length >= 5 ? (
                                      <>
                                        <CAutoComplete
                                          disabled={
                                            signupEmailOrMobileNoDisable
                                          }
                                          options={countryListPhone}
                                          phoneCode
                                          selectedValue={signupCountryCode}
                                          onSelect={(val) => {
                                            if (val?.phone_code == "+91") {
                                              setSignupShowEmail(false);
                                              setSignupEmail("");
                                              setSignupEmailErrorText("");
                                              setSignUpEmailDisabled(false);
                                            }
                                            setSignupCountryCode(val);
                                            setSignUpEmailDisabled(false);
                                          }}
                                          style={{
                                            outline: "none",
                                            width: "100px",
                                          }}
                                        />
                                        <div
                                          style={{
                                            height: 25,
                                            borderRight: `1px solid ${BaseColor.primary}`,
                                          }}
                                        />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                }
                                endIcon={
                                  signupEmailOrMobileNoDisable ? (
                                    <CIcon
                                      src={Files.svgIcons.editFill}
                                      className="CInputStartIcon"
                                      onClick={() => {
                                        setSignupEmailOrMobileNoDisable(false);
                                        setIsSignupOtpGenerated(false);
                                        setSignupGenerateOTPBtnLoader(false);
                                        setSignupOtp("");
                                        setSignupOtpErrorText("");
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  ) : null
                                }
                                errorMsg={signupEmailOrMobileErrorText}
                              />
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            {signupShowEmail && (
                              <Grid item xs={12}>
                                <p
                                  style={{
                                    fontFamily: FontFamily.regular,
                                    fontSize: "15px",
                                    color: BaseColor?.red,
                                  }}
                                >
                                  {translate("otp_not_supported_message")}
                                </p>
                              </Grid>
                            )}
                            {signupShowEmail && (
                              <Grid item xs={12}>
                                <CInput
                                  disabled={signUpEmailDisabled}
                                  placeholder={translate("user_email")}
                                  value={signupEmail}
                                  onChange={(val) => {
                                    setSignupEmail(val?.trim());
                                    signupUsingOtpAllErrorFalse();
                                  }}
                                  errorMsg={signupEmailErrorText}
                                  endIcon={
                                    signUpEmailDisabled ? (
                                      <CIcon
                                        src={Files.svgIcons.editFill}
                                        className="CInputStartIcon"
                                        onClick={() => {
                                          setSignUpEmailDisabled(false);
                                          setIsSignupOtpGenerated(false);
                                          setSignupGenerateOTPBtnLoader(false);
                                          setSignupOtp("");
                                          setSignupOtpErrorText("");
                                        }}
                                        style={{ cursor: "pointer" }}
                                      />
                                    ) : null
                                  }
                                />
                              </Grid>
                            )}
                            {isSignupOtpGenerated && (
                              <Grid item xs={12} mt={"30px"}>
                                <div
                                  style={{
                                    marginBottom: "20px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <CResendBtn
                                    ref={signupTimerRef}
                                    isOTPGenerated={isSignupOtpGenerated}
                                    onClick={() => {
                                      checkValidDataSignup();
                                      setSignupOtp("");
                                      setSignupOtpErrorText("");
                                    }}
                                  />
                                </div>
                                <COtpInput
                                  disabled={signupDisableOtp}
                                  value={signupOtp}
                                  onChange={(val) => {
                                    setSignupOtp(val);
                                    setSignupOtpErrorText("");
                                    if (val.length >= 6) {
                                      verifyOTPValidationSignup(val);
                                    }
                                  }}
                                  errorMsg={signupOtpErrorText}
                                />
                              </Grid>
                            )}
                            {isSignupOtpGenerated ? (
                              <Grid item xs={12}>
                                <CButton
                                  loading={signupVerifyOTPBtnLoader}
                                  variant="contained"
                                  style={{ width: "100%", marginTop: "20px" }}
                                  onClick={() => {
                                    verifyOTPValidationSignup(signupOtp);
                                  }}
                                >
                                  {translate("verify_otp_btn")}
                                </CButton>
                              </Grid>
                            ) : (
                              <>
                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      width: "100%",
                                      fontFamily: FontFamily.Medium,
                                      marginTop: "10px",
                                    }}
                                  >
                                    <CCheckBox
                                      register
                                      checked={AcceptTermsSignup}
                                      onChange={(val) => {
                                        setAcceptTermsSignup(val);
                                        setAcceptTermsErrorTextSignup("");
                                      }}
                                      errorMsg={AcceptTermsErrorTextSignup}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12}>
                                  <CButton
                                    variant="contained"
                                    style={{ width: "100%", marginTop: "20px" }}
                                    loading={signupGenerateOTPBtnLoader}
                                    onClick={() => {
                                      checkValidDataSignup();
                                    }}
                                  >
                                    {translate("continue_btn")}
                                  </CButton>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: BaseColor.primary,
                                        width: "50%",
                                        height: "1px",
                                      }}
                                    ></div>
                                    <div style={{ padding: "0px 5px" }}>
                                      {translate("or")}
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: BaseColor.primary,
                                        width: "50%",
                                        height: "1px",
                                      }}
                                    ></div>
                                  </div>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={12}>
                                  <CGoogle
                                    isModal
                                    onSuccess={(bool) => {
                                      if (bool) {
                                        setOpenSignupUsingOtpModal(false);
                                      }
                                    }}
                                  />
                                </Grid>
                              </>
                            )}

                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={2}></Grid>
                      </Grid>
                    </Grid>

                    {!isSignupOtpGenerated && (
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography
                          style={{
                            marginTop: "20px",
                            fontFamily: FontFamily.Medium,
                          }}
                          align="center"
                        >
                          <Link
                            className={classes.link}
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontWeight: "900",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setCreateAccountModalOpen(true);
                              setOpenSignupUsingOtpModal(false);
                              setEmailOrMobileCreateAcc(signupEmailOrMobile);
                              setSignupEmailOrMobile("");
                              setSignupEmail("");
                              setCountryPhoneCodeCreateAcc(signupCountryCode);
                              setSignupEmailOrMobileErrorText("");
                              setSignupEmailErrorText("");
                              setSignupShowEmail(false);
                              setSignupEmailOrMobileNoDisable(false);
                              setSignUpEmailDisabled(false);
                              setSignupOtp("");
                              setIsSignupOtpGenerated(false);
                              setSignupGenerateOTPBtnLoader(false);
                              setSignupOtpErrorText("");
                              setSignupVerifyOTPBtnLoader(false);
                              setSignupDisableOtp(false);
                              setSignupGenerateOTPBtnLoader(false);
                            }}
                          >
                            {translate("signup_using_password_link")}
                          </Link>
                        </Typography>
                      </Grid>
                    )}

                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography
                        style={{
                          marginTop: "20px",
                          fontFamily: FontFamily.Medium,
                        }}
                        align="center"
                      >
                        {translate("already_have_an_account")}{" "}
                        <Link
                          className={classes.link}
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontWeight: "900",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpenSignupUsingOtpModal(false);
                            setLoginModalOpen(true);
                            setSignupEmailOrMobile("");
                            setSignupEmail("");
                            const phoneCode = countryListPhone?.filter(
                              (v) => v?.phone_code === "+91"
                            );
                            let data;
                            phoneCode.map((item) => {
                              data = item;
                            });
                            setSignupCountryCode(phoneCode);
                            setSignupEmailOrMobileErrorText("");
                            setSignupEmailErrorText("");
                            setSignupShowEmail(false);
                            setSignupEmailOrMobileNoDisable(false);
                            setSignUpEmailDisabled(false);
                            setSignupOtp("");
                            setIsSignupOtpGenerated(false);
                            setSignupGenerateOTPBtnLoader(false);
                            setSignupOtpErrorText("");
                            setSignupVerifyOTPBtnLoader(false);
                            setSignupDisableOtp(false);
                            setSignupGenerateOTPBtnLoader(false);
                          }}
                        >
                          {translate("login")}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
});
export default CFooter;
