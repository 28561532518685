import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/Color";
import { FontFamily } from "../../Config/theme";

const styles = makeStyles({
  root: {
    background: BaseColor.whiteColor,
  },
  mainContainer: {
    marginBottom: "3%",
    minHeight: "80vh",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 30,
    flexWrap: "wrap",
  },
  headerTitleText: {
    fontSize: 24,
    color: BaseColor.primary,
    fontFamily: FontFamily.SemiBold,
  },
  lastUpdatedAtContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  lastUpdatedAtText: {
    fontSize: 16,
    color: BaseColor.blackColor,
    fontFamily: FontFamily.Medium,
  },
  lastUpdatedTimeText: {
    fontSize: 16,
    color: BaseColor.primary,
    fontFamily: FontFamily.RobotoMedium,
  },
});
export default styles;
