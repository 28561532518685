import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/Color";
import theme, { FontFamily } from "../../Config/theme";

const styles = makeStyles({
  container: {
    background: BaseColor.primary,
    // justifyContent: "center",
  },
  siteMapContainer: {
    background: BaseColor.disableOffWhite,
  },
  siteMapTitleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleSide: {
    backgroundColor: BaseColor.blackColor,
    height: "2px",
    width: "100px",
  },

  topGridContainer: {
    minHeight: "220px",
    paddingTop: 30,
  },
  logo: {
    objectFit: "contain",
  },
  companyAndLegalMainContainer: {
    padding: "0px 15px 0px 15px",
    background: BaseColor.primary,
  },
  companyAndLegalContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  companyAndLegalText: {
    fontSize: 16,
    lineHeight: 2,
    fontFamily: FontFamily.Regular,
    textAlign: "left",
    color: BaseColor.whiteColor,
    cursor: "pointer",
  },
  companyAndLegalHeaderTextContainer: {
    marginBottom: 15,
  },
  companyAndLegalHeaderText: {
    color: BaseColor.whiteColor,
    fontSize: 18,
    cursor: "default",
    letterSpacing: 1,
    fontFamily: FontFamily.SemiBold,
    fontWeight: "900",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },

  newsLetterMainContainer: {
    background: BaseColor.primary,
  },
  newsLetterTextContainer: {
    display: "flex",
    flexDirection: "column",
  },
  newsletterText: {
    fontSize: 18,
    fontFamily: FontFamily.SemiBold,
    fontWeight: "900",
    letterSpacing: 1,
    cursor: "default",
    color: BaseColor.whiteColor,
    textAlign: "left",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  newsletterDescriptionText: {
    fontSize: 13,
    cursor: "default",
    fontFamily: FontFamily.Regular,
    color: BaseColor.whiteColor,
    textAlign: "left",
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  emailInputContainer: {
    display: "flex",
    flexDirection: "row",
    background: BaseColor.whiteColor,
    padding: 2,
    marginTop: 10,
  },

  subscribeBtn: {},
  subscribeBtnText: {
    fontSize: 16,
    fontFamily: FontFamily.Bold,
    color: BaseColor.whiteColor,
    "@media (max-width:980px)": {
      fontSize: 12,
    },
  },

  bottomGridContainer: {
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  // Bottom First Grid Container
  copyRightText: {
    fontSize: 16,
    cursor: "default",
    color: BaseColor.whiteColor,
    fontFamily: FontFamily.RobotoRegular,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },

  // Bottom Second Grind Container
  socialMainIconContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
  socialIconContainer: {
    border: `1px solid ${BaseColor.whiteColor}`,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 36,
    width: 36,
    cursor: "pointer",
  },
  socialIcon: {
    fontSize: 22,
    color: BaseColor.whiteColor,
  },
  scrollBar: {
    display: "flex",
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 5,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.disablePrimary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
  link: {
    fontFamily: FontFamily.Medium,
    // fontSize: "10px",
    color: BaseColor.primary,
    cursor: "pointer",
  },
  errorMsgContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    marginLeft: 2,
  },
  errorMsgText: {
    marginLeft: 2,
    fontSize: 14,
    color: BaseColor.whiteColor,
    fontFamily: FontFamily.Medium,
  },
  errorMsgStarText: {
    fontSize: 28,
    lineHeight: 1,
    color: BaseColor.whiteColor,
    fontFamily: FontFamily.Medium,
  },
});

export default styles;
