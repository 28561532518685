import { Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import BaseColor from "../../Config/Color";
import theme, { FontFamily } from "../../Config/theme";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import styles from "./styles";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { translate } from "../../lang/Translate";

export default function NotificationModal(props) {
  const {
    handlePress = () => {},
    openModal,
    data = [],
    pagination = {},
    onMarkReadAll = () => {},
    isDisabledMarkAsReadBtn = false,
    isDisabledClearBtn = false,
    onClearAll = () => {},
    onClick = () => {},
    onReachBottom = () => {},
    disabledSingleNoti,
    notificationData,
  } = props;
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const classes = styles();
  return (
    <div
      style={{
        backgroundColor: BaseColor.offWhite,
        width: sm ? "90vw" : md ? "80vw" : 500,
      }}
    >
      <Grid
        container
        style={{
          padding: 10,
          overflowY: "hidden",
        }}
      >
        <Grid
          item
          container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="tableTitle" style={{ color: BaseColor.primary }}>
            {translate("drawer_menu_notification")}
          </Typography>
          <IconButton onClick={() => handlePress("close")}>
            <CloseIcon style={{ color: BaseColor.primary }} />
          </IconButton>
        </Grid>
        <Grid
          container
          style={{
            height: 1,
            backgroundColor: BaseColor.textGray,
            marginBottom: 10,
            opacity: 0.6,
          }}
        />
        <Grid
          item
          container
          gap={1}
          display={"flex"}
          justifyContent="space-between"
          // wrap={"nowrap"}
          style={{ marginBottom: 5 }}
        >
          <Typography
            style={{
              color: BaseColor.primary,
              fontFamily: FontFamily.Medium,
            }}
          >
            {translate("unread")} (
            {notificationData?.isUnreadCount < 1
              ? 0
              : notificationData?.isUnreadCount}
            )
          </Typography>

          <Grid item style={{ display: "flex" }} gap={1.5}>
            <div
              style={{
                cursor: !isDisabledMarkAsReadBtn && "pointer",
                pointerEvents: isDisabledMarkAsReadBtn && "none",
                opacity: isDisabledMarkAsReadBtn ? 0.5 : null,
              }}
              onClick={onMarkReadAll}
            >
              <Typography
                style={{
                  fontSize: 14,
                  color: BaseColor.primary,
                  textDecoration: "underline",
                  fontFamily: FontFamily.Regular,
                  cursor: "pointer",
                }}
              >
                {translate("mark_all_as_read_button")}
              </Typography>
            </div>
            <div
              style={{
                cursor: !isDisabledClearBtn && "pointer",
                pointerEvents: isDisabledClearBtn && "none",
                opacity: isDisabledClearBtn ? 0.5 : null,
              }}
              onClick={onClearAll}
            >
              <Typography
                style={{
                  fontSize: 14,
                  color: BaseColor.red,
                  textDecoration: "underline",
                  fontFamily: FontFamily.Regular,
                  cursor: "pointer",
                }}
              >
                {translate("clear_all_button")}
              </Typography>
            </div>
            <div
              style={{
                cursor: !isDisabledClearBtn && "pointer",
                pointerEvents: isDisabledClearBtn && "none",
                opacity: isDisabledClearBtn ? 0.5 : null,
              }}
              onClick={() => navigate("/Notification")}
            >
              <Typography
                style={{
                  fontSize: 14,
                  color: BaseColor.primary,
                  textDecoration: "underline",
                  fontFamily: FontFamily.Regular,
                  cursor: "pointer",
                }}
              >
                {translate("view_all_button")}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          flexDirection={"row"}
          style={{ maxHeight: 400, overflowY: "scroll" }}
          className={classes.scrollBar}
          onScroll={(e) => {
            const container = e?.target;
            const isReachBottom =
              container?.scrollHeight - container?.scrollTop;
            if (
              !_.isEmpty(data) &&
              pagination?.isMore &&
              isReachBottom === container.clientHeight
            ) {
              onReachBottom();
            }
          }}
        >
          {!_.isEmpty(data) && _.isArray(data) && data.length > 0 ? (
            data?.map((item, index) => {
              const isUnread = item.is_read == "No";
              const img =
                !_.isEmpty(item.photo) &&
                !_.isUndefined(item?.photo) &&
                !_.isNull(item.phot)
                  ? item.photo
                  : "";

              return (
                <div
                  id={`notification_${index + 1}`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    width: "100%",
                  }}
                  onClick={() => {
                    !disabledSingleNoti && onClick(item);
                  }}
                >
                  <Grid
                    container
                    className={classes.listContainer}
                    style={{
                      marginBottom: 5,
                      backgroundColor:
                        item.is_read == "No" ? BaseColor.white : null,
                    }}
                  >
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                      <div className={classes.imageContainer}>
                        {!_.isEmpty(img) ? (
                          <img src={img} className={classes.profileImg} />
                        ) : (
                          <FaUserCircle size={34} color={BaseColor.primary} />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={0.2} sm={0.2} md={0.5} lg={0.5} xl={0.5} />
                    <Grid item xs={10} sm={10.7} md={10.5} lg={10.5} xl={10.5}>
                      <Grid
                        className={classes.container}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          wordBreak: "break-word",
                          width: sm || (isMobile && "100%"),
                        }}
                      >
                        <span
                          className={classes.titleContainer}
                          style={{
                            color: BaseColor.primary,
                            fontFamily: FontFamily.SemiBold,
                            fontWeight: "900",
                            fontSize: sm ? 14 : 16,
                          }}
                        >
                          {item?.title}
                        </span>
                        {isUnread && <div className={classes.indicator} />}
                      </Grid>
                      <Grid
                        container
                        wrap="nowrap"
                        className={classes.container}
                      >
                        <Grid
                          item
                          xs={5}
                          sm={5}
                          md={5}
                          lg={6}
                          xl={6}
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            overflowX: "hidden",
                            wordBreak: "break-word",
                          }}
                        >
                          <span
                            className={classes.subTitleContainer}
                            style={{ fontSize: sm || isMobile ? 10 : 14 }}
                          >
                            {item?.message}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          sm={5}
                          md={5}
                          lg={6}
                          xl={6}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span
                            className={classes.timeText}
                            style={{ fontSize: sm || isMobile ? 10 : 12 }}
                          >
                            {moment(item.updatedAt).format(
                              "DD-MM-YYYY | HH:mm"
                            )}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: 100,
              }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  color: BaseColor.primary,
                  fontFamily: FontFamily.Medium,
                }}
              >
                {translate("no_notification_found")}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
