import OTPInput from "react-otp-input";
import BaseColor from "../../Config/Color";
import _ from "lodash";
import styles from "./styles";
import { isMobile } from "react-device-detect";

const COtpInput = (props) => {
  const { value = "", onChange = () => {}, disabled, errorMsg = "" } = props;
  const classes = styles();
  return (
    <>
      <OTPInput
        value={value}
        onChange={onChange}
        numInputs={6}
        inputType="tel"
        renderInput={(props) => <input readOnly={disabled} {...props} />}
        containerStyle={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
        inputStyle={{
          width: isMobile ? 30 : 40,
          height: isMobile ? 30 : 40,
          border: disabled
            ? `1px solid ${BaseColor.disablePrimary}`
            : `1px solid ${BaseColor.primary}`,
          borderRadius: 5,
        }}
      />

      {!_.isEmpty(errorMsg) && (
        <div className={classes.errorMsgContainer}>
          <span className={classes.errorMsgText}>{errorMsg}</span>
        </div>
      )}
    </>
  );
};

export default COtpInput;
