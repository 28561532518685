/* eslint-disable react-hooks/rules-of-hooks */
import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/Color";
import theme, { FontFamily } from "../../Config/theme";
import { useMediaQuery } from "@mui/material";

// const sm = useMediaQuery(theme.breakpoints.down("sm"));

const styles = makeStyles({
  placeHolder: {
    height: 35,
    width: 35,
    borderRadius: 5,
    backgroundColor: BaseColor.disablePrimary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  placeHolderText: {
    fontSize: 18,
    fontFamily: FontFamily.Bold,
    color: BaseColor.whiteColor,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
});
export default styles;
