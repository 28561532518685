import postIcon from "../Assets/Svg/Post.svg";
import chatIcon from "../Assets/Svg/Chat.svg";
import notificationIcon from "../Assets/Svg/Notification.svg";
import cartIcon from "../Assets/Svg/Cart.svg";
import sellerIcon from "../Assets/Svg/Seller.svg";
import buyerIcon from "../Assets/Svg/Buyer.svg";
import cameraIcon from "../Assets/Svg/Camera.svg";
import whiteDownArrowIcon from "../Assets/Svg/Down_White_Arrow.svg";
import fillDownArrowIcon from "../Assets/Svg/Down_Fill_Arrow.svg";
import fillUpArrow from "../Assets/Svg/Up_Fill_Arrow.svg";
import rightArrow from "../Assets/Svg/Right-arrow.svg";
import leftArrow from "../Assets/Svg/left-arrow.svg";
import closeIcon from "../Assets/Svg/Close.svg";
import closeRedIcon from "../Assets/Svg/CloseRed.svg";
import closeWhiteIcon from "../Assets/Svg/CloseWhite.svg";
import fillStar from "../Assets/Svg/Fill_Star.svg";
import halfFillStarIcon from "../Assets/Svg/Half_Fill_Star.svg";
import unFillStarIcon from "../Assets/Svg/UnFill_Star.svg";
import outlineHeartIcon from "../Assets/Svg/Outline_Heart.svg";
import fillHeartIcon from "../Assets/Svg/Fill_Heart.svg";
import filterIcon from "../Assets/Svg/Filter.svg";
import hidePasswordIcon from "../Assets/Svg/HidePassword.svg";
import infoIcon from "../Assets/Svg/Info.svg";
import locationIcon from "../Assets/Svg/Location.svg";
import lockIcon from "../Assets/Svg/Lock.svg";
import PhoneIcon from "../Assets/Svg/Phone.svg";
import pinCodeIcon from "../Assets/Svg/pinCode.svg";
import searchIcon from "../Assets/Svg/Search.svg";
import shareIcon from "../Assets/Svg/Share.svg";
import unFillSubscriptionIcon from "../Assets/Svg/UnFill_Subscription.svg";
import fillSubscriptionIcon from "../Assets/Svg/Fill_Subscription.svg";
import telePhoneIcon from "../Assets/Svg/Telephone.svg";
import translateIcon from "../Assets/Svg/Translate.svg";
import userIcon from "../Assets/Svg/User.svg";
import whiteUserIcon from "../Assets/Svg/whiteUser.svg";
import fillVerifiedIcon from "../Assets/Svg/Fill_Verified.svg";
import unFillVerifiedIcon from "../Assets/Svg/UnFillVerified.svg";
import workIcon from "../Assets/Svg/Work.svg";
import phoneEmailIcon from "../Assets/Svg/phoneMail.svg";

import attachmentIcon from "../Assets/Svg/attachment.svg";
import editFillIcon from "../Assets/Svg/EditFill.svg";
import editWhiteIcon from "../Assets/Svg/EditWhite.svg";
import emailIcon from "../Assets/Svg/Email.svg";
import forwardIcon from "../Assets/Svg/Forward.svg";
import inquiryChatIcon from "../Assets/Svg/InquiryChat.svg";
import plusIcon from "../Assets/Svg/Plus.svg";
import sendIcon from "../Assets/Svg/Send.svg";
import smileIcon from "../Assets/Svg/Smile.svg";
import GoogleIcon from "../Assets/Svg/google.svg";
import download from "../Assets/Svg/download.svg";

import WaterMark from "../Assets/Svg/watermark.svg";

const Files = {
  images: {
    appIcon: require("../Assets/Images/appIcon-512.png"),
    logo: require("../Assets/Images/logo.png"),
    colorLogo: require("../Assets/Images/ScrapC_Color_Logo.jpg"),
    colorLogoVertical: require("../Assets/Images/ScrapC_Logo(2).jpg"),
    pdf: require("../Assets/Images/pdf.png"),
    doc: require("../Assets/Images/doc.png"),
  },
  lottie: {
    loader: require("../Assets/lottie/lineLoader.json"),
    pageLoader: require("../Assets/lottie/pageLoader.json"),
    error404: require("../Assets/lottie/Error404.json"),
    typing: require("../Assets/lottie/typingLoader.json"),
    noInternet: require("../Assets/lottie/noInternet.json"),
    primaryColorLoader: require("../Assets/lottie/primaryColorLoader.json"),
    emptyChatLoader: require("../Assets/lottie/chatLoader.json"),
    noData: require("../Assets/lottie/noData.json"),
    expirePost: require("../Assets/lottie/ExpirePostLottie.json"),
  },
  svgIcons: {
    post: postIcon,
    chat: chatIcon,
    notification: notificationIcon,
    cart: cartIcon,
    seller: sellerIcon,
    buyer: buyerIcon,
    camera: cameraIcon,
    whiteDownArrow: whiteDownArrowIcon,
    fillDownArrow: fillDownArrowIcon,
    upArrow: fillUpArrow,
    rightArrow: rightArrow,
    leftArrow: leftArrow,
    close: closeIcon,
    closeWhite: closeWhiteIcon,
    closeRed: closeRedIcon,
    fillStar: fillStar,
    halfFillStar: halfFillStarIcon,
    outlineStar: unFillStarIcon,
    outlineHeart: outlineHeartIcon,
    fillHeart: fillHeartIcon,
    filter: filterIcon,
    hidePassword: hidePasswordIcon,
    info: infoIcon,
    location: locationIcon,
    lock: lockIcon,
    phone: PhoneIcon,
    pinCode: pinCodeIcon,
    search: searchIcon,
    share: shareIcon,
    unFillSubscription: unFillSubscriptionIcon,
    fillSubscription: fillSubscriptionIcon,
    telePhone: telePhoneIcon,
    translate: translateIcon,
    user: userIcon,
    whiteUser: whiteUserIcon,
    fillVerified: fillVerifiedIcon,
    unFillVerified: unFillVerifiedIcon,
    work: workIcon,
    phoneEmail: phoneEmailIcon,
    attachmentIcon: attachmentIcon,
    editFill: editFillIcon,
    editWhite: editWhiteIcon,
    email: emailIcon,
    forward: forwardIcon,
    inquiryChat: inquiryChatIcon,
    plus: plusIcon,
    send: sendIcon,
    smile: smileIcon,
    google: GoogleIcon,
    watermarkLogo: WaterMark,
    downloadIcon: download,
  },
  regex: {
    characterOnly: /^[a-zA-Z]*$/,
    numberOnly: /^[0-9]*$/,
    phoneNumber: /^[6-9][0-9]{9}$/,
    emailRegex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
    otp: /^[0-9]{6}$/,
    descriptionRegex: /^(.|\s)*[a-zA-Z]+(.|\s)*$/,
  },
};

export default Files;
