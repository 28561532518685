import React, { useRef } from "react";
import Checkbox from "@mui/material/Checkbox";
import styles from "./styles";
import _ from "lodash";
import theme, { FontFamily } from "../../Config/theme";
import { Link, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import BaseColor from "../../Config/Color";
import { useNavigate } from "react-router-dom";
import { translate } from "../../lang/Translate";

const CCheckBox = (props) => {
  const navigate = useNavigate();

  const {
    checked = false,
    onChange = () => {},
    register,
    label = "",
    errorMsg = "",
  } = props;

  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const classes = styles();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Checkbox
          {...props}
          checked={checked}
          onChange={(e) => {
            const val = e.target.checked;
            onChange(val);
          }}
          id={"terms"}
          style={{ marginRight: 5 }}
        />
        {register ? (
          <label
            style={{
              fontFamily: FontFamily.Medium,
              color: BaseColor.textColor,
              cursor: "pointer",
              fontSize: sm ? 12 : 14,
              marginTop: sm && 2,
            }}
            for={"terms"}
          >
            <span style={{ fontSize: sm ? 12 : 14 }}>
              {translate("i_agree_with_the")}{" "}
            </span>
            <Link
              className={classes.link}
              style={{
                fontFamily: FontFamily.SemiBold,
                fontWeight: "900",
                cursor: "pointer",
                fontSize: sm ? 12 : 14,
              }}
              onClick={() => {
                navigate("/terms-and-conditions");
              }}
            >
              {translate("terms_and_condition_text")}
            </Link>{" "}
            {translate("and")}{" "}
            <Link
              className={classes.link}
              style={{
                fontFamily: FontFamily.SemiBold,
                fontWeight: "900",
                cursor: "pointer",
                fontSize: sm ? 12 : 14,
              }}
              onClick={() => {
                navigate("/privacy-policy");
              }}
            >
              {translate("privacy_policy_text")}.
            </Link>
          </label>
        ) : !_.isEmpty(label) ? (
          <label
            for={"terms"}
            style={{
              fontFamily: FontFamily.Medium,
              color: BaseColor.textColor,
              cursor: "pointer",
              fontSize: sm ? 12 : 14,
            }}
          >
            {label}
          </label>
        ) : null}
      </div>
      {!_.isEmpty(errorMsg) && (
        <div className={classes.errorMsgContainer}>
          <span className={classes.errorMsgText}>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

CCheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  register: PropTypes.bool,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
};

export default CCheckBox;
