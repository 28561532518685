import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/Color";
import { IoClose } from "react-icons/io5";
import _, { isArray, isEmpty } from "lodash";
import styles from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import NoData from "../../Components/NoData";
import { isMobile } from "react-device-detect";
import { replaceSpacesAndSpecialChars } from "../../Utils/commonFunctions";
import BaseSetting from "../../Apis/setting";
import { useSelector } from "react-redux";
import { getApiData } from "../../Apis/apiHelper";
import { toast } from "react-toastify";
import { translate } from "../../lang/Translate";

const CSearch = (props) => {
  const navigate = useNavigate();

  const location = useLocation();

  const {
    searchHistoryList,
    remove = () => {},
    getResult = () => {},
    searchResultData,
    searchVal,
    getSearchHistoryList = () => {},
  } = props;
  const classes = styles();
  const xsmall = "XSMALL_";

  const { accessToken, userData } = useSelector((state) => state.auth);

  function getAllPostApiCall(page = 1, searchVal) {
    const endpoint = BaseSetting.endpoint.getProductList;
    let data = {
      page: page,
    };
    if (
      !_.isNull(userData) &&
      !_.isEmpty(userData?.personal_info) &&
      !_.isNull(userData?.personal_info) &&
      !_.isUndefined(userData?.personal_info?.id) &&
      !_.isNull(userData?.personal_info?.id)
    ) {
      data.user_id = userData?.personal_info?.id;
    }
    if (!isEmpty(searchVal)) {
      data.searchValue = searchVal;
    }
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
        } else {
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  }

  return (
    <div
      style={{
        padding: "20px",
        width: "100%",
        maxHeight: "50vh",
        minHeight: "15vh",
      }}
    >
      {isEmpty(searchHistoryList?.history) &&
      isEmpty(searchHistoryList?.posts) &&
      isEmpty(searchHistoryList?.users) &&
      isEmpty(searchHistoryList?.business) ? (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          style={{ height: "100%", color: BaseColor.blackColor }}
        >
          <NoData title={translate("no_suggestions")} />
        </Grid>
      ) : (
        <Grid container style={{ width: "100%" }} paddingBottom={"15px"}>
          <Grid item xs={0} sm={0} md={0} lg={0} xl={0} />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {!isEmpty(searchHistoryList?.history) &&
              isArray(searchHistoryList?.history) && (
                <Grid item xs={12}>
                  <Typography
                    style={{ fontFamily: FontFamily.Bold, fontSize: 15 }}
                  >
                    {translate("search_list_history")}:
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {searchHistoryList?.history.map((item, index) => (
                      <div
                        style={{
                          margin: isMobile ? "6px 3px" : "5px 5px",
                          width: "auto",
                          padding: "2px 8px",
                          background: BaseColor.primary,
                          display: "flex",
                          marginRight: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: 5,
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          getSearchHistoryList(item?.title);
                          getResult(item?.title, 1, false);
                        }}
                      >
                        <span
                          style={{
                            width: "95%",
                            fontSize: 14,
                            fontFamily: FontFamily.SemiBold,
                            color: BaseColor.whiteColor,
                          }}
                        >
                          {item.title}
                        </span>
                        <IconButton size="small" style={{ padding: 0 }}>
                          <IoClose
                            size={22}
                            color={BaseColor.whiteColor}
                            onClick={(e) => {
                              remove(item?.id);
                              e.stopPropagation();
                            }}
                          />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                </Grid>
              )}
            {isEmpty(searchResultData?.postData) &&
            isEmpty(searchHistoryList?.posts) &&
            isEmpty(searchHistoryList?.users) &&
            isEmpty(searchHistoryList?.business) &&
            !_.isEmpty(searchVal) ? (
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                style={{ height: "100%", color: BaseColor.blackColor }}
              >
                <NoData title={translate("no_data_found")} />
              </Grid>
            ) : (
              <div>
                {!isEmpty(searchHistoryList?.posts) &&
                  isArray(searchHistoryList?.posts) && (
                    <Grid item xs={12} marginTop={"10px"}>
                      <Typography
                        style={{ fontFamily: FontFamily.Bold, fontSize: 15 }}
                      >
                        {translate("search_list_posts")}
                      </Typography>
                      <div
                        style={{ marginTop: "10px", color: BaseColor.black90 }}
                      >
                        {!isEmpty(searchHistoryList?.posts) &&
                          isArray(searchHistoryList?.posts) &&
                          searchHistoryList?.posts.map((item, index) => {
                            const urlName = replaceSpacesAndSpecialChars(
                              item.name
                            );
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginBottom: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  const searchVal = item?.name;
                                  getAllPostApiCall(1, searchVal);
                                  e.stopPropagation();
                                  const webUrl = window.location.href;
                                  const webUrlArray = webUrl.split("/");
                                  const length = !_.isEmpty(webUrlArray)
                                    ? webUrlArray.length - 1
                                    : null;
                                  const pageName = webUrlArray[length];
                                  if (
                                    webUrlArray[length - 1] == `product-detail`
                                  ) {
                                    window.location.replace(
                                      `/product-detail/${item?.id}-${urlName}`
                                    );
                                  } else {
                                    navigate(
                                      `/product-detail/${item?.id}-${urlName}`
                                    );
                                  }
                                }}
                              >
                                {!_.isEmpty(item.url) &&
                                !_.isUndefined(item.url) &&
                                !_.isNull(item.url) ? (
                                  <img
                                    src={item.url + xsmall + item?.image_name}
                                    style={{
                                      width: "35px",
                                      height: "35px",
                                      borderRadius: 5,
                                      border: `1px solid ${BaseColor.primary}`,
                                    }}
                                  />
                                ) : (
                                  <div className={classes.placeHolder}>
                                    <span className={classes.placeHolderText}>
                                      {item?.name?.charAt(0)?.toUpperCase()}
                                    </span>
                                  </div>
                                )}
                                <div style={{ marginLeft: "10px" }}>
                                  <div
                                    style={{
                                      fontFamily: FontFamily.Bold,
                                      fontSize: 15,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "35px",
                                    }}
                                  >
                                    {item?.name}
                                  </div>
                                  <div
                                    style={{
                                      fontFamily: FontFamily.Regular,
                                      fontSize: 13,
                                    }}
                                  >
                                    {item?.desc}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Grid>
                  )}

                {!isEmpty(searchHistoryList?.users) &&
                  isArray(searchHistoryList?.users) && (
                    <Grid item xs={12} marginTop={"10px"}>
                      <Typography
                        style={{ fontFamily: FontFamily.Bold, fontSize: 15 }}
                      >
                        {translate("search_list_user")}
                      </Typography>
                      <div
                        style={{
                          marginTop: "10px",
                          // display: "flex",
                          color: BaseColor.black90,
                        }}
                      >
                        {!isEmpty(searchHistoryList?.users) &&
                          isArray(searchHistoryList?.users) &&
                          searchHistoryList?.users.map((item, index) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const searchVal =
                                  item?.first_name + " " + item?.last_name;

                                getAllPostApiCall(1, searchVal);
                                const webUrl = window.location.href;
                                const webUrlArray = webUrl.split("/");
                                const length = !_.isEmpty(webUrlArray)
                                  ? webUrlArray.length - 1
                                  : null;
                                const pageName = webUrlArray[length];
                                const userName =
                                  !_.isEmpty(item?.first_name) &&
                                  !_.isEmpty(item?.last_name)
                                    ? `${item?.first_name} ${item?.last_name}`
                                    : "";
                                if (webUrlArray[length - 1] == "user-profile") {
                                  window.location.replace(
                                    `/user-profile/${item?.id}${
                                      !_.isEmpty(userName)
                                        ? `-${replaceSpacesAndSpecialChars(
                                            userName
                                          )}`
                                        : ""
                                    }`
                                  );
                                } else {
                                  navigate(
                                    `/user-profile/${item?.id}${
                                      !_.isEmpty(userName)
                                        ? `-${replaceSpacesAndSpecialChars(
                                            userName
                                          )}`
                                        : ""
                                    }`
                                  );
                                }
                              }}
                            >
                              {!_.isEmpty(item?.photo) &&
                              !_.isUndefined(item?.photo) &&
                              !_.isNull(item?.photo) ? (
                                <img
                                  src={item.photo}
                                  style={{
                                    height: 35,
                                    width: 35,
                                    borderRadius: 5,
                                    border: `1px solid ${BaseColor.primary}`,
                                  }}
                                />
                              ) : (
                                <div className={classes.placeHolder}>
                                  <span className={classes.placeHolderText}>
                                    {item?.first_name?.charAt(0)?.toUpperCase()}
                                  </span>
                                </div>
                              )}

                              <div style={{ marginLeft: "10px" }}>
                                <div
                                  style={{
                                    fontFamily: FontFamily.Bold,
                                    fontSize: 15,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "35px",
                                  }}
                                >
                                  {item?.first_name} {item?.last_name}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Grid>
                  )}
                {!isEmpty(searchHistoryList?.business) &&
                  isArray(searchHistoryList?.business) && (
                    <Grid item xs={12} marginTop={"10px"}>
                      <Typography
                        style={{ fontFamily: FontFamily.Bold, fontSize: 15 }}
                      >
                        {translate("search_list_business")}
                      </Typography>
                      <div
                        style={{
                          marginTop: "10px",
                          // display: "flex",
                          color: BaseColor.black90,
                        }}
                      >
                        {!isEmpty(searchHistoryList?.business) &&
                          isArray(searchHistoryList?.business) &&
                          searchHistoryList?.business.map((item, index) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const searchVal = item?.name;
                                getAllPostApiCall(1, searchVal);
                                const webUrl = window.location.href;
                                const webUrlArray = webUrl.split("/");
                                const length = !_.isEmpty(webUrlArray)
                                  ? webUrlArray.length - 1
                                  : null;
                                const pageName = webUrlArray[length];
                                const userName =
                                  !_.isEmpty(item?.first_name) &&
                                  !_.isEmpty(item?.last_name)
                                    ? `${item?.first_name} ${item?.last_name}`
                                    : "";
                                if (webUrlArray[length - 1] == "user-profile") {
                                  window.location.replace(
                                    `/user-profile/${item?.user_id}${
                                      !_.isEmpty(userName)
                                        ? `-${replaceSpacesAndSpecialChars(
                                            userName
                                          )}`
                                        : ""
                                    }`
                                  );
                                } else {
                                  navigate(
                                    `/user-profile/${item?.user_id}${
                                      !_.isEmpty(userName)
                                        ? `-${replaceSpacesAndSpecialChars(
                                            userName
                                          )}`
                                        : ""
                                    }`
                                  );
                                }
                              }}
                            >
                              {!_.isEmpty(item?.photo) &&
                              !_.isUndefined(item?.photo) &&
                              !_.isNull(item?.photo) ? (
                                <img
                                  src={item.photo}
                                  style={{
                                    height: 35,
                                    width: 35,
                                    borderRadius: 5,
                                    border: `1px solid ${BaseColor.primary}`,
                                  }}
                                />
                              ) : (
                                <div className={classes.placeHolder}>
                                  <span className={classes.placeHolderText}>
                                    {item?.name?.charAt(0)?.toUpperCase()}
                                  </span>
                                </div>
                              )}

                              <div style={{ marginLeft: "10px" }}>
                                <div
                                  style={{
                                    fontFamily: FontFamily.Bold,
                                    fontSize: 15,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "35px",
                                  }}
                                >
                                  {item?.name}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Grid>
                  )}
              </div>
            )}
          </Grid>
          <Grid item xs={0} sm={0} md={0} lg={0} xl={0} />
        </Grid>
      )}
    </div>
  );
};

export default CSearch;
