import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./App/Redux/Store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";
import theme, { FontFamily } from "./App/Config/theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import BaseSetting from "./App/Apis/setting";
import PushNotification from "./App/Components/PushNotification";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const root = ReactDOM.createRoot(document.getElementById("root"));

Bugsnag.start({
  apiKey: "cb0355e43575d49c44abbc6dd5d1dbf2",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ThemeProvider theme={theme}>
              <GoogleOAuthProvider clientId={BaseSetting.GOOGLE_CLIENT_ID}>
                <App />
              </GoogleOAuthProvider>
              <ToastContainer
                theme="light"
                className="toast_container"
                toastStyle={{
                  fontSize: 18,
                  fontFamily: FontFamily.Medium,
                }}
                bodyClassName={{ fontFamily: FontFamily.Regular }}
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <PushNotification />
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
