import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/Color";

const styles = makeStyles({
  container: {
    padding: "20px !important",
    backgroundColor: `${BaseColor.offWhite} !important`,
    borderRadius: 5,
  },

  search: {
    backgroundColor: `${BaseColor.whiteColor} !important`,
    "& .css-j5psij-MuiInputBase-root-MuiOutlinedInput-root": {
      padding: "0 8px !important",
    },
  },
  title: {
    fontFamily: `${FontFamily.SemiBold} !important`,
    fontWeight: "500 !important",
    fontSize: "18px !important",
  },

  scrollBar: {
    display: "flex",
    overflowY: "scroll",

    "&::-webkit-scrollbar": {
      width: 5,
      height: 5,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: BaseColor.disablePrimary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
});

export default styles;
