import {
  FormControl,
  Grid,
  IconButton,
  InputBase,
  ListItemButton,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import styles from "./styles";
import { BsCheck, BsPlus } from "react-icons/bs";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import BaseColor from "../../Config/Color";
import Files from "../../Config/Files";
import theme, { FontFamily } from "../../Config/theme";
import styled from "@emotion/styled";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CModal from "../CModal";
import _, { isEmpty, isNull, isUndefined } from "lodash";
import NotificationModal from "../NotificationModal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import authActions from "../../Redux/Reducers/auth/actions";
import { store } from "../../Redux/Store/configureStore";
import CButton from "../CButton";
import MenuIcon from "@mui/icons-material/Menu";
import MuiDrawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import CFilterModal from "../CFilterModal";
import CIcon from "../CIcon";
import { isMobile, isSafari } from "react-device-detect";
import socket from "../../Apis/socket";
import CSearch from "../CSearch";
import ConfirmModel from "../ConfirmationModal";
import CLink from "../CLink";
import { useSearchParams } from "react-router-dom";
import { translate } from "../../lang/Translate";
import ChangeLanguageModal from "../ChangeLanguageModal";
import { replaceSpacesAndSpecialChars } from "../../Utils/commonFunctions";
const drawerWidth = 250;

const Drawer = styled(MuiDrawer, {
  // shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: BaseColor.primary,
    whiteSpace: "nowrap",
    width: open ? drawerWidth : 60,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const CHeader = forwardRef((props, ref) => {
  // Set data in redux
  const dispatch = useDispatch();
  const { setActivePage, setSidebar, setUserFirstTime } = authActions;

  // Get Data from redux
  const { accessToken, userData, activePage, cartBadge, sidebar, UUID } =
    useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [logOutModal, setLogOutModal] = useState(false);
  const [changeLanguageModal, setChangeLanguageModal] = useState(false);

  const {
    onLoginRequired = () => {},
    from = "",
    isPopOver = false,
    isProductPage = false,
    onFilterApply = () => {},
    onApplyFilterData = () => {},
    selectedFilterArr = [],
    filterOpen = false,
    onFilterReset = () => {},
    onFilter = () => {},
    onNotificationFilter = () => {},
    onFilterClose = () => {},
    setSearchValue = () => {},
    filterPost = "",
    FilterUsingPost = () => {},
    categoryId = [],
    subCategoryId = [],
    conditionId = [],
    country = [],
    onChangePageLoader = () => {},
    onSearchVal = () => {},
    isHomePage = false,
  } = props;

  const drawerList = [
    {
      id: 1,
      title: translate("drawer_menu_my_profile"),
      icon: "",
      navigate: "",
    },
    {
      id: 2,
      title: translate("drawer_menu_my_cart"),
      icon: "",
      navigate: "",
    },
    {
      id: 3,
      title: translate("drawer_menu_chat"),
      icon: "",
      navigate: "",
    },
    {
      id: 4,
      title: translate("drawer_menu_notification"),
      icon: "",
      navigate: "",
    },
    {
      id: 5,
      title: translate("drawer_menu_favorite"),
      icon: "",
      navigate: "",
    },
    {
      id: 6,
      title: translate("drawer_menu_offer"),
      icon: "",
      navigate: "",
    },
    {
      id: 7,
      title: translate("drawer_menu_alert"),
      icon: "",
      navigate: "",
    },
    {
      id: 8,
      title: translate("drawer_menu_logistics"),
      icon: "",
      navigate: "",
    },
    // {
    //   id: 9,
    //   title: "Subscription",
    //   icon: "",
    //   navigate: "",
    // },
    { id: 9, title: translate("drawer_menu_chat_with_scrapc"), navigate: "" },
    {
      id: 10,
      title: translate("drawer_menu_contact_us"),
      icon: "",
      navigate: "",
    },
    {
      id: 11,
      title: translate("drawer_menu_change_language"),
      icon: "",
      navigate: "",
    },
    {
      id: 12,
      title: translate("drawer_menu_log_out"),
      icon: "",
      navigate: "",
    },
  ];
  const CHeaderProfileDropdownData = [
    {
      id: 1,
      title: translate("drawer_menu_my_profile"),
    },
    {
      id: 2,
      title: translate("drawer_menu_favorite"),
      navigation: "/favorites",
    },
    { id: 3, title: translate("drawer_menu_offer"), navigation: "/myOffers" },
    {
      id: 4,
      title: translate("drawer_menu_alert"),
    },
    {
      id: 5,
      title: translate("drawer_menu_logistics"),
      navigation: "/logistic",
    },
    // { id: 6, title: "Subscription" },
    {
      id: 9,
      title: translate("drawer_menu_chat_with_scrapc"),
      navigation: "/chat",
    },
    {
      id: 7,
      title: translate("drawer_menu_contact_us"),
      navigation: "/contact-us",
    },
    {
      id: 10,
      title: translate("drawer_menu_change_language"),
      icon: "",
      navigate: "",
    },
    { id: 8, title: translate("drawer_menu_log_out") },
  ];

  const loginRequired = _.isEmpty(accessToken);
  const userId = userData?.personal_info?.id;

  const navigate = useNavigate();
  const location = useLocation();
  const observedDiv = useRef(null);
  const classes = styles();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchVal = searchParams.get("search");

  //search history list
  const [searchText, setSearchText] = useState(
    !isEmpty(searchVal) && !isUndefined(searchVal) && !isNull(searchVal)
      ? searchVal
      : ""
  );
  const [searchHistoryList, setSearchHistoryList] = useState([]);
  // Notification list
  const [notificationList, setNotificationList] = useState([]);
  const [notificationPagination, setNotificationPagination] = useState([]);

  // Modal
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;
  const [open, setOpen] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(250);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // notification model
  const [noti, setNoti] = useState(null);
  const popN = Boolean(noti);
  const idN = popN ? "simple-popover" : undefined;
  const [page, setPage] = useState(1);

  // filter modal state
  const popF = Boolean(filterOpen);
  const idF = popF ? "simple-popover" : undefined;

  // create post modal state
  const [createPost, setCreatePost] = useState(null);
  const popCP = Boolean(createPost);
  const idCP = popF ? "simple-popover" : undefined;

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [notificationModal, setNotificationModal] = useState(false);

  const [openSearch, setOpenSearch] = useState(false);

  const [searchReasultData, setSearchResultData] = useState([]);

  const [disableNoti, setDisableNoti] = useState(false);

  const [notificationData, setNotificationData] = useState({});
  const [divDimension, setDivDimension] = useState({ width: null, left: null });

  // remove email from search bar
  useEffect(() => {
    if (searchText === userData?.personal_info?.email) {
      setSearchText("");
    }
  }, [searchText]);

  useImperativeHandle(ref, () => ({
    search(val, bool) {
      onSearchVal(val, bool);
    },
    close() {
      setOpenSearch(false);
    },
  }));

  useEffect(() => {
    setTimeout(() => {
      if (observedDiv && observedDiv.current) {
        const width = observedDiv.current.clientWidth; // this will return div width
        const left = observedDiv.current.offsetLeft; // this will return div left offset
        setDivDimension({ width, left }); // store width and offset to state
      }
    }, 500);
  }, [observedDiv.current]);

  //search history list api
  const getSearchHistoryList = (searchVal) => {
    const endpoint = BaseSetting.endpoint.searchList;
    let data = { searchVal: searchVal.trim() };
    if (!loginRequired) {
      data.user_id = String(userId);
    }
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setSearchHistoryList(data);
        } else {
          // toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  // remove search history
  const removeSearchHistory = (id) => {
    const endpoint = BaseSetting.endpoint.removeSearchList;
    let data = { id: id };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          getSearchHistoryList(searchText);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  useEffect(() => {
    if (openSearch === true) {
      document.addEventListener("mouseup", function (e) {
        var container = document.getElementById("searchDiv");
        if (!container?.contains(e.target)) {
          setOpenSearch(false);
        }
      });
    }
  }, [openSearch]);

  useEffect(() => {
    if (!popN) {
      setPage(1);
    }
  }, [popN, userData]);

  useEffect(() => {
    receiveNotification();
  });

  useEffect(() => {
    setTimeout(() => {
      userConnect();
    }, 700);
  }, []);

  // connection to backed for notifications or chat
  const userConnect = () => {
    if (!_.isEmpty(userData) && !_.isUndefined(userId)) {
      socket.connect();
      const data = {
        user_id: userData?.personal_info?.id,
      };
      socket.emit("userConnect", data, (callBackData) => {});
      // setTimeout(() => {
      GetNotificationList(1);
      // }, 350);
    }
  };

  const GetCommonDataApi = (list) => {
    const endpoint = BaseSetting.endpoint.commonData;

    let data = {};

    if (!_.isEmpty(list) && _.isArray(list)) {
      const parentIds = list.category_id;
      data.parent_ids = parentIds; // get sub categories by category
    }

    if (!_.isEmpty(list) && _.isObject(list)) {
      const parentIds = list.category_id;
      data.parent_ids = parentIds; // get sub categories by category
    }

    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const selectedCategories = list.category_id;
          const selectedSubCat = list.sub_category_id;
          const selectedCondition = list.condition_id;
          const selectedCountry = list.country;

          const categoryList = result?.data?.categories;
          const subCategoryList = result?.data?.subCategories;
          const conditionList = result?.data?.conditions;
          const countryList = result?.data?.countries;

          const filterCat = categoryList.filter(
            (v) => selectedCategories.find((id) => v.id === id) && v
          );
          const filterSubCat = !_.isEmpty(selectedSubCat)
            ? subCategoryList.filter(
                (v) => selectedSubCat.find((id) => v.id === id) && v
              )
            : [];
          const filterCon = !_.isEmpty(selectedCondition)
            ? conditionList.filter(
                (v) => selectedCondition.find((id) => v.id === id) && v
              )
            : [];
          const filterCountry = !_.isEmpty(selectedCountry)
            ? countryList.filter(
                (v) => selectedCountry.find((id) => v.id === id) && v
              )
            : [];

          const addTypeCat = filterCat.map((v) => {
            v.type = "category";
            return v;
          });
          const addTypeSubCat = filterSubCat.map((v) => {
            v.type = "sub_category";
            return v;
          });
          const addTypeCon = filterCon.map((v) => {
            v.type = "condition";
            return v;
          });
          const addTypeCountry = filterCountry.map((v) => {
            v.type = "country";
            return v;
          });

          const catgoryIds = !_.isEmpty(list?.category_id)
            ? list?.category_id
            : [];
          const subCategoriesIds = !_.isEmpty(list?.sub_category_id)
            ? list?.sub_category_id
            : [];
          const conditionsIds = !_.isEmpty(list?.condition_id)
            ? list?.condition_id
            : [];
          const countries = !_.isEmpty(list?.country) ? list?.country : [];

          const selectedFilter = addTypeCat
            .concat(addTypeSubCat)
            .concat(addTypeCon)
            .concat(addTypeCountry);

          const webUrl = window.location.href;
          const webUrlArray = webUrl.split("/");
          const length = !_.isEmpty(webUrlArray)
            ? webUrlArray.length - 1
            : null;
          const url = webUrlArray[length];
          const pageName = url.split("?");
          if (pageName[0] === "products") {
            setNoti(null);
            if (_.isObject(list)) {
              onNotificationFilter(list);
            }
            onApplyFilterData(selectedFilter);
          } else {
            let navigateUrl = "/products?";

            if (!_.isEmpty(catgoryIds)) {
              navigateUrl += `category=${catgoryIds}&`;
            }
            if (!_.isEmpty(subCategoriesIds)) {
              navigateUrl += `subCategory=${subCategoriesIds}&`;
            }
            if (!_.isEmpty(conditionsIds)) {
              navigateUrl += `condition=${conditionsIds}&`;
            }
            if (!_.isEmpty(countries)) {
              navigateUrl += `country=${countries}`;
            }
            navigate(navigateUrl);
          }
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const LogOutApi = () => {
    setLoader(true);
    const endpoint = BaseSetting.endpoint.logOut;
    const data = { uuid: UUID };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          store.dispatch(authActions.setAccessToken(""));
          store.dispatch(authActions.setUserData({}));
          store.dispatch(authActions.setFirstTimeSkip(false));
          socket.disconnect();
          setLoader(false);
          navigate("/login");
        } else {
          toast(result?.message, { type: "error" });
          setLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setLoader(false);
      });
  };

  // Socket emits Start
  const GetNotificationList = (page) => {
    const data = {
      user_id: userData?.personal_info?.id,
      page: page,
    };
    socket.emit("getNotificationList", data, (callBackData) => {
      if (callBackData.status) {
        if (page !== 1) {
          setNotificationList([
            ...notificationList,
            ...callBackData?.data?.getNotifications,
          ]);
        } else {
          setNotificationList(callBackData?.data?.getNotifications);
          setNotificationData(callBackData?.data);
        }
        setNotificationPagination(callBackData?.data?.pagination);
      } else {
        toast(callBackData.message, { type: "error" });
      }
    });
  };

  const receiveNotification = () => {
    socket.on("receiveNotification", (req, res) => {
      if (!isEmpty(userData) && !isUndefined(userData?.personal_info?.id)) {
        GetNotificationList(1);
      }
      const notification = new Notification(req?.title, {
        body: req.desc,
        icon: Files.images.appIcon,
        vibrate: true,
        native: true,
      });
      setTimeout(() => notification.close(), 10 * 1000);
    });
  };

  const ReadAllNotification = () => {
    const data = {
      user_id: userId,
    };
    socket.emit("readAllNotification", data, (callBackData) => {
      if (callBackData?.status) {
        if (!isEmpty(userData) && !isUndefined(userData?.personal_info?.id)) {
          GetNotificationList(1);
        }
        setPage(1);
      } else {
        toast(callBackData.message, { type: "error" });
      }
    });
  };

  const ClearAllNotification = () => {
    const data = {
      user_id: userId,
    };
    socket.emit("clearAll", data, (callBackData) => {
      if (callBackData?.status) {
        if (!isEmpty(userData) && !isUndefined(userData?.personal_info?.id)) {
          GetNotificationList(1);
        }
        setPage(1);
      } else {
        toast(callBackData.message, { type: "error" });
      }
    });
  };

  const ReadSingleNotification = (nId) => {
    setDisableNoti(true);
    const data = {
      notification_id: nId,
    };
    socket.emit("readSingleNotification", data, (callBackData) => {
      if (callBackData.status) {
        if (!isEmpty(userData) && !isUndefined(userData?.personal_info?.id)) {
          GetNotificationList(1);
        }
        setPage(1);
        setDisableNoti(false);
      } else {
        toast(callBackData.message, { type: "error" });
        setDisableNoti(false);
      }
    });
  };

  const isUnreadChatNotificationExists =
    !_.isEmpty(notificationList) && _.isArray(notificationList)
      ? notificationList?.filter(
          (v) => v.action == "send_message" && v.is_read == "No"
        )
      : null;
  const isUnReadNotificationExists =
    !_.isEmpty(notificationList) && _.isArray(notificationList)
      ? notificationList?.filter((v) => v.is_read == "No")
      : null;

  const renderNotificationModal = () => {
    return (
      <NotificationModal
        data={notificationList}
        pagination={notificationPagination}
        isDisabledMarkAsReadBtn={_.isEmpty(isUnReadNotificationExists)}
        isDisabledClearBtn={_.isEmpty(notificationList)}
        disabledSingleNoti={disableNoti}
        notificationData={notificationData}
        onReachBottom={() => {
          const pageNo = page + 1;
          GetNotificationList(pageNo);
          setPage(pageNo);
        }}
        onMarkReadAll={() => {
          const isUnreadMsg = notificationList?.filter(
            (v) => v.is_read == "No"
          );
          if (!_.isEmpty(isUnreadMsg)) {
            ReadAllNotification();
          }
        }}
        onClick={(item) => {
          const isUnread = item.is_read == "No";
          if (item?.action === "filterPage") {
            const filterData =
              !_.isEmpty(item?.data) && _.isString(item?.data)
                ? JSON.parse(item?.data)
                : {};
            GetCommonDataApi(filterData);
          } else if (item?.action === "send_message") {
            const coversationId =
              !_.isEmpty(item?.data) && _.isString(item?.data)
                ? JSON.parse(item?.data)
                : {};
            window.location.replace(`/chat?id=${coversationId?.conv_id}`);
          } else if (item?.action === "rejectUserVerify") {
            navigate("/profile");
          } else if (item?.action === "verifiedUser") {
            navigate("/profile");
          } else if (item?.action === "followUser") {
            const userData =
              !_.isEmpty(item?.data) && _.isString(item?.data)
                ? JSON.parse(item?.data)
                : {};
            navigate(
              `/user-profile/${userData?.user_id}${
                !_.isEmpty(userData?.user_name)
                  ? `-${replaceSpacesAndSpecialChars(userData?.user_name)}`
                  : ""
              }`
            );
          } else if (item?.action === "postReject") {
            const postData =
              !_.isEmpty(item?.data) && _.isString(item?.data)
                ? JSON.parse(item?.data)
                : {};
            navigate(
              `/product-detail/${
                postData?.post_id
              }-${replaceSpacesAndSpecialChars(postData?.post_name)}`
            );
          } else if (item?.action === "postApproved") {
            const postData =
              !_.isEmpty(item?.data) && _.isString(item?.data)
                ? JSON.parse(item?.data)
                : {};
            navigate(
              `/product-detail/${
                postData?.post_id
              }-${replaceSpacesAndSpecialChars(postData?.post_name)}`
            );
          } else if (item?.action === "expirePost") {
            const postData =
              !_.isEmpty(item?.data) && _.isString(item?.data)
                ? JSON.parse(item?.data)
                : {};
            navigate(
              `/product-detail/${
                postData?.post_id
              }-${replaceSpacesAndSpecialChars(postData?.post_name)}`
            );
          } else if (item?.action === "postDetailPage") {
            const postData =
              !_.isEmpty(item?.data) && _.isString(item?.data)
                ? JSON.parse(item?.data)
                : {};
            navigate(`/product-detail/${postData?.post_id}`);
          } else if (item?.action === "postListPage") {
            if (userData?.isProfileComplete) {
              if (JSON.parse(item?.data)?.redirect_page === "home_page") {
                if (
                  !_.isNull(JSON.parse(item?.data)?.post_id) &&
                  !_.isUndefined(JSON.parse(item?.data)?.post_id)
                ) {
                  const postData =
                    !_.isEmpty(item?.data) && _.isString(item?.data)
                      ? JSON.parse(item?.data)
                      : {};
                  navigate(`/product-detail/${postData?.post_id}`);
                } else {
                  navigate("/products");
                }
              } else if (
                JSON.parse(item?.data)?.redirect_page === "profile_page"
              ) {
                navigate("/profile");
              } else if (
                JSON.parse(item?.data)?.redirect_page === "favorite_page"
              ) {
                navigate("/favorites");
              } else if (
                JSON.parse(item?.data)?.redirect_page === "my_offr_page"
              ) {
                navigate("/myOffers");
              } else if (
                JSON.parse(item?.data)?.redirect_page === "alert_page"
              ) {
                navigate("/alert");
              } else if (
                JSON.parse(item?.data)?.redirect_page === "logistic_page"
              ) {
                navigate("/logistic", {
                  state: { from: "user" },
                });
              } else if (
                JSON.parse(item?.data)?.redirect_page === "cart_page"
              ) {
                navigate("/my-cart");
              } else if (
                JSON.parse(item?.data)?.redirect_page === "notification_page"
              ) {
                navigate("/notification");
              } else if (
                JSON.parse(item?.data)?.redirect_page === "buy_post_page"
              ) {
                navigate("/buy-post");
              } else if (
                JSON.parse(item?.data)?.redirect_page === "sell_post_page"
              ) {
                navigate("/sell-post");
              } else if (
                JSON.parse(item?.data)?.redirect_page === "privacy_policy_page"
              ) {
                navigate("/privacy-policy");
              } else if (
                JSON.parse(item?.data)?.redirect_page ===
                "tearms_conditoins_page"
              ) {
                navigate("/terms-and-conditions");
              } else if (
                JSON.parse(item?.data)?.redirect_page === "live_price_page"
              ) {
                window.open(BaseSetting?.livePriceUrl, "_blank");
              } else if (
                JSON.parse(item?.data)?.redirect_page === "contact_us_page"
              ) {
                navigate("/contact-us");
              } else if (
                !_.isNull(JSON.parse(item?.data)?.post_id) &&
                !_.isUndefined(JSON.parse(item?.data)?.post_id)
              ) {
                const postData =
                  !_.isEmpty(item?.data) && _.isString(item?.data)
                    ? JSON.parse(item?.data)
                    : {};
                navigate(`/product-detail/${postData?.post_id}`);
              }
            }
          }

          if (isUnread) {
            ReadSingleNotification(item?.id);
          }
        }}
        onClearAll={() => {
          if (notificationList?.length > 0) {
            ClearAllNotification();
          }
        }}
        handlePress={(type) => {
          if (loginRequired) {
            onLoginRequired();
          } else {
            if (type === "close") {
              setNoti(null);
              setNotificationModal(false);
            }
          }
        }}
      />
    );
  };

  const backToAdmin = () => {
    store.dispatch(authActions.setAccessToken(""));
    store.dispatch(authActions.setUserData({}));
    socket.disconnect();
    window.location.replace(`${BaseSetting.adminUrl}/all-users`);
  };

  return (
    <Grid container>
      <Grid container className={classes.root}>
        <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
        <Grid
          item
          xs={11}
          sm={11}
          md={11}
          lg={10}
          xl={10}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          gap={3}
        >
          <div
            style={{
              cursor: "pointer",
              marginRight: md ? 10 : 0,
              marginTop: md && 5,
            }}
          >
            <a href="/products">
              {isMobile && md ? (
                <img
                  src={Files?.svgIcons?.watermarkLogo}
                  alt={"ScrapC"}
                  title="ScrapC"
                  style={{ height: 40, width: 40, objectFit: "contain" }}
                />
              ) : (
                <img
                  alt={"ScrapC"}
                  title="ScrapC"
                  src={Files.images.logo}
                  className={classes.logo}
                />
              )}
            </a>
          </div>
          <Grid item className={classes.inputContainer} ref={observedDiv}>
            {!sm && (
              <Grid item>
                {isProductPage ? (
                  <Select
                    sx={{
                      height: 40,
                      "&:hover": { border: "none", outline: "none" },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                    }}
                    variant="outlined"
                    displayEmpty
                    value={filterPost}
                    onChange={(e) => {
                      FilterUsingPost(e.target.value);
                    }}
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                  >
                    <MenuItem value="" selected hidden>
                      {translate("all_post")}
                    </MenuItem>
                    <MenuItem value="Buy">{translate("buy_post")}</MenuItem>
                    <MenuItem value="Sell">{translate("sell_post")}</MenuItem>
                  </Select>
                ) : (
                  <Tooltip
                    title={"Filter only work in home page"}
                    disableHoverListener={isProductPage}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: 17,
                          backgroundColor: BaseColor.white,
                          color: BaseColor.textColor,
                          fontFamily: FontFamily.Medium,
                        },
                      },
                    }}
                  >
                    <Select
                      sx={{
                        height: 40,
                        "&:hover": { border: "none", outline: "none" },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                        },
                      }}
                      variant="outlined"
                      displayEmpty
                      value={filterPost}
                      onChange={(e) => {
                        FilterUsingPost(e.target.value);
                      }}
                      readOnly
                      IconComponent={KeyboardArrowDownOutlinedIcon}
                    >
                      <MenuItem value="" selected hidden>
                        {translate("all_post")}
                      </MenuItem>
                      <MenuItem value="Buy">{translate("buy_post")}</MenuItem>
                      <MenuItem value="Sell">{translate("sell_post")}</MenuItem>
                    </Select>
                  </Tooltip>
                )}
              </Grid>
            )}
            {md && (
              <CModal
                visible={notificationModal}
                onClose={() => setNotificationModal(false)}
                children={renderNotificationModal("modal")}
              />
            )}
            <Grid item className={classes.searchInputContainer} id="searchDiv">
              <Grid
                sx={{
                  transition: "transform .2s",
                  "& :hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <CIcon
                  src={Files.svgIcons.search}
                  color={BaseColor.whiteColor}
                  size={24}
                  onClick={() => {
                    !isEmpty(searchText) &&
                      searchText.trimStart() !== "" &&
                      onSearchVal(searchText, true);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </Grid>

              <Grid container style={{ width: "100%" }}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchText.trim() != "") {
                      const webUrl = window.location.href;
                      const webUrlArray = webUrl.split("/");
                      const length = !_.isEmpty(webUrlArray)
                        ? webUrlArray.length - 1
                        : null;
                      const pageName = webUrlArray[length];
                      if (webUrlArray[length] !== "products") {
                        navigate(`/products?page=1&search=${searchText}`);
                      }
                      onSearchVal(searchText, true);
                    }
                  }}
                  style={{ width: "100%" }}
                >
                  <InputBase
                    sx={{
                      display: "inline-block",
                      width: "100%",
                      "& input": {
                        width: "100%",
                        marginLeft: 2,
                        background: BaseColor.primary,
                        border: "none",
                        color: BaseColor.whiteColor,
                        fontFamily: FontFamily.Medium,
                        fontSize: "16px",
                        height: 40,
                        "&::placeholder": {
                          color: BaseColor.white,
                        },
                      },
                      "&  .inputFocused": {
                        outline: "none",
                        border: "none",
                      },
                      input: {
                        "&::placeholder": {
                          color: BaseColor.white,
                        },
                      },
                    }}
                    placeholder={translate("search_placeholder")}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      getSearchHistoryList(e.target.value);
                      setOpenSearch(true);
                      if (isEmpty(e.target.value)) {
                        onSearchVal(e.target.value, true);
                      }
                    }}
                    onFocus={() => {
                      getSearchHistoryList(searchText);
                      setOpenSearch(true);
                    }}
                  />
                </form>

                {openSearch && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      width: sm && isMobile ? "100%" : divDimension.width,
                      top: sm && isMobile ? "90px" : "72px",
                      left: sm && isMobile ? 0 : divDimension.left,
                      minHeight: "50px",
                      maxheight: "250px",
                      boxShadow: " 0 0 5px rgb(103	65	136 / 60%)",
                      backgroundColor: BaseColor.whiteColor,
                      overflowY: "scroll",
                      marginBottom: "10px",
                      border:
                        sm && isMobile ? 0 : `1px solid ${BaseColor.primary}`,
                      borderRadius: sm && isMobile ? 0 : 2,
                    }}
                    className={classes.scrollBar}
                  >
                    <CSearch
                      searchHistoryList={searchHistoryList}
                      getSearchHistoryList={(searchVal) =>
                        getSearchHistoryList(searchVal)
                      }
                      searchResultData={searchReasultData}
                      remove={(id) => removeSearchHistory(id)}
                      searchVal={searchText}
                      getResult={(val) => {
                        setSearchText(val);
                        if (val.trim() != "") {
                          const webUrl = window.location.href;
                          const webUrlArray = webUrl.split("/");
                          const length = !_.isEmpty(webUrlArray)
                            ? webUrlArray.length - 1
                            : null;
                          const pageName = webUrlArray[length];
                          if (webUrlArray[length] !== "products") {
                            navigate(`/products?page=1&search=${val}`);
                          }
                          onSearchVal(val, true);
                        }
                      }}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item className={classes.filterContainer}>
              <Tooltip
                title={"Filter only work in home page"}
                disableHoverListener={isProductPage}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: 17,
                      backgroundColor: BaseColor.white,
                      color: BaseColor.textColor,
                      fontFamily: FontFamily.Medium,
                    },
                  },
                }}
              >
                <IconButton
                  style={{
                    padding: 0,
                    cursor: "pointer",
                    color: !isProductPage
                      ? BaseColor.white90
                      : BaseColor.whiteColor,
                  }}
                  onClick={(event) => {
                    isProductPage && onFilter(event.nativeEvent);
                  }}
                >
                  <HiOutlineAdjustmentsHorizontal size={28} />
                </IconButton>
              </Tooltip>
              {isPopOver ? (
                <Popover
                  onClose={() => onFilter(false)}
                  anchorEl={filterOpen}
                  anchorReference={"none"}
                  id={idF}
                  open={popF}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    top: 90,
                  }}
                >
                  <CFilterModal
                    categoryId={categoryId}
                    subCategoryId={subCategoryId}
                    conditionId={conditionId}
                    country={country}
                    isProductPage={isProductPage}
                    onApplyFilterData={onApplyFilterData}
                    selectedFilterArr={selectedFilterArr}
                    onApply={onFilterApply}
                    onReset={onFilterReset}
                    onlyCategory={true}
                    onlySubCategory={true}
                    onlyCondition={true}
                    onlyCountry={true}
                    // onlyState={isHomePage}
                    // onlyCity={isHomePage}
                  />
                </Popover>
              ) : (
                <CModal
                  visible={filterOpen}
                  onClose={() => {
                    onFilter(false);
                  }}
                >
                  <CFilterModal
                    isModal
                    categoryId={categoryId}
                    subCategoryId={subCategoryId}
                    conditionId={conditionId}
                    country={country}
                    isProductPage={isProductPage}
                    onApplyFilterData={onApplyFilterData}
                    selectedFilterArr={selectedFilterArr}
                    onReset={onFilterReset}
                    onApply={onFilterApply}
                    onlyCategory={true}
                    onlySubCategory={true}
                    onlyCondition={true}
                    onlyCountry={true}
                    // onlyState={true}
                    // onlyCity={true}
                  />
                </CModal>
              )}
            </Grid>
          </Grid>
          {!md ? (
            <Grid item display={"flex"} alignItems={"center"} gap={3}>
              <CButton
                variant={"outlined"}
                onClick={(e) => {
                  if (loginRequired) {
                    onLoginRequired();
                  }
                  // else if (userData?.isProfileComplete == false) {
                  //   setProfileCompleted(true);
                  // }
                  else {
                    setCreatePost(e.currentTarget);
                  }
                }}
                style={{
                  background: BaseColor.whiteColor,
                  color: BaseColor.primary,
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "center",
                  borderRadius: 5,
                  height: 40,
                  padding: "8px 10px",
                }}
              >
                <div className={classes.postIconContainer}>
                  <BsPlus size={16} color={BaseColor.primary} />
                </div>
                <span className={classes.createPostText}>
                  {translate("create_post_title")}
                </span>
              </CButton>

              <Popover
                onClose={() => setCreatePost(null)}
                anchorEl={createPost}
                id={idCP}
                open={popCP}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                className={classes.createPostPopoover}
                style={{ marginTop: "2%" }}
              >
                <Grid
                  style={{
                    backgroundColor: BaseColor.whiteColor,
                    padding: 8,
                    border: `1px solid ${BaseColor.primary}`,
                    borderRadius: 5,
                    width: 130,
                  }}
                >
                  <ListItemButton
                    style={{
                      marginLeft: -8,
                      fontSize: 14,
                      fontFamily: FontFamily.SemiBold,
                      fontWeight: "900",
                      color: BaseColor.primary,
                    }}
                    onClick={() => {
                      navigate("/sell-post");
                      setCreatePost(null);
                    }}
                  >
                    {translate("sell_post")}
                  </ListItemButton>
                  <div
                    style={{ borderBottom: `1px solid ${BaseColor.primary}` }}
                  />
                  <ListItemButton
                    style={{
                      marginLeft: -8,
                      fontSize: 14,
                      fontFamily: FontFamily.SemiBold,
                      fontWeight: "900",
                      color: BaseColor.primary,
                    }}
                    onClick={() => {
                      navigate("/buy-post");
                      setCreatePost(null);
                    }}
                  >
                    {translate("buy_post")}
                  </ListItemButton>
                </Grid>
              </Popover>
              <Grid
                item
                className={classes.threeIconContainer}
                alignItems="center"
                // wrap="nowrap"
                gap={3}
              >
                <Grid style={{ position: "relative" }}>
                  <CIcon
                    src={Files.svgIcons.cart}
                    size={30}
                    color={BaseColor.whiteColor}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (loginRequired) {
                        onLoginRequired();
                      } else {
                        if (location?.pathname === "/create-profile") {
                          dispatch(setUserFirstTime(false));
                          navigate("/my-cart");
                        } else {
                          navigate("/my-cart");
                        }
                      }
                    }}
                  />
                  {cartBadge && (
                    <div
                      style={{
                        height: 8,
                        width: 8,
                        borderWidth: 2,
                        borderStyle: "solid",
                        borderColor: BaseColor.primary,
                        background: BaseColor.white,
                        borderRadius: 50,
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    />
                  )}
                </Grid>
                <Grid style={{ position: "relative" }}>
                  {!_.isEmpty(isUnreadChatNotificationExists) &&
                    isUnreadChatNotificationExists.length > 0 && (
                      <div
                        style={{
                          height: 8,
                          width: 8,
                          borderWidth: 2,
                          borderStyle: "solid",
                          borderColor: BaseColor.primary,
                          background: BaseColor.white,
                          borderRadius: 50,
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }}
                      />
                    )}
                  <CIcon
                    src={Files.svgIcons.chat}
                    size={30}
                    style={{
                      cursor: "pointer",
                      marginRight: userData?.is_admin && "10px",
                    }}
                    color={BaseColor.whiteColor}
                    onClick={() => {
                      if (loginRequired) {
                        onLoginRequired();
                      } else {
                        if (userData?.isProfileComplete) {
                          navigate("/chat");
                        } else {
                          setProfileCompleted(true);
                        }
                      }
                    }}
                  />
                </Grid>

                {!userData?.is_admin && (
                  <Grid style={{ position: "relative" }}>
                    {!_.isEmpty(isUnReadNotificationExists) &&
                      isUnReadNotificationExists.length > 0 && (
                        <div
                          style={{
                            height: 8,
                            width: 8,
                            borderWidth: 2,
                            borderStyle: "solid",
                            borderColor: BaseColor.primary,
                            background: BaseColor.white,
                            borderRadius: 50,
                            position: "absolute",
                            top: 0,
                            right: 20,
                          }}
                        />
                      )}
                    <CIcon
                      src={Files.svgIcons.notification}
                      size={30}
                      color={BaseColor.whiteColor}
                      style={{ cursor: "pointer", marginRight: 20 }}
                      onClick={(event) => {
                        if (!userData?.is_admin) {
                          if (loginRequired) {
                            onLoginRequired();
                          } else {
                            if (!isEmpty(notificationList)) {
                              setNoti(event.currentTarget);
                            } else {
                              toast("There is no notification", {
                                type: "warning",
                              });
                            }
                          }
                        }
                      }}
                    />
                  </Grid>
                )}

                <Popover
                  onClose={() => {
                    setNoti(null);
                    setPage(1);
                  }}
                  anchorEl={noti}
                  id={idN}
                  open={popN}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  style={{
                    display: "flex",
                    marginTop: "2%",
                    justifyContent: "center",
                  }}
                  PaperProps={{
                    style: {
                      overflowY: "hidden",
                    },
                  }}
                >
                  {renderNotificationModal()}
                </Popover>
                <CModal
                  visible={notificationModal}
                  onClose={() => setNotificationModal(false)}
                  children={renderNotificationModal()}
                />
              </Grid>
              <Grid
                item
                className={classes.userProfileContainer}
                style={{ marginRight: -8 }}
              >
                <div
                  className={classes.userProfileImg}
                  onClick={(event) => {
                    if (loginRequired) {
                      onLoginRequired();
                    } else {
                      setAnchorEl(event.currentTarget);
                    }
                  }}
                >
                  {userData?.personal_info?.is_verified == "1" && (
                    <div
                      style={{
                        backgroundColor: BaseColor?.whiteColor,
                        position: "absolute",
                        bottom: "22px",
                        width: "15px",
                        height: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <BsCheck style={{ color: BaseColor.primary }} />
                    </div>
                  )}
                  <img
                    alt="profile_img"
                    src={
                      !_.isEmpty(userData.user_image)
                        ? userData.user_image
                        : Files.svgIcons.whiteUser
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                      objectFit: "contain",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <IconButton
                  onClick={(event) => {
                    if (loginRequired) {
                      onLoginRequired();
                    } else {
                      setAnchorEl(event.currentTarget);
                    }
                  }}
                  style={{ padding: "0px 0px 0px 10px" }}
                >
                  <KeyboardArrowDownOutlinedIcon
                    style={{
                      fontSize: 24,
                      color: BaseColor.whiteColor,
                      transform: !_.isNull(anchorEl) && "rotate(180deg)",
                    }}
                  />
                </IconButton>

                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  style={{ marginTop: "2.5%" }}
                >
                  <Grid
                    style={{
                      padding: 8,
                      border: `1px solid ${BaseColor.primary}`,
                      borderRadius: 5,
                      width: 150,
                    }}
                  >
                    {CHeaderProfileDropdownData?.map((item, index) => {
                      return (
                        <>
                          <ListItemButton
                            style={{
                              marginLeft: -8,
                              fontSize: 14,
                              fontFamily: FontFamily.SemiBold,
                              fontWeight: "900",
                              color: BaseColor.primary,
                            }}
                            key={index}
                            onClick={() => {
                              if (index === 0) {
                                if (userData?.isProfileComplete) {
                                  navigate(`/profile`);
                                } else {
                                  navigate("/create-profile", {
                                    state: { isEditable: true },
                                  });
                                }
                              } else if (index === 1) {
                                if (userData?.isProfileComplete) {
                                  navigate(item.navigation);
                                } else {
                                  setProfileCompleted(true);
                                }
                              } else if (index === 2) {
                                // if (userData?.isProfileComplete) {
                                navigate(item.navigation);
                                // } else {
                                //   setProfileCompleted(true);
                                // }
                              } else if (index === 3) {
                                if (userData?.isProfileComplete) {
                                  navigate("/alert");
                                } else {
                                  setProfileCompleted(true);
                                }
                              } else if (index === 4) {
                                if (location.pathname === "create-profile") {
                                  dispatch(setUserFirstTime(true));
                                }
                                navigate(item.navigation, {
                                  state: { from: "user" },
                                });
                              } else if (index === 6) {
                                if (location.pathname === "create-profile") {
                                  dispatch(setUserFirstTime(true));
                                }
                                navigate(item.navigation);
                              } else if (item?.id == 8) {
                                setAnchorEl(null);
                                setLogOutModal(true);
                              } else if (item?.id == 9) {
                                if (userData.isProfileComplete) {
                                  navigate(item.navigation, {
                                    state: { type: "Admin" },
                                  });
                                } else {
                                  setProfileCompleted(true);
                                }
                              } else if (item?.id == 10) {
                                setChangeLanguageModal(true);
                              } else {
                                toast("Comming soon...", { type: "alert" });
                              }
                            }}
                          >
                            {index === 7 && userData?.is_admin
                              ? "Back to admin"
                              : item.title}
                          </ListItemButton>
                          {CHeaderProfileDropdownData.length - 1 !== index && (
                            <div
                              style={{
                                border: `1px solid ${BaseColor.primary}`,
                                opacity: 0.3,
                              }}
                            />
                          )}
                        </>
                      );
                    })}
                  </Grid>
                </Popover>
              </Grid>
            </Grid>
          ) : (
            <div
              onClick={() => {
                if (loginRequired) {
                  onLoginRequired();
                } else {
                  setDrawerOpen(!drawerOpen);
                }
              }}
            >
              <MenuIcon
                style={{
                  color: BaseColor.whiteColor,
                  // marginRight: md ? 10 : 0,
                }}
              />
            </div>
          )}

          {/* is profile complete modal */}
          <CModal
            visible={profileCompleted}
            onClose={() => setProfileCompleted(false)}
            children={
              <div style={{ padding: 20 }}>
                <img
                  alt={"logo"}
                  src={Files?.images?.colorLogo}
                  style={{ height: 100, width: 300, objectFit: "contain" }}
                />

                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <span
                    style={{
                      fontSize: isMobile ? 18 : 20,
                      color: BaseColor.blackColor,
                      fontFamily: FontFamily.SemiBold,
                      fontWeight: "900",
                    }}
                  >
                    {translate("profile_complete_warning_modal_title")}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CButton
                    onClick={() =>
                      navigate("/create-profile", {
                        state: { isEditable: true },
                      })
                    }
                    style={{ width: "90%" }}
                  >
                    {translate("go_to_profile")}
                  </CButton>
                  <CButton
                    onClick={() => setProfileCompleted(false)}
                    style={{ width: "90%" }}
                  >
                    {translate("cancel_button")}
                  </CButton>
                </div>
              </div>
            }
          />
        </Grid>

        {/* Profile Drawer For Mobile */}
        <Drawer
          anchor={"right"}
          onClose={() => setDrawerOpen(false)}
          open={drawerOpen}
        >
          <div>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon style={{ color: BaseColor.white }} />
            </IconButton>
          </div>

          <Grid item>
            <ListItemButton style={{ color: BaseColor.whiteColor }}>
              <CButton
                variant={"outlined"}
                onClick={(e) => {
                  if (loginRequired) {
                    onLoginRequired();
                  } else {
                    setCreatePost(e.currentTarget);
                  }
                }}
                style={{
                  background: BaseColor.whiteColor,
                  color: BaseColor.primary,
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "center",
                  borderRadius: 5,
                  height: 40,
                  padding: "8px 10px",
                }}
              >
                <div className={classes.postIconContainer}>
                  <BsPlus size={16} color={BaseColor.primary} />
                </div>
                <span className={classes.createPostText}>
                  {translate("create_post_title")}
                </span>
              </CButton>

              <Popover
                onClose={() => setCreatePost(null)}
                anchorEl={createPost}
                id={idCP}
                open={popCP}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                className={classes.createPostPopoover}
              >
                <Grid
                  style={{
                    backgroundColor: BaseColor.whiteColor,
                    padding: 8,
                    border: `1px solid ${BaseColor.primary}`,
                    borderRadius: 5,
                  }}
                >
                  <ListItemButton
                    style={{
                      fontFamily: FontFamily.Medium,
                      color: BaseColor.primary,
                    }}
                    onClick={() => {
                      navigate("/sell-post");
                    }}
                  >
                    {translate("sell_post")}
                  </ListItemButton>
                  <div
                    style={{
                      borderBottom: `1px solid ${BaseColor.primary}`,
                    }}
                  />
                  <ListItemButton
                    style={{
                      fontFamily: FontFamily.Medium,
                      color: BaseColor.primary,
                    }}
                    onClick={() => {
                      navigate("/buy-post");
                    }}
                  >
                    {translate("buy_post")}
                  </ListItemButton>
                </Grid>
              </Popover>
            </ListItemButton>
            <div>
              {drawerList?.map((item, index) => {
                return (
                  <Grid
                    item
                    container
                    key={index}
                    onClick={() => {
                      dispatch(setActivePage(index));
                      if (index === 0) {
                        if (userData?.isProfileComplete) {
                          navigate(`/profile`);
                        } else {
                          navigate("/create-profile", {
                            state: {
                              isEditable: true,
                            },
                          });
                        }
                      } else if (index === 1) {
                        if (userData?.isProfileComplete) {
                          navigate("/my-cart");
                        } else {
                          setProfileCompleted(true);
                        }
                      } else if (index === 2) {
                        if (userData?.isProfileComplete) {
                          navigate("/chat");
                        } else {
                          setProfileCompleted(true);
                        }
                      } else if (index === 3) {
                        if (userData?.isProfileComplete) {
                          setNotificationModal(true);
                        } else {
                          setProfileCompleted(true);
                        }
                      } else if (index === 4) {
                        if (userData?.isProfileComplete) {
                          navigate("/favorites");
                        } else {
                          setProfileCompleted(true);
                        }
                      } else if (index === 5) {
                        // if (userData?.isProfileComplete) {
                        navigate("/myOffers");
                        // } else {
                        //   setProfileCompleted(true);
                        // }
                      } else if (index === 6) {
                        if (userData?.isProfileComplete) {
                          navigate("/alert");
                        } else {
                          setProfileCompleted(true);
                        }
                      } else if (index === 7) {
                        if (userData?.isProfileComplete) {
                          if (location?.pathname === "create-profile") {
                            navigate("/logistic", {
                              state: { from: "user" },
                            });
                            dispatch(setUserFirstTime(false));
                          } else {
                            navigate("/logistic", {
                              state: { from: "user" },
                            });
                          }
                        } else {
                          setProfileCompleted(true);
                        }
                      } else if (index === 8) {
                        if (userData.isProfileComplete) {
                          navigate("/chat", {
                            state: { type: "Admin" },
                          });
                        } else {
                          setProfileCompleted(true);
                        }
                      } else if (index === 9) {
                        if (location?.pathname === "create-profile") {
                          navigate("/contact-us");
                          dispatch(setUserFirstTime(false));
                        } else {
                          navigate("/contact-us");
                        }
                      } else if (index === 10) {
                        setChangeLanguageModal(true);
                      } else if (index === 11) {
                        setLogOutModal(true);
                        setAnchorEl(null);
                      } else {
                        toast("Comming soon...", { type: "alert" });
                      }
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemButton
                      style={{
                        fontFamily: FontFamily.Medium,
                        color:
                          activePage === index
                            ? BaseColor.white
                            : BaseColor.disablePrimary, // Change the colors as desired
                      }}
                    >
                      {item.title}
                    </ListItemButton>
                  </Grid>
                );
              })}
            </div>
          </Grid>
        </Drawer>
        <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />

        <ConfirmModel
          visible={logOutModal}
          handleClose={() => {
            setLogOutModal(false);
          }}
          loader={loader}
          confirmation={() => {
            userData?.is_admin ? backToAdmin() : LogOutApi();
          }}
          message={translate("logout_modal_title")}
        />

        <ChangeLanguageModal
          visible={changeLanguageModal}
          handleClose={() => {
            setChangeLanguageModal(false);
          }}
        />
      </Grid>
      {userData?.is_admin && (
        <Grid
          container
          style={{
            backgroundColor: "yellow",
            padding: "8px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: FontFamily.Medium,
            boxShadow: "0 0 10px 2px rgb(103	65	136 / 10%)",
          }}
        >
          <span style={{ fontSize: sm ? "14px" : "16px" }}>
            You are currently login with admin
          </span>
          <CLink
            title={"Back to admin"}
            onClick={() => setLogOutModal(true)}
            style={{ marginLeft: sm ? "20px" : "30px" }}
          ></CLink>
        </Grid>
      )}
    </Grid>
  );
});
export default CHeader;
