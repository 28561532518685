import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../Config/theme";
import { isMobile } from "react-device-detect";
import BaseColor from "../../Config/Color";

const styles = makeStyles({
  headerText: {
    cursor: "pointer !important",
    fontFamily: `${FontFamily.SemiBold} !important`,
    fontWeight: "900 !important",
    fontSize: isMobile ? "20px !important" : "24px !important",
    "@media (max-width: 900px)": {
      fontSize: "24px !important",
    },
    "@media (max-width: 460px)": {
      fontSize: "20px !important",
    },
    "@media (max-width: 360px)": {
      fontSize: "18px !important",
    },
    "@media (max-width: 340px)": {
      fontSize: "16px !important",
    },
    "&:hover": {
      color: BaseColor.primary,
      transform: "scale(0.95)",
      transition: "0.1s",
    },
  },

  slashTxt: {
    cursor: "pointer !important",
    fontFamily: `${FontFamily.SemiBold} !important`,
    fontWeight: "900 !important",
    fontSize: isMobile ? "20px !important" : "24px !important",
    "@media (max-width: 900px)": {
      fontSize: "24px !important",
    },
    "@media (max-width: 460px)": {
      fontSize: "20px !important",
    },
    "@media (max-width: 360px)": {
      fontSize: "18px !important",
    },
  },
  subContainer: {
    display: "flex",
    // paddingLeft: 5,
    paddingTop: 30,
    paddingBottom: 30,
    "@media (max-width: 1500px)": {
      paddingTop: 20,
      paddingBottom: 20,
    },
    "@media (max-width: 800px)": {
      paddingTop: 15,
      paddingBottom: 15,
    },
  },
});
export default styles;
