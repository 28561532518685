import {
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./styles";
import CDropdown from "../CDropdown";
import theme, { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/Color";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { toast } from "react-toastify";
import _ from "lodash";
import { IoClose } from "react-icons/io5";
import { translate } from "../../lang/Translate";

export default function CFilterModal(props) {
  const {
    isModal,
    onApplyFilterData = () => {},
    onApply = () => {},
    onReset = () => {},
    onlyCategory,
    onlySubCategory,
    onlyCondition,
    onlyCountry,
    filterList = false,
    selectedFilterArr = [],
    categoryId = [],
    subCategoryId = [],
    conditionId = [],
    country = [],
  } = props;

  const classes = styles();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  // category list
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    GetCommonDataApi(true, false, false);
  }, []);

  const GetCommonDataApi = (list, isReset = false, onlySubCatData = false) => {
    const endpoint = BaseSetting.endpoint.commonData;

    let data = {};

    const categoryArr = _.isString(categoryId)
      ? categoryId.split(",")
      : !_.isEmpty(categoryId)
      ? categoryId
      : [];
    if (_.isBoolean(list)) {
      data.parent_ids = categoryArr; // get sub categories by category
    } else if (_.isArray(list)) {
      const finalArr = list;
      const parentIds = finalArr.map((v) => v.id);
      data.parent_ids = parentIds; // get sub categories by category
    }

    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const subCategoryArr = _.isString(subCategoryId)
            ? subCategoryId.split(",")
            : !_.isEmpty(subCategoryId)
            ? subCategoryId
            : [];

          const conditionArr = _.isString(conditionId)
            ? conditionId.split(",")
            : !_.isEmpty(conditionId)
            ? conditionId
            : [];

          const countryArr = _.isString(country)
            ? country.split(",")
            : !_.isEmpty(country)
            ? country
            : [];

          const categoryList = result?.data?.categories;
          const tempSubCategoryList = result?.data?.subCategories;
          const conditionList = result?.data?.conditions;
          const countryList = result?.data?.countries;

          if (!_.isEmpty(tempSubCategoryList)) {
            tempSubCategoryList.map((item) => {
              subCategoryList.map((item1) => {
                if (item1.isSelected && item.name === item1.name) {
                  item.isSelected = true;
                }
              });
            });
          }

          if (!isReset && list) {
            categoryList.map((item, index) => {
              categoryArr.map((item1, index1) => {
                if (item.id == item1) {
                  item.isSelected = true;
                }
              });
            });
            tempSubCategoryList.map((item, index) => {
              subCategoryArr.map((item1, index1) => {
                if (item.id == item1) {
                  item.isSelected = true;
                }
              });
            });

            conditionList.map((item, index) => {
              conditionArr.map((item1, index1) => {
                if (item.id == item1) {
                  item.isSelected = true;
                }
              });
            });
            countryList.map((item, index) => {
              countryArr.map((item1, index1) => {
                if (item.name == item1) {
                  item.isSelected = true;
                }
              });
            });
          }

          const selectedCategory = categoryList.filter((v) => v.isSelected);
          const selectedCategoryArr = selectedCategory.map((v) => {
            v.type = "category";
            return v;
          });
          const selectedSubCategory = tempSubCategoryList.filter(
            (v) => v.isSelected
          );
          const selectedSubCategoryArr = selectedSubCategory.map((v) => {
            v.type = "sub_category";
            return v;
          });
          const selectedCondition = conditionList.filter((v) => v.isSelected);
          const selectedConditionArr = selectedCondition.map((v) => {
            v.type = "condition";
            return v;
          });
          const selectedCountry = countryList.filter((v) => v.isSelected);
          const selectedCountryArr = selectedCountry.map((v) => {
            v.type = "country";
            return v;
          });

          const selectedFilterData = selectedCategoryArr
            .concat(selectedSubCategoryArr)
            .concat(selectedConditionArr)
            .concat(selectedCountryArr);
          onApplyFilterData([...selectedFilterData]);

          if (
            // !_.isEmpty(list) &&
            // _.isArray(list) &&
            onlySubCatData &&
            !isReset
          ) {
            setSubCategoryList([...tempSubCategoryList]);
          } else {
            setSubCategoryList([...tempSubCategoryList]);
            setCategoryList([...categoryList]);
            setConditionList([...conditionList]);
            setCountryList([...countryList]);
          }
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  return filterList ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {selectedFilterArr.map((item, index) => {
        return (
          <div
            id={`selected_filter_${index}`}
            className={classes.labelContainer}
          >
            <span className={classes.labelText}>{item?.name}</span>
            <IconButton
              size="small"
              style={{ padding: 0 }}
              onClick={() => {
                const tempArr = selectedFilterArr;
                if (tempArr.length > 0) {
                  tempArr.splice(index, 1);
                }
                const filterCategories = tempArr.filter(
                  (v) => v.type === "category"
                );
                const categoryIds = filterCategories.map((v) => v.id);
                const filterSubCat = tempArr.filter(
                  (v) => v.type === "sub_category"
                );
                const subCategoryIds = filterSubCat.map((v) => v.id);
                const filterCondition = tempArr.filter(
                  (v) => v.type === "condition"
                );
                const conditionIds = filterCondition.map((v) => v.id);
                const filterCountry = tempArr.filter(
                  (v) => v.type === "country"
                );

                const countryNameArr = filterCountry?.map((v) => v.name);

                onApplyFilterData([...tempArr]);
                onApply(
                  categoryIds,
                  subCategoryIds,
                  conditionIds,
                  countryNameArr
                );
              }}
            >
              <IoClose size={22} color={BaseColor.whiteColor} />
            </IconButton>
          </div>
        );
      })}
    </div>
  ) : (
    <Grid
      container
      // className={classes.scrollBar}
      style={{
        padding: sm ? "15px" : "20px",
        width: "80vw",
        maxHeight: "85vh",
        borderRadius: isModal && 5,
        backgroundColor: xs || sm || md ? BaseColor.offWhite : BaseColor.white,
      }}
    >
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="tableTitle" className={classes.title}>
          {translate("filter_title")}
        </Typography>
        <Grid>
          <Button
            style={{ marginRight: 10, marginLeft: "auto" }}
            variant="contained"
            onClick={() => {
              // Category
              const filterSelectedCategory = categoryList.filter(
                (v) => v.isSelected
              );
              const categoryIds = filterSelectedCategory.map((v) => v.id);
              // SubCategory
              const filterSelectedSubCategory = subCategoryList.filter(
                (v) => v.isSelected
              );
              const subCategoryIds = filterSelectedSubCategory.map((v) => v.id);
              // Condition
              const filterSelectedCondition = conditionList.filter(
                (v) => v.isSelected
              );
              const conditionIds = filterSelectedCondition.map((v) => v.id);
              // Country
              const filterSelectedCountry = countryList.filter(
                (v) => v.isSelected
              );
              const countryNameArr = filterSelectedCountry?.map((v) => v.name);

              // Add type for identification
              const tempCategory = filterSelectedCategory.map((v) => {
                v.type = "category";
                return v;
              });
              const tempSubCategory = filterSelectedSubCategory.map((v) => {
                v.type = "sub_category";
                return v;
              });
              const tempCondition = filterSelectedCondition.map((v) => {
                v.type = "condition";
                return v;
              });
              const tempCountry = filterSelectedCountry.map((v) => {
                v.type = "country";
                return v;
              });

              const tempArr = tempCategory
                .concat(tempSubCategory)
                .concat(tempCondition)
                .concat(tempCountry);
              onApplyFilterData([...tempArr]);
              onApply(
                categoryIds,
                subCategoryIds,
                conditionIds,
                countryNameArr
              );
            }}
          >
            {translate("apply_button")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              GetCommonDataApi([], true);
              onApplyFilterData([]);
              onReset();
            }}
          >
            {translate("reset_button")}
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.scrollBar}
        mt={"20px"}
        gap={3}
        wrap={sm || md ? "wrap" : "nowrap"}
        style={{
          overflowX: "scroll",
          // overflowY: "scroll",
          maxHeight: "400px",
          width: "auto",
          justifyContent: "space-between",
          marginLeft: xs || sm ? -15 : 0,
        }}
      >
        {onlyCategory && (
          <Grid item xs={12} lg={4} minWidth={sm ? "200px" : "300px"}>
            <CDropdown
              label={translate("category")}
              optionList={categoryList}
              onChange={(val) => {}}
              onUpdated={(list) => {
                setCategoryList([...list]);
                const isCategorySelected = list.filter((v) => v.isSelected);
                GetCommonDataApi(isCategorySelected, false, true);
              }}
            />
          </Grid>
        )}

        {onlySubCategory && (
          <Grid item xs={12} lg={4} minWidth={sm ? "200px" : "300px"}>
            <CDropdown
              label={translate("sub_category")}
              optionList={subCategoryList}
              onUpdated={(list) => {
                setSubCategoryList([...list]);
              }}
              noDataTitle={translate(
                categoryList.filter((v) => v.isSelected).length <= 0
                  ? "category_empty_error"
                  : "no_data_found"
              )}
            />
          </Grid>
        )}
        {onlyCondition && (
          <Grid item xs={12} lg={4} minWidth={sm ? "200px" : "300px"}>
            <CDropdown
              label={translate("condition")}
              optionList={conditionList}
              onUpdated={(list) => {
                setConditionList([...list]);
              }}
            />
          </Grid>
        )}
        {onlyCountry && (
          <Grid item xs={12} lg={4} minWidth={sm ? "200px" : "300px"}>
            <CDropdown
              label={translate("country")}
              optionList={countryList}
              onUpdated={(list) => {
                setCountryList([...list]);
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
