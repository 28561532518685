import { Grid, IconButton, Typography } from "@mui/material";
import React, { Component } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/Color";
import _ from "lodash";
import { translate } from "../../lang/Translate";
import CModal from "../CModal";
import { connect } from "react-redux";
import CButton from "../CButton";

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      value: {},
      btnLoader: false,
    };
  }

  open(item) {
    this.setState({
      visible: true,
      value: item,
    });
  }

  close() {
    this.setState({
      visible: false,
      value: "",
      btnLoader: false,
    });
  }

  render() {
    const { descriptionTitle, headerTitle, onRemove = () => {} } = this.props;

    const { visible, value, btnLoader } = this.state;

    return (
      <CModal
        visible={visible}
        onClose={() => {
          this.close();
        }}
        children={
          <Grid
            container
            style={{
              position: "relative",
              padding: 30,
              height: 250,
              width: 280,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              style={{ position: "absolute", top: -6, right: -6 }}
              onClick={() => {
                this.close();
              }}
            >
              <CloseIcon style={{ color: BaseColor.primary }} />
            </IconButton>
            <Typography variant="tableTitle">
              {!_.isEmpty(headerTitle) ? headerTitle : ""}
            </Typography>
            <Typography
              style={{
                textAlign: "center",
                fontSize: 14,
                fontFamily: FontFamily.Medium,
              }}
            >
              {!_.isEmpty(descriptionTitle) ? descriptionTitle : ""}?
            </Typography>
            <CButton
              color="error"
              loading={btnLoader}
              onClick={() => {
                this.setState({ btnLoader: true });
                onRemove(value);
              }}
            >
              {translate(`${headerTitle ? "remove" : "delete"}_button`)}
            </CButton>
          </Grid>
        }
      />
    );
  }
}

export default connect(null, null, null, { forwardRef: true })(DeleteModal);
