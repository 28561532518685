import {
  Button,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/Color";
import CButton from "../CButton";
import CModal from "../CModal";
import { translate } from "../../lang/Translate";

export default function ConfirmModel(props) {
  const {
    message = "",
    visible = false,
    handleClose = () => null,
    confirmation = () => null,
    loader = false,
    title = "",
    titleText = "",
    rightBtnText = translate("confirm_button"),
    leftBtnText = translate("cancel_button"),
  } = props;

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(3px)",
      }}
      open={visible}
      onClose={handleClose}
    >
      <Grid
        item
        xs={10}
        sm={8}
        md={6}
        lg={4}
        justifyContent={"center"}
        style={{
          backgroundColor: "#FFF",
          borderRadius: 12,
          padding: isMobile ? 20 : 30,
        }}
      >
        <Grid item>
          {titleText && (
            <Typography
              style={{
                color: BaseColor.blackColor,
                fontFamily: FontFamily.Regular,
                fontSize: "18px",
                letterSpacing: "0.5px",
                lineHeight: "24px",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              {titleText}
            </Typography>
          )}
          <Typography
            style={{
              color: BaseColor.textColor,
              fontFamily: FontFamily.Medium,
              fontSize: "18px",
              letterSpacing: "0.5px",
              lineHeight: "24px",
              textAlign: "center",
              marginBottom: "24px",
            }}
          >
            {message}
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent={"center"}
          gap={isMobile ? 1 : 2}
          wrap="nowrap"
          marginTop={"10px"}
        >
          <Grid item xs={6}>
            <CButton variant="outlined" onClick={handleClose}>
              {leftBtnText}
            </CButton>
          </Grid>
          <Grid item xs={6}>
            <CButton
              loading={loader}
              variant="contained"
              onClick={confirmation}
            >
              {rightBtnText}
            </CButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
