import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import BaseColor from "../../Config/Color";
import CloseIcon from "@mui/icons-material/Close";
import Files from "../../Config/Files";
import theme, { FontFamily } from "../../Config/theme";
import { getOS } from "../../Utils/commonFunctions";

const CDownloadApp = (props) => {
  const { onClose = () => {} } = props;

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      style={{
        width: "100%",
        backgroundColor: BaseColor.primary,
        padding: "10px",
        zIndex: 10,
      }}
    >
      <Grid
        xs={4}
        sm={3}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={1.5}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CloseIcon
              style={{
                color: BaseColor.white,
                fontSize: sm ? "25px" : "25px",
              }}
              onClick={() => onClose()}
            />
          </Grid>
          <Grid
            item
            xs={9}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt={"scrapc"}
              src={Files.images.logo}
              style={{
                width: "100%",
                objectFit: "contain",
                marginLeft: "15px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={8}
        sm={9}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          item
          xs={8}
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: sm ? "15px" : "25px",
            marginRight: "5px",
          }}
        >
          <Typography
            style={{
              color: BaseColor.white,
              fontFamily: FontFamily.Medium,
              fontSize: sm ? "12px" : "18px",
            }}
          >
            Download App
          </Typography>
          <Typography
            style={{
              color: BaseColor.white,
              fontFamily: FontFamily.Regular,
              fontSize: sm ? "10px" : "13px",
            }}
          >
            Download the app for a better experience in the ScrapC!
          </Typography>
        </div>
        <Button
          variant="outlined"
          style={{ height: "30px", marginRight: "5px" }}
          onClick={() => {
            const system_os = getOS();
            const appLink = ["iPhone", "iPod", "iPad"].includes(system_os)
              ? "https://apps.apple.com/in/app/scrapc/id6453885238"
              : "https://play.google.com/store/apps/details?id=com.scrapc";
            window.location.replace(appLink);
          }}
        >
          Install
        </Button>
      </Grid>
    </Grid>
  );
};

export default CDownloadApp;
