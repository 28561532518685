import { useGoogleLogin } from "@react-oauth/google";
import BaseColor from "../../Config/Color";
import Files from "../../Config/Files";
import { FontFamily } from "../../Config/theme";
import CButton from "../CButton";
import CIcon from "../CIcon";
import _ from "lodash";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import authActions from "../../Redux/Reducers/auth/actions";
import { translate } from "../../lang/Translate";

const CGoogle = (props) => {
  const {
    loginPage = false,
    isModal = false,
    onLoginSuccess = () => {},
    onSuccess = () => {},
  } = props;
  const navigate = useNavigate();

  // Set data in Redux
  const dispatch = useDispatch();
  const { setAccessToken, setUserData, setUserFirstTime } = authActions;

  // Get data from Redux
  const { UUID } = useSelector((state) => state.auth);
  const [loginBtnLoader, setLoginBtnLoader] = useState(false);

  const login = useGoogleLogin({
    // flow:'auth-code',
    onSuccess: (tokenResponse) => {
      const token = tokenResponse?.access_token;
      SocialLoginApiCall(token);
    },
  });

  const SocialLoginApiCall = (token) => {
    // loading true
    setLoginBtnLoader(true);

    const data = {
      provider_id: token,
      uuid: UUID,
      type: "google",
      plateform_type: "web",
    };
    const endPoint = BaseSetting.endpoint.socialLogin;

    const headers = {
      "Content-Type": "application/json",
    };
    getApiData(endPoint, "post", data, headers)
      .then((result) => {
        if (result.status) {
          const resultUserData =
            !_.isEmpty(result?.data?.userData) &&
            !_.isUndefined(result?.data?.userData) &&
            !_.isNull(result?.data?.userData)
              ? result?.data?.userData
              : {};
          const token =
            !_.isEmpty(result?.data?.token) &&
            !_.isUndefined(result?.data?.token) &&
            !_.isNull(result?.data?.token)
              ? result?.data?.token
              : "";
          dispatch(setAccessToken(token));
          dispatch(setUserData(resultUserData));
          onLoginSuccess(result.status);
          if (result?.data?.userData?.isProfileComplete) {
            if (!isModal) {
              navigate("/products");
            } else {
              onSuccess(result.status);
            }
          } else {
            if (
              _.isEmpty(resultUserData?.business_info?.country) ||
              _.isNull(resultUserData?.business_info?.country) ||
              _.isUndefined(resultUserData?.business_info?.country) ||
              _.isEmpty(resultUserData?.business_info?.name) ||
              _.isUndefined(resultUserData?.business_info?.name) ||
              _.isNull(resultUserData?.business_info?.name)
            ) {
              dispatch(setUserFirstTime(true));
            }
            // navigate("/create-profile", {
            //   state: {
            //     isEditable: true,
            //   },
            // });
          }
          setLoginBtnLoader(false);
        } else {
          toast(result?.message, { type: "error" });
          setLoginBtnLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setLoginBtnLoader(false);
      });
  };

  return (
    <CButton
      variant="outlined"
      style={{
        width: "100%",
      }}
      loading={loginBtnLoader}
      onClick={login}
    >
      <CIcon src={Files.svgIcons.google} size={22} />
      <span
        style={{
          marginLeft: 15,
          color: BaseColor.textColor,
          fontFamily: FontFamily.SemiBold,
          fontWeight: "900",
        }}
      >
        {translate(`${loginPage ? "login" : "sign_in"}_with_google`)}
      </span>
    </CButton>
  );
};
export default CGoogle;
