import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../../Config/theme";

const styles = makeStyles({
  title: {
    fontFamily: FontFamily.SemiBold,
    fontWeight: "900",
    fontSize: "20px",
  },
});

export default styles;
