export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
}

export function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function getDatafromStr(str = "") {
  const strArray = str.split("; ");
  let stringsArray = [];
  strArray.map((item) => {
    const string1 = item.split("=");
    stringsArray = [...stringsArray, string1];
  });
  return Object.fromEntries(stringsArray);
}

export function getSlugWithoutSlace(string) {
  if (string !== "") {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace("&", "and")
      .replace(/[&_\/\\#,+()$~%.'":*?<>{}]/g, "-")
      .replace(/\s+/g, "_")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }
}

export const getOS = () => {
  const user_agent = navigator.userAgent;

  let os_platform = "Unknown Operating System";
  const os_array = {
    "windows nt 10": "Windows 10",
    "windows nt 6.3": "Windows 8.1",
    "windows nt 6.2": "Windows 8",
    "windows nt 6.1": "Windows 7",
    "windows nt 6.0": "Windows Vista",
    "windows nt 5.2": "Windows Server 2003/XP x64",
    "windows nt 5.1": "Windows XP",
    "windows xp": "Windows XP",
    "windows nt 5.0": "Windows 2000",
    "windows me": "Windows ME",
    win98: "Windows 98",
    win95: "Windows 95",
    win16: "Windows 3.11",
    "macintosh|mac os x": "Mac OS X",
    mac_powerpc: "Mac OS 9",
    linux: "Linux",
    ubuntu: "Ubuntu",
    iphone: "iPhone",
    ipod: "iPod",
    ipad: "iPad",
    android: "Android",
    blackberry: "BlackBerry",
    webos: "Mobile",
  };

  for (const regex in os_array) {
    if (user_agent.match(new RegExp(regex, "i"))) {
      os_platform = os_array[regex];
    }
  }
  return os_platform;
};

export function replaceSpacesAndSpecialChars(inputString) {
  // Remove spaces and special characters using a regular expression
  const modifiedString = inputString
    .replace(/[^\w-]/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-|-$/g, "") // Remove hyphens from start and end
    .toLowerCase();

  return modifiedString;
}

export function EncDctFn(params, type) {
  try {
    if (params && type === "encrypt") {
      const encodedString = window.btoa(params);
      return encodedString;
    }
    if (params && type === "decrypt") {
      const decodedString = window.atob(params);
      return decodedString;
    }
  } catch (error) {
    console.log("-------------------------------");
    console.log("🚀 EncDctFn ~ params:", params);
    console.log("🚀 EncDctFn ~ type:", type);
    console.log("🚀  file: EctDctFn.js:30  EncDctFn ~ error:", error);
    return "";
  }
}
