import React, { useRef } from "react";
import styles from "./styles";
import CHeader from "../../Components/CHeader";
import CFooter from "../../Components/CFooter";
import CPageHeader from "../../Components/CPageHeader";
import { translate } from "../../lang/Translate";

const SiteMap = () => {
  const classes = styles();

  // ? Reference
  const footerRef = useRef();

  return (
    <div className={classes.root}>
      <CHeader
        onLoginRequired={() => {
          footerRef?.current?.LoginModalOpen(true);
        }}
      />
      <CPageHeader
        title={translate("home_title")}
        titleNav="/products"
        subTitle={translate("sitemap_title")}
      />

      <CFooter ref={footerRef} siteMap={true} />
    </div>
  );
};

export default SiteMap;
