import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import styles from "./styles";
import _, { isArray, isEmpty } from "lodash";
import NoData from "../NoData";
import CInput from "../CInput";
import { translate } from "../../lang/Translate";

export default function CDropdown(props) {
  const classes = styles();

  const {
    label = "",
    optionList = [],
    onChange = () => {},
    onUpdated = () => {},
    noDataTitle = "",
  } = props;

  useEffect(() => {
    setOptionArr(optionList);
  }, [optionList]);

  const [optionArr, setOptionArr] = useState([]);
  const [searchText, setSearchText] = useState("");

  const search = (val) => {
    setSearchText(val);
    const value = val.toLowerCase();
    const data = val
      ? optionList.filter((item) => item?.name.toLowerCase().includes(value))
      : optionArr;
    setOptionArr(data);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={10} mb={"20px"}>
        <Typography className={classes.title}>{label}</Typography>
      </Grid>
      <CInput
        placeholder={translate("search_placeholder")}
        value={searchText}
        onChange={search}
        startIcon={
          <div style={{ lineHeight: 0 }}>
            <BiSearch style={{ fontSize: 20 }} />
          </div>
        }
      />
      <Grid
        item
        xs={12}
        className={classes.scrollBar}
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
          overflowY: "scroll",
          maxHeight: "200px",
        }}
      >
        {isArray(optionArr) && !isEmpty(optionArr) ? (
          optionArr.map((item, index) => {
            const isSelected =
              item?.isSelected === true ? item.isSelected : false;
            return (
              <div style={{ padding: 5, marginLeft: 5 }}>
                <FormControlLabel
                  label={item?.name}
                  checked={isSelected}
                  control={
                    <Checkbox
                      checked={isSelected}
                      onChange={() => {
                        if (!item.isSelected) {
                          item.isSelected = true;
                        } else {
                          item.isSelected = false;
                        }
                        onUpdated(optionList);
                        // onChange(item);
                      }}
                      style={{ marginRight: 5 }}
                    />
                  }
                />
              </div>
            );
          })
        ) : (
          <NoData title={noDataTitle} />
        )}
      </Grid>
    </Grid>
  );
}
