import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/Color";
import { FontFamily } from "../../Config/theme";

const styles = makeStyles({
  listContainer: {
    padding: 8,
    cursor: "pointer",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    // background: "pink",
  },
  scrollBar: {
    display: "flex",

    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 5,
      height: 5,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
  imageContainer: {
    width: 35,
    height: 35,
    borderRadius: "80%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    overflow: "hidden",
  },
  profileImg: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  indicator: {
    height: 10,
    width: 10,
    backgroundColor: BaseColor.primary,
    borderRadius: 50,
  },
  subTitleContainer: {
    color: BaseColor.textColor,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    opacity: 0.5,
    marginTop: 5,
    whiteSpace: "nowrap",
    width: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleContainer: {
    color: BaseColor.primary,
    fontSize: 16,
    fontFamily: FontFamily.SemiBold,
    fontWeight: "900",
    marginTop: 5,
    whiteSpace: "nowrap",
    width: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  timeText: {
    color: BaseColor.textColor,

    opacity: 0.5,
    marginTop: 5,
    fontFamily: FontFamily.RobotoRegular,
    textAlign: "end",
  },
});

export default styles;
