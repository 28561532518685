import React, { useEffect, useState } from "react";
import CHeader from "../../../Components/CHeader";
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import styles from "./styles";
import BaseColor from "../../../Config/Color";
import CInput from "../../../Components/CInput";
import CButton from "../../../Components/CButton";
import CFooter from "../../../Components/CFooter";
import CImageUpload from "../../../Components/CImageUpload";
import { getApiData, getAxiosApi } from "../../../Apis/apiHelper";
import BaseSetting from "../../../Apis/setting";
import { toast } from "react-toastify";
import CSelect from "../../../Components/CSelect";
import _ from "lodash";
import CPageHeader from "../../../Components/CPageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import theme, { FontFamily } from "../../../Config/theme";
import CAutoComplete from "../../../Components/CAutoComplete";
import CloseIcon from "@mui/icons-material/Close";
import Files from "../../../Config/Files";
import { translate } from "../../../lang/Translate";

const EditBuyPost = () => {
  const classes = styles();
  const navigate = useNavigate();
  const location = useLocation();

  const isUpdate = location?.state?.update;
  const postId = location?.state?.postId;

  //media queries
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [condition, setCondition] = useState("");
  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [postStatus, setPostStatus] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [loader, setLoader] = useState(false);
  const [country, setCountry] = useState("");
  const [close, setClose] = useState(true);
  const [postBtnLoader, setPostBtnLoader] = useState(false);
  const [draftBtnLoader, setDraftBtnLoader] = useState(false);

  const [thumbnail, setThumbnail] = useState({});

  //form validation
  const [categoryErrorText, setCategoryErrorText] = useState("");
  const [subCategoryErrorText, setSubCategoryErrorText] = useState("");
  const [conditionErrorText, setConditionErrorText] = useState("");
  const [productNameErrorText, setProductNameErrorText] = useState("");
  const [productDescErrorText, setProductDescErrorText] = useState("");
  const [countryErrorText, setCountryErrorText] = useState("");

  //image upload
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const [removeVideo, setRemoveVideo] = useState([0]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [allSubcategoryList, setAllSubCategoryList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [saveAsDraftDisable, setSaveAsDraftDisable] = useState(false);

  const [pageLoad, setPageLoad] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    GetCategoryListApi();
    GetConditionListApi();
    GetCountryListApi();
    isUpdate && GetSubCategoryListApi();
  }, []);

  useEffect(() => {
    if (removeVideo !== 0) {
      setVideo([]);
    }
  }, [removeVideo]);

  useEffect(() => {
    const isSuccess =
      !_.isEmpty(categoryList) &&
      _.isArray(categoryList) &&
      !_.isEmpty(conditionList) &&
      _.isArray(conditionList) &&
      !_.isEmpty(countryList) &&
      _.isArray(countryList);

    if (isSuccess) {
      if (
        isUpdate &&
        !_.isEmpty(allSubcategoryList) &&
        _.isArray(allSubcategoryList)
      ) {
        getOnePostData();
      } else {
        setPageLoad(false);
      }
    }
  }, [countryList, categoryList, conditionList, allSubcategoryList]);

  const GetCategoryListApi = () => {
    const endpoint = BaseSetting.endpoint.categoryList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };
  const GetSubCategoryListApi = () => {
    const endpoint = BaseSetting.endpoint.subCategoryList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          const data = result?.data;
          setAllSubCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };
  const GetSubCategoryListByCategoryApi = (parentId) => {
    const endpoint = BaseSetting.endpoint.subCategoryByParentIdList;
    const data = {
      parent_id: parentId,
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setSubCategoryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };
  const GetCountryListApi = () => {
    const endpoint = BaseSetting.endpoint.countryList;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCountryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };
  const GetConditionListApi = () => {
    const endpoint = BaseSetting.endpoint.conditionList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setConditionList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const allErrorFalse = () => {
    setCategoryErrorText("");
    setSubCategoryErrorText("");
    setConditionErrorText("");
    setCountryErrorText("");
    setProductNameErrorText("");
    setProductDescErrorText("");
  };
  const createPostValidation = (type) => {
    if (_.isEmpty(category)) {
      allErrorFalse();
      setCategoryErrorText(translate("category_empty_error"));
    } else if (_.isEmpty(subCategory)) {
      allErrorFalse();
      setSubCategoryErrorText(translate("sub_category_empty_error"));
    } else if (_.isEmpty(condition)) {
      allErrorFalse();
      setConditionErrorText(translate("condition_empty_error"));
    } else if (_.isEmpty(country)) {
      allErrorFalse();
      setCountryErrorText(translate("country_empty_error"));
    } else if (productName.trim() === "") {
      allErrorFalse();
      setProductNameErrorText(translate("product_name_empty_error"));
    } else if (!Files?.regex?.descriptionRegex?.test(String(productName))) {
      allErrorFalse();
      setProductNameErrorText(translate("number_character_not_allow_error"));
    } else if (productDesc.trim() === "") {
      allErrorFalse();
      setProductDescErrorText(translate("product_description_not_valid_error"));
    } else {
      allErrorFalse();
      UpdatePostApi(type);
    }
  };

  const appendLiveImageInArray = (liveImages, localImages, id) => {
    let imageArr = localImages;
    const liveImageLength = liveImages?.length;
    let indexArr = [];
    for (let i = 0; i < liveImageLength; i++) {
      indexArr.push(i);
    }
    if (liveImageLength > 0) {
      imageArr?.splice(indexArr[0], 1, liveImages[indexArr[0]]);
      const findLocalImage = imageArr?.filter(
        (v) => _.isUndefined(v.id) || _.isUndefined(v.url)
      );
      const localImagesLength = findLocalImage.length;
      if (localImagesLength > 0) {
        setImages([...imageArr]);
        SingleImageUploadApi(findLocalImage, id);
      } else {
        setImages([...liveImages]);
      }
    }
  };

  const ChangeMainImageApi = (image_id, post_id) => {
    const endpoint = BaseSetting.endpoint.changeMainImage;
    const data = { image_id: image_id, post_id: post_id };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          getOnePostData(post_id);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };
  const UpdatePostApi = (type) => {
    const endpoint = BaseSetting.endpoint.updatePost;
    const data = {
      post_id: postId,
      post_type: "Buy",
      api_type: "web",
      type: type,
      category_id: category?.id,
      sub_category_id: subCategory?.id,
      condition_id: condition?.id,
      name: productName,
      description: productDesc,
      country: country?.name,
      country_code: country.country_code,
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          if (type === "post") {
            navigate("/myOffers");
            setPostBtnLoader(false);
          } else {
            navigate("/myOffers");
            setDraftBtnLoader(false);
          }
          toast(result?.message, { type: "success" });
        } else {
          if (type === "post") {
            setPostBtnLoader(false);
          } else {
            setDraftBtnLoader(false);
          }
          toast(result?.message, { type: "error" });
        }
        if (type === "post") {
          setPostBtnLoader(false);
        } else {
          setDraftBtnLoader(false);
        }
      })
      .catch((err) => {
        if (type === "post") {
          setPostBtnLoader(false);
        } else {
          setDraftBtnLoader(false);
        }
        toast(err?.message, { type: "error" });
      });
  };
  const SingleImageUploadApi = (image, id) => {
    const form = new FormData();
    form.append("post_image", image[0]);
    const type = !_.isUndefined(image[0]?.type)
      ? image[0]?.type?.split("/")
      : [];
    const fileType = !_.isEmpty(type) && _.isArray(type) ? type[0] : "";

    form.append("file_type", fileType);
    form.append("type", "Buy");
    if (id && !_.isUndefined(id) && !_.isNull(id)) {
      form.append("post_id", id);
    }

    const endpoint = BaseSetting.endpoint.singlePostImageUpload;
    getAxiosApi(endpoint, "post", form)
      .then((result) => {
        if (result?.status) {
          if (result?.data?.type === "image_courupt") {
            toast(result?.message, { type: "error" });
            if (
              result?.data?.id &&
              !_.isUndefined(result?.data?.id) &&
              !_.isNull(result?.data?.id)
            ) {
              getOnePostData(image, fileType);
            } else {
              setImages([]);
            }
          } else {
            getOnePostData(image, fileType);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  //sold status
  async function ChangeSoldStatus() {
    setLoader(true);
    try {
      const data = {
        post_id: postId,
      };
      const response = await getApiData(
        BaseSetting.endpoint.postSold,
        "POST",
        data
      );
      if (response.status) {
        toast(response.message, { type: "success" });
        setLoader(false);
        getOnePostData();
      } else {
        toast(response?.message, { type: "error" });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  }

  // // get one post data
  const getOnePostData = (localImages, fileType) => {
    const endpoint = BaseSetting.endpoint.getOnePostData;
    const data = { id: postId, type: "Buy" };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          if (
            !_.isEmpty(localImages) &&
            !_.isUndefined(localImages) &&
            _.isArray(localImages) &&
            fileType != "video"
          ) {
            appendLiveImageInArray(
              data?.postImages?.images,
              localImages,
              postId
            );
          } else {
            setData(data);
          }
        } else {
          toast(result.message, { type: "error" });
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
        setPageLoad(false);
      });
  };

  const setData = (data) => {
    const postData =
      !_.isEmpty(data.postData) &&
      !_.isNull(data.postData) &&
      !_.isUndefined(data.postData)
        ? data.postData
        : {};

    const postImages =
      !_.isEmpty(data.postImages) &&
      !_.isNull(data.postImages) &&
      !_.isUndefined(data.postImages)
        ? data.postImages
        : {};

    const imagesData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.images) &&
      !_.isEmpty(postImages?.images) &&
      _.isArray(postImages?.images)
        ? postImages?.images
        : [];

    setImages([...imagesData]);
    const videoData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.video) &&
      !_.isEmpty(postImages?.video) &&
      _.isArray(postImages?.video)
        ? postImages?.video.find((v) => v.type === "video")
        : [];
    setVideo({ ...videoData });

    const thumbnailData =
      !_.isEmpty(postImages) &&
      !_.isNull(postImages?.video) &&
      !_.isEmpty(postImages?.video) &&
      _.isArray(postImages?.video)
        ? postImages?.video.find((v) => v.type === "thumbnail")
        : [];
    setThumbnail({ ...thumbnailData });

    const categoryValue =
      !_.isNull(postData.category_id) && !_.isUndefined(postData.category_id)
        ? categoryList.find((v) => v.id === postData.category_id)
        : "";

    GetSubCategoryListByCategoryApi(postData?.category_id);

    const subCategoryValue =
      !_.isNull(postData.sub_category_id) &&
      !_.isUndefined(postData.sub_category_id)
        ? allSubcategoryList.find((v) => v.id === postData.sub_category_id)
        : "";

    const conditionValue =
      !_.isNull(postData.condition_id) && !_.isUndefined(postData.condition_id)
        ? conditionList.find((v) => v.id === postData.condition_id)
        : "";

    const productNameValue =
      !_.isEmpty(postData.name) &&
      !_.isNull(postData.name) &&
      !_.isUndefined(postData.name)
        ? postData.name
        : "";

    const productDescValue =
      !_.isEmpty(postData.description) &&
      !_.isNull(postData.description) &&
      !_.isUndefined(postData.description)
        ? postData.description
        : "";
    const reason =
      !_.isEmpty(postData.reject_reason) &&
      !_.isNull(postData.reject_reason) &&
      !_.isUndefined(postData.reject_reason)
        ? postData.reject_reason
        : "";

    const countryValue =
      !_.isNull(postData.country_code) && !_.isUndefined(postData.country_code)
        ? countryList.find((v) => v.country_code === postData.country_code)
        : "";

    const disable = postData?.status === "draft" ? false : true;
    const postStatus = postData?.status;

    setCategory(categoryValue);
    setSubCategory(subCategoryValue);
    setCondition(conditionValue);
    setProductName(productNameValue);
    setProductDesc(productDescValue);
    setCountry(countryValue);
    setSaveAsDraftDisable(disable);
    setPostStatus(postStatus);
    setPageLoad(false);
    setRejectReason(reason);
  };

  const mainImage =
    !_.isEmpty(images) && _.isArray(images)
      ? images.filter((v) => v?.main_image == 1)
      : null;

  return (
    <div style={{ background: BaseColor.whiteColor }}>
      <CHeader />
      <Grid container alignItems="center" justifyContent="center" spacing={0}>
        <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
        <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={9}
              md={9}
              lg={7.8}
              xl={7.6}
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <CPageHeader
                title={translate("home_title")}
                titleNav="/products"
                subTitle={translate("edit_post_title")}
                subSubTitle={translate("buy_post_header_title")}
                isSpaceRequired={false}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={4.2}
              xl={4.4}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            ></Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
      </Grid>

      {postStatus === "rejected" && close && (
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          columnSpacing={sm ? 0 : 4}
        >
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}></Grid>
          <Grid
            item
            xs={12}
            sm={11}
            md={4.2}
            lg={3.75}
            xl={3.8}
            alignItems="center"
            justifyContent="flex-end"
            padding={sm ? 2 : 0}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: BaseColor.offWhite,
                borderRadius: 10,
                flexDirection: "row",
                display: "flex",
                justifyContent: md ? "flex-end" : "",
              }}
            >
              <div
                style={{
                  width: "20%",
                  backgroundColor: "red",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                }}
              >
                <div
                  style={{
                    border: "2px solid ",
                    borderColor: BaseColor.offWhite,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CloseIcon
                    style={{ color: BaseColor.offWhite, fontSize: 30 }}
                  />
                </div>
              </div>
              <div style={{ width: "60%", padding: 7, flexWrap: "wrap" }}>
                <Typography
                  style={{
                    fontFamily: FontFamily.Bold,
                    fontWeight: "900",
                    fontSize: 14,
                    color: BaseColor.blackColor,
                  }}
                >
                  {translate("rejected")}
                </Typography>
                <Typography
                  style={{
                    fontFamily: FontFamily.Medium,
                    fontSize: 12,
                    color: BaseColor.blackColor,
                  }}
                >
                  {rejectReason && rejectReason.length > 150
                    ? rejectReason.substring(0, 150) + "..."
                    : rejectReason}
                </Typography>
              </div>
              <div
                style={{
                  width: "20%",
                  borderLeft: "1px solid ",
                  borderColor: BaseColor.white,
                  // height: 60,
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor:'blue'
                }}
                onClick={() => {
                  setClose(false);
                }}
              >
                <Typography
                  style={{
                    fontFamily: FontFamily.Medium,
                    fontSize: sm ? 12 : 14,
                    color: BaseColor.textGray,
                  }}
                >
                  {translate("close")}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      {pageLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "50vh",
          }}
        >
          <CircularProgress style={{ color: BaseColor.primary }} size={30} />
        </div>
      ) : (
        <>
          <Grid
            container
            justifyContent={"center"}
            style={{ marginBottom: "5%" }}
          >
            <Grid container xs={0.5} sm={0.5} md={0.5} lg={1} xl={1}></Grid>

            <Grid item xs={11} sm={11} md={11} lg={10} xl={10} marginTop={2}>
              <Grid container>
                <Grid item xs={12} lg={7}>
                  <div>
                    <Typography
                      mb={2}
                      style={{
                        fontFamily: FontFamily.SemiBold,
                        fontWeight: "900",
                        fontSize: sm ? 18 : 20,
                        color: BaseColor.primary,
                      }}
                    >
                      {translate("product_details_title")}
                    </Typography>
                    <Grid
                      container
                      sx={{
                        backgroundColor: BaseColor.offWhite,
                        padding: "15px",
                        borderRadius: "5px",
                        justifyContent: "space-between",
                        marginTop: 3,
                      }}
                      // gap={2}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={5.79}
                        md={5.8}
                        style={{ marginBottom: 15 }}
                      >
                        <CAutoComplete
                          selectedValue={category}
                          label={translate("category")}
                          options={categoryList}
                          onSelect={(val) => {
                            setCategory(val);
                            GetSubCategoryListByCategoryApi(val.id);
                            setSubCategory({});
                            setCategoryErrorText("");
                          }}
                          errorMsg={categoryErrorText}
                          popOverStyle={{
                            width: sm ? "87%" : lg ? "44%" : "22.5%",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={5.79}
                        md={5.8}
                        style={{ marginBottom: 15 }}
                      >
                        <CAutoComplete
                          selectedValue={subCategory}
                          label={translate("sub_category")}
                          options={subCategoryList}
                          onSelect={(val) => {
                            setSubCategory(val);
                            setSubCategoryErrorText("");
                          }}
                          noCategory={_.isEmpty(category)}
                          errorMsg={subCategoryErrorText}
                          popOverStyle={{
                            width: sm ? "87%" : lg ? "44%" : "22.5%",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={5.79}
                        md={5.8}
                        style={{ marginBottom: 15 }}
                      >
                        <CAutoComplete
                          selectedValue={condition}
                          label={translate("condition")}
                          options={conditionList}
                          onSelect={(val) => {
                            setCondition(val);
                            setConditionErrorText("");
                          }}
                          errorMsg={conditionErrorText}
                          popOverStyle={{
                            width: sm ? "87%" : lg ? "44%" : "22.5%",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={5.79}
                        md={5.8}
                        style={{ marginBottom: 15 }}
                      >
                        <CAutoComplete
                          selectedValue={country}
                          label={translate("country")}
                          options={countryList}
                          onSelect={(val) => {
                            setCountry(val);
                            setCountryErrorText("");
                          }}
                          errorMsg={countryErrorText}
                          popOverStyle={{
                            width: sm ? "87%" : lg ? "44%" : "22.5%",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={5.79}
                        md={5.8}
                        style={{ marginBottom: 15 }}
                      >
                        <CInput
                          placeholder={translate("product_name")}
                          value={productName}
                          onChange={(val) => {
                            setProductName(val);
                            setProductNameErrorText("");
                          }}
                          errorMsg={productNameErrorText}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CInput
                          placeholder={translate("product_description")}
                          value={productDesc}
                          onChange={(val) => {
                            setProductDesc(val);
                            setProductDescErrorText("");
                          }}
                          multiline
                          rows={6}
                          errorMsg={productDescErrorText}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  {!lg && (
                    <Grid container style={{ margin: "10px 0px 10px 0px" }}>
                      <Grid item xs={5.75}>
                        <CButton
                          variant="contained"
                          style={{ marginTop: "20px" }}
                          onClick={() => {
                            createPostValidation("post");
                          }}
                          loading={postBtnLoader}
                        >
                          {translate("post_button")}
                        </CButton>
                      </Grid>
                      <Grid item xs={0.5}></Grid>
                      <Grid item xs={5.75}>
                        <CButton
                          variant="outlined"
                          style={{ marginTop: "20px" }}
                          onClick={() => {
                            createPostValidation("draft");
                          }}
                          loading={draftBtnLoader}
                          disabled={
                            postBtnLoader ? postBtnLoader : saveAsDraftDisable
                          }
                        >
                          {translate("save_as_draft_button")}
                        </CButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {/* Images and videos part */}
                <Grid
                  item
                  xs={12}
                  lg={5}
                  paddingLeft={lg ? 0 : 10}
                  marginTop={lg ? (sm ? 5 : 10) : 0}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          fontFamily: FontFamily.SemiBold,
                          fontWeight: "900",
                          fontSize: sm ? 18 : 20,
                          color: BaseColor.primary,
                        }}
                      >
                        {translate("images_and_videos")}
                        <span
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontWeight: "900",
                            marginLeft: 10,
                            fontSize: 12,
                            color: BaseColor.textGray,
                          }}
                        >
                          ({translate("optional")})
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{
                        backgroundColor: BaseColor.offWhite,
                        borderRadius: "5px",
                        padding: "20px",
                      }}
                      marginTop={3}
                      rowGap={2}
                    >
                      <Grid item xs={12}>
                        {!_.isEmpty(mainImage) && (
                          <div
                            style={{
                              marginBottom: 30,
                            }}
                          >
                            <CImageUpload file={mainImage} hideRemoveBtn />
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              color: BaseColor.textColor,
                              fontFamily: FontFamily.SemiBold,
                              fontWeight: "900",
                            }}
                          >
                            {translate("more_images")}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 12,
                              marginLeft: 8,
                              color: BaseColor.textGray,
                              fontFamily: FontFamily.SemiBold,
                              fontWeight: "900",
                            }}
                          >
                            ({translate("post_image_upload_supported_format")})
                          </Typography>
                        </div>
                        <Typography
                          style={{
                            fontFamily: FontFamily.RobotoMedium,
                            fontSize: "12px",
                            color: BaseColor.red,
                            marginBottom: 10,
                          }}
                        >
                          {translate("image_upload_note")}
                        </Typography>
                        <CImageUpload
                          multiple
                          file={images}
                          onChange={(val) => {
                            setImages([...val]);
                          }}
                          onChangeMainImage={(id) => {
                            ChangeMainImageApi(id, postId);
                          }}
                          onUploadImage={(val) => {
                            const findLocalImage = val?.filter(
                              (v) => _.isUndefined(v.id) || _.isUndefined(v.url)
                            );
                            SingleImageUploadApi(findLocalImage, postId);
                          }}
                          onRemoveImageSuccess={(bool) => {
                            if (bool) {
                              getOnePostData(postId);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h5"
                            style={{
                              color: BaseColor.textColor,
                              fontFamily: FontFamily.SemiBold,
                              fontWeight: "900",
                            }}
                          >
                            {translate("video")}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 12,
                              marginLeft: 8,
                              color: BaseColor.textGray,
                              fontFamily: FontFamily.SemiBold,
                              fontWeight: "900",
                            }}
                          >
                            ({translate("post_video_upload_supported_format")})
                          </Typography>
                        </div>
                        <Typography
                          style={{
                            fontFamily: FontFamily.RobotoMedium,
                            fontSize: "12px",
                            color: BaseColor.red,
                            marginBottom: 10,
                          }}
                        >
                          {translate("video_upload_note")}
                        </Typography>

                        <CImageUpload
                          video
                          file={video}
                          thumbnail={thumbnail}
                          onChange={(val) => {
                            setVideo([...val]);
                          }}
                          onUploadImage={(val) => {
                            const findLocalImage = val?.filter(
                              (v) => _.isUndefined(v.id) || _.isUndefined(v.url)
                            );
                            SingleImageUploadApi(findLocalImage, postId);
                          }}
                          onRemove={() => {
                            setRemoveVideo([]);
                          }}
                          onRemoveImageSuccess={(bool) => {
                            if (bool) {
                              getOnePostData(bool);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={0.5} sm={0.5} md={0.5} lg={1} xl={1}></Grid>
          </Grid>
          {lg && (
            <Grid
              container
              style={{
                margin: "10px 0px",
              }}
            >
              <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} />
              <Grid item xs={11} sm={11} md={11} lg={5.8}>
                <Grid container>
                  <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                    <CButton
                      variant="contained"
                      style={{ marginTop: "20px" }}
                      onClick={() => {
                        createPostValidation("post");
                      }}
                      loading={postBtnLoader}
                    >
                      {translate("post_button")}
                    </CButton>
                  </Grid>
                  <Grid item xs={0} sm={0.4} md={0.4} lg={0.4} />
                  <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                    <CButton
                      variant="outlined"
                      style={{ marginTop: "20px" }}
                      onClick={() => {
                        createPostValidation("draft");
                      }}
                      loading={draftBtnLoader}
                      disabled={saveAsDraftDisable}
                    >
                      {translate("save_as_draft_button")}
                    </CButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} />
            </Grid>
          )}
        </>
      )}
      <CFooter />
    </div>
  );
};

export default EditBuyPost;
