const actions = {
  SET_ACCESS_TOKEN: "auth/SET_ACCESS_TOKEN",
  SET_USER_DATA: "auth/SET_USER_DATA",
  SET_UUID: "auth/SET_UUID",
  SET_ACTIVE_PAGE: "auth/SET_ACTIVE_PAGE",
  SET_CART_BADGE: "auth/SET_CART_BADGE",
  SET_SIDEBAR: "auth/SET_SIDEBAR",
  SET_SELECTED_CONV: "auth/SET_SELECTED_CONV",
  SET_IMAGE_FILES: "auth/SET_IMAGE_FILES",
  SET_USER_FIRST_TIME: "auth/SET_USER_FIRST_TIME",
  SET_FIRST_TIME_SKIP: "auth/SET_FIRST_TIME_SKIP",
  SET_POPUP_SHOW_TIME: "auth/SET_POPUP_SHOW_TIME",
  SET_PROFILE_COMPLETE_POPUP_SHOW_TIME:
    "auth/SET_PROFILE_COMPLETE_POPUP_SHOW_TIME",

  setAccessToken: (token) => (dispatch) =>
    dispatch({
      type: actions.SET_ACCESS_TOKEN,
      accessToken: token,
    }),
  setUserData: (data) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_DATA,
      userData: data,
    }),
  setUUID: (id) => (dispatch) =>
    dispatch({
      type: actions.SET_UUID,
      UUID: id,
    }),
  setActivePage: (page) => (dispatch) =>
    dispatch({
      type: actions.SET_ACTIVE_PAGE,
      activePage: page,
    }),
  setCartBadge: (badge) => (dispatch) =>
    dispatch({
      type: actions.SET_CART_BADGE,
      cartBadge: badge,
    }),
  setSidebar: (data) => (dispatch) =>
    dispatch({
      type: actions.SET_SIDEBAR,
      sidebar: data,
    }),
  setSelectedConv: (data) => (dispatch) =>
    dispatch({
      type: actions.SET_SELECTED_CONV,
      selectedConv: data,
    }),
  setImageFile: (data) => (dispatch) =>
    dispatch({
      type: actions.SET_IMAGE_FILES,
      imageFile: data,
    }),
  setUserFirstTime: (data) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_FIRST_TIME,
      userFirstTime: data,
    }),
  setFirstTimeSkip: (data) => (dispatch) =>
    dispatch({
      type: actions.SET_FIRST_TIME_SKIP,
      firstTimeSkip: data,
    }),

  setPopupShowTime: (data) => (dispatch) =>
    dispatch({
      type: actions.SET_POPUP_SHOW_TIME,
      popupShowTime: data,
    }),

  setProfileCompletePopupShowTime: (data) => (dispatch) =>
    dispatch({
      type: actions.SET_PROFILE_COMPLETE_POPUP_SHOW_TIME,
      profileCompletePopupShowTime: data,
    }),
};
export default actions;
