import { translate } from "../lang/Translate";

export const dropDownData = [
  {
    id: 1,
    name: "MT",
  },
  {
    id: 2,
    name: "KG",
  },
  {
    id: 3,
    name: "LB",
  },
];

export const unitList = [
  {
    id: 1,
    name: "MT",
  },
  {
    id: 2,
    name: "LB",
  },
  {
    id: 3,
    name: "KG",
  },
  {
    id: 4,
    name: "Machinery",
  },
];

export const supplyList = [
  {
    id: 1,
    name: "One time",
    value: "one_time",
  },
  {
    id: 2,
    name: "Ongoing",
    value: "ongoing",
  },
];

export const pricingTermsList = [
  {
    id: 1,
    name: "EXW",
  },
  {
    id: 2,
    name: "FCA",
  },
  {
    id: 3,
    name: "FAS",
  },
  {
    id: 4,
    name: "FOB",
  },
  {
    id: 5,
    name: "CFR",
  },
  {
    id: 6,
    name: "CIF",
  },
  {
    id: 7,
    name: "CPT",
  },
  {
    id: 8,
    name: "CIP",
  },
  {
    id: 9,
    name: "DAF",
  },
  {
    id: 10,
    name: "DES",
  },
  {
    id: 11,
    name: "DEQ",
  },
  {
    id: 12,
    name: "DDU",
  },
  {
    id: 13,
    name: "Other",
  },
];

export const myOffersStatus = [
  {
    id: 1,
    title: "Draft",
    name: "draft",
  },
  {
    id: 2,
    title: "In review",
    name: "in_review",
  },
  {
    id: 3,
    title: "Posted",
    name: "posted",
  },
  {
    id: 4,
    title: "Rejected",
    name: "rejected",
  },
  {
    id: 5,
    title: "Expired",
    name: "expired",
  },
];
