import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import styles from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Helmet } from "react-helmet";
import theme, { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/Color";
import { IoChevronBackCircle } from "react-icons/io5";
import { isMobile } from "react-device-detect";

const CPageHeader = (props) => {
  const navigate = useNavigate();
  const {
    title = "",
    titleNav,
    onTitleClick = () => {},
    subTitle = "",
    subTitleNav,
    subSubTitle = "",
    subSubTitleNav,
    isSpaceRequired = true,
    moreOffers = false,
    pageTitle = "",
    isProductDetailPage = false,
    clearMoreOffers = () => {},
    hide = false,
    isAlert = false,
    clearAlerts = () => {},
    titleShow = true,
    showDescription = true,
    postId = "",
    showKeywords = false,
    keywords = "",
  } = props;
  const classes = styles();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container>
      {isSpaceRequired && <Grid xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />}
      {titleShow && (
        <Helmet>
          <title>
            {isProductDetailPage
              ? pageTitle
              : subSubTitle
              ? subSubTitle
              : subTitle}{" "}
            | ScrapC Marketplace {isProductDetailPage ? `| ${postId}` : ""}
          </title>
          {showDescription && (
            <meta name="description" content="Buy.Sell.Connect" />
          )}

          {showKeywords && <meta name="keywords" content={keywords} />}
        </Helmet>
      )}

      <Grid
        item
        xs={moreOffers || isAlert ? 12 : 11}
        sm={moreOffers || isAlert ? 12 : 11}
        md={moreOffers || isAlert ? 12 : 11}
        lg={moreOffers || isAlert ? 12 : 10}
        xl={moreOffers || isAlert ? 12 : 10}
        className={classes.subContainer}
      >
        <Typography
          onClick={() => {
            onTitleClick();
            if (
              !_.isEmpty(titleNav) &&
              !_.isUndefined(titleNav) &&
              !_.isNull(titleNav)
            ) {
              navigate(titleNav);
            }
          }}
          className={classes.headerText}
        >
          {title}
        </Typography>

        {subTitle && (
          <>
            <Typography className={classes.slashTxt}>/</Typography>
            <Typography
              onClick={() => {
                if (
                  !_.isEmpty(subTitleNav) &&
                  !_.isUndefined(subTitleNav) &&
                  !_.isNull(subTitleNav)
                ) {
                  navigate(subTitleNav);
                }
              }}
              variant={!isProductDetailPage && !subSubTitle && "h1"}
              className={classes.headerText}
              sx={{
                color: hide && "transparent !important",
                textShadow: hide && "0 0 10px #000 !important",
                userSelect: hide && "none",
                fontSize: isAlert && isMobile && sm && "12px !important",
                marginTop: "4px",
              }}
            >
              {subTitle}{" "}
            </Typography>
            {moreOffers && (
              <div
                style={{
                  // backgroundColor: BaseColor.primary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30px",
                  width: "50px",
                  alignSelf: "center",
                  // marginLeft: "10px",
                  cursor: "pointer",
                  // color: BaseColor.white,
                  borderRadius: "10px",
                }}
                onClick={clearMoreOffers}
              >
                <IoChevronBackCircle
                  style={{
                    height: "100%",
                    width: "100%",
                    color: BaseColor.primary,
                  }}
                />
              </div>
            )}
            {isAlert && (
              <div
                style={{
                  // backgroundColor: BaseColor.primary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30px",
                  width: "50px",
                  alignSelf: "center",
                  cursor: "pointer",
                  borderRadius: "10px",
                }}
                onClick={clearAlerts}
              >
                <IoChevronBackCircle
                  style={{
                    height: "100%",
                    width: "100%",
                    color: BaseColor.primary,
                  }}
                />
              </div>
            )}
          </>
        )}

        {subSubTitle && (
          <>
            <Typography
              className={classes.slashTxt}
              // variant={!isProductDetailPage && "h1"}
              marginTop={"2px"}
            >
              /
            </Typography>
            <Typography
              className={classes.headerText}
              variant={!isProductDetailPage && "h1"}
              marginTop={!isProductDetailPage ? "5px" : "5px"}
              onClick={() => {
                if (
                  !_.isEmpty(subSubTitleNav) &&
                  !_.isUndefined(subSubTitleNav) &&
                  !_.isNull(subSubTitleNav)
                ) {
                  navigate(subSubTitleNav);
                }
              }}
            >
              {subSubTitle}
            </Typography>
          </>
        )}
      </Grid>
      <Grid xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
    </Grid>
  );
};

export default CPageHeader;
