import types from "./actions";

const initialState = {
  accessToken: "",
  userData: {},
  activePage: 0,
  UUID: "",
  cartBadge: false,
  sidebar: false,
  selectedConv: {},
  imageFile: {},
  userFirstTime: false,
  firstTimeSkip: false,
  popupShowTime: "",
  profileCompletePopupShowTime: "",
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: actions.accessToken,
      };
    case types.SET_USER_DATA:
      return { ...state, userData: actions.userData };
    case types.SET_UUID:
      return { ...state, UUID: actions.UUID };
    case types.SET_ACTIVE_PAGE:
      return {
        ...state,
        activePage: actions.activePage,
      };
    case types.SET_CART_BADGE:
      return {
        ...state,
        cartBadge: actions.cartBadge,
      };

    case types.SET_SIDEBAR:
      return {
        ...state,
        sidebar: actions.sidebar,
      };
    case types.SET_SELECTED_CONV:
      return {
        ...state,
        selectedConv: actions.selectedConv,
      };
    case types.SET_IMAGE_FILES:
      return {
        ...state,
        imageFile: actions.imageFile,
      };

    case types.SET_USER_FIRST_TIME:
      return {
        ...state,
        userFirstTime: actions.userFirstTime,
      };

    case types.SET_FIRST_TIME_SKIP:
      return {
        ...state,
        firstTimeSkip: actions.firstTimeSkip,
      };
    case types.SET_POPUP_SHOW_TIME:
      return {
        ...state,
        popupShowTime: actions.popupShowTime,
      };

    case types.SET_PROFILE_COMPLETE_POPUP_SHOW_TIME:
      return {
        ...state,
        profileCompletePopupShowTime: actions.profileCompletePopupShowTime,
      };

    default:
      return state;
  }
}
