import React from "react";
import { Button, CircularProgress } from "@mui/material";
import Lottie from "lottie-react";
import styles from "./styles";
import lottieFile from "../../Config/Files";
import BaseColor from "../../Config/Color";
import CIcon from "../CIcon";
import { IoClose } from "react-icons/io5";
import Files from "../../Config/Files";
import PropTypes from "prop-types";

const CButton = (props) => {
  const classes = styles();
  const {
    children,
    variant = "contained",
    color = "primary",
    loading,
    disabled = false,
    onClick,
    loaderTop,
    removeImg,
    cameraIcon,
    style,
    btnStyle,
  } = props;

  return removeImg ? (
    <div className={classes.removeBtnContainer} style={style} onClick={onClick}>
      <div className={classes.removeBtn}>
        {loading ? (
          <CircularProgress size={12} color="error" />
        ) : (
          <IoClose size={20} color={BaseColor.red} />
        )}
      </div>
    </div>
  ) : cameraIcon ? (
    <div className={classes.cameraIconBtnContainer} onClick={onClick}>
      <div className={classes.cameraIcon}>
        <CIcon
          src={Files.svgIcons.camera}
          size={18}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  ) : (
    <div className={classes.root} style={btnStyle}>
      <Button
        {...props}
        sx={[{}, style]}
        variant={variant}
        color={color}
        disabled={loading ? loading : disabled}
        onClick={onClick}
        className={classes.button}
      >
        {loading ? (
          <div
            style={{
              position: "absolute",
              height: 40,
              width: 200,
              top: loaderTop
                ? loaderTop
                : variant == "outlined"
                ? "-95%"
                : "-90%",
            }}
          >
            <Lottie
              animationData={
                variant == "outlined"
                  ? lottieFile?.lottie?.primaryColorLoader
                  : lottieFile.lottie.loader
              }
              loop={true}
              height={40}
              width="100%"
            />
          </div>
        ) : (
          children
        )}
      </Button>
    </div>
  );
};

CButton.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CButton;
