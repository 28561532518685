import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/Color";

const styles = makeStyles({
  title: {
    fontFamily: `${FontFamily.SemiBold} !important`,
    fontWeight: "900",
    fontSize: "20px !important",
    color: BaseColor.textColor,
    "@media (max-width: 1500px)": {
      fontSize: "18px !important",
    },
  },
  scrollBar: {
    display: "flex",

    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 5,
      height: 5,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.disablePrimary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },

  labelContainer: {
    margin: "5px 5px",
    width: "auto",
    padding: "2px 8px",
    background: BaseColor.primary,
    display: "flex",
    marginRight: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 5,
  },
  labelText: {
    width: "95%",
    fontSize: 14,
    fontFamily: FontFamily.SemiBold,
    fontWeight: "900",
    color: BaseColor.whiteColor,
  },
});

export default styles;
