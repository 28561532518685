import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/Color";
import { FontFamily } from "../../Config/theme";

const styles = makeStyles({
  root: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 90,
    backgroundColor: BaseColor.primary,
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  logo: {
    height: 60,
    width: 150,
    objectFit: "contain",
    "@media (max-width:1200px)": {
      width: 120,
      height: 50,
    },
    "@media (max-width:900px)": {
      width: 100,
      height: 50,
    },
    "@media (max-width:600px)": {
      width: 100,
      height: 50,
    },
  },
  homeLogo: {
    width: 180,
    height: 60,
    "@media (max-width:900px)": {
      width: 150,
      height: 60,
    },
    "@media (max-width:600px)": {
      width: 100,
      height: 40,
    },
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    border: `1px solid ${BaseColor.whiteColor}`,
    borderRadius: 3,
    color: BaseColor.whiteColor,
  },
  downArrowIcon: {
    fontSize: 22,
    color: BaseColor.whiteColor,
    transform: "rotate(90deg)",
  },
  searchInputContainer: {
    display: "flex",
    alignItems: "center",
    width: "80%",
    padding: "0px 10px",
    borderLeft: `1px solid ${BaseColor.white}`,
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: 1,
    borderLeftStyle: "solid",
    borderLeftColor: BaseColor.whiteColor,
    padding: "0 10px",
  },
  allPostText: {
    marginLeft: "8px",
    fontSize: 18,
    fontFamily: FontFamily.Medium,
    color: BaseColor.whiteColor,
  },

  iconsContainer: {
    background: BaseColor.primary,
  },

  createPostBtn: {
    background: BaseColor.whiteColor,
    color: BaseColor.primary,
    height: 40,
    borderRadius: 3,
    padding: "5px 15px 5px 15px",
  },
  postIconContainer: {
    border: `2px solid ${BaseColor.primary}`,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createPostText: {
    marginLeft: "6px",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    "@media (max-width: 1500px)": {
      fontSize: 12,
    },
  },

  threeIconContainer: {
    borderRight: `1px solid ${BaseColor.whiteColor}`,
    display: "flex",
  },

  userProfileContainer: {
    display: "flex",
    alignItems: "center",
  },
  userProfileImg: {
    height: 40,
    width: 40,
    borderRadius: 25,
    border: `1px solid ${BaseColor.whiteColor}`,
  },
  createPostPopoover: {
    "& .css-1ov0a33-MuiPaper-root-MuiPopover-paper": {
      backgroundColor: BaseColor.transparent,
    },
  },

  navContainer: {
    display: "flex",
    alignItems: "center",
    wrap: "nowrap",
    "@media (max-width:900px)": {
      display: "none",
    },
  },
  link: {
    color: BaseColor.whiteColor,
    cursor: "pointer",
    marginLeft: 15,
    marginRight: 15,
    fontFamily: FontFamily.Regular,
  },
  avtar: {
    display: "flex",
    "@media (max-width:900px)": {
      display: "none",
    },
  },

  menuIcon: {
    display: "none",
    "@media (max-width:900px)": {
      display: "flex",
    },
  },
  modalTitle: {
    marginLeft: -8,
    fontSize: 14,
    fontFamily: FontFamily.SemiBold,
    fontWeight: "900",
    color: BaseColor.primary,
  },
  scrollBar: {
    display: "flex",

    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 5,
      height: 5,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
});
export default styles;
