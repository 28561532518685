import { Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles";
import CHeader from "../../Components/CHeader";
import CFooter from "../../Components/CFooter";
import CPageHeader from "../../Components/CPageHeader";
import BaseSetting from "../../Apis/setting";
import _ from "lodash";
import { getApiData } from "../../Apis/apiHelper";
import { toast } from "react-toastify";
import moment from "moment";
import { translate } from "../../lang/Translate";

const AboutUs = () => {
  const classes = styles();

  // ? Reference
  const footerRef = useRef();

  const [aboutUsData, setAboutUsData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    GetTermsList();
  }, []);

  const GetTermsList = () => {
    const endPoint = BaseSetting.endpoint.getCMS;
    const data = {
      slug: "about_us",
    };
    getApiData(endPoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          setAboutUsData(result?.data);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  return (
    <div className={classes.root}>
      <CHeader
        onLoginRequired={() => {
          footerRef?.current?.LoginModalOpen(true);
        }}
      />
      <CPageHeader
        title={translate("home_title")}
        titleNav="/products"
        subTitle={translate("about_us_title")}
      />
      <Grid container className={classes.mainContainer}>
        <Grid item xs={0.5} sm={0.5} md={1} lg={1} xl={1} />
        <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
          <Grid container style={{ marginBottom: 20 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={classes.headerContainer}>
                <Typography
                  className={classes.headerTitleText}
                  style={{ fontSize: 24 }}
                >
                  {translate("about_us_head")}
                </Typography>
              </div>

              <div
                dangerouslySetInnerHTML={{ __html: aboutUsData?.html_body }}
              ></div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.5} sm={0.5} md={1} lg={1} xl={1} />
      </Grid>

      <CFooter ref={footerRef} />
    </div>
  );
};

export default AboutUs;
