import { Grid, Modal, Radio, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/Color";
import CButton from "../CButton";
import { initTranslate, translate } from "../../lang/Translate";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import ReactCountryFlag from "react-country-flag";
import { store } from "../../Redux/Store/configureStore";
import languageAction from "../../Redux/Reducers/language/actions";
import _ from "lodash";

export default function ChangeLanguageModal(props) {
  const {
    message = "",
    visible = false,
    handleClose = () => null,
    confirmation = () => null,
    loader = false,
    title = "",
    titleText = "",
    rightBtnText = translate("confirm_button"),
    leftBtnText = translate("cancel_button"),
  } = props;

  const [changeLanguageList, setChangeLanguageList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [confirmBtnLoader, setConfirmBtnLoader] = useState(false);

  useEffect(() => {
    GetLanguageList();
  }, [visible == true]);

  const {
    language: { language },
  } = store.getState();

  const GetLanguageList = () => {
    const endpoint = BaseSetting.endpoint.allLanguageList;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result?.status) {
          const list = result?.data?.translation_languages;
          const languageSelect =
            _.isNull(language?.code) || language?.code == "en"
              ? "en-Us"
              : language?.code;
          const findEnglish = list.find((v) => v.language_id == languageSelect);
          setChangeLanguageList(list);
          setSelectedLanguage(findEnglish);
        } else {
        }
      })
      .catch((err) => {});
  };

  const GetLanguageApi = (id) => {
    setConfirmBtnLoader(true);
    const endpoint = BaseSetting.endpoint.getLanguage;
    const data = {
      lang_id: id,
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          store.dispatch(
            languageAction.setLanguage({
              code: id,
              json: result?.data,
            })
          );
          initTranslate(store, true);
          setConfirmBtnLoader(true);
        } else {
          setConfirmBtnLoader(false);
        }
      })
      .catch((err) => {
        console.log(`💥️ ~ GetLanguageApi ~ err ->`, err);
        setConfirmBtnLoader(false);
      });
  };

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(3px)",
      }}
      open={visible}
      onClose={handleClose}
    >
      <Grid
        item
        xs={10}
        sm={8}
        md={6}
        lg={4}
        justifyContent={"center"}
        style={{
          backgroundColor: "#FFF",
          borderRadius: 12,
          padding: isMobile ? 20 : 30,
        }}
      >
        <Grid item>
          <Typography
            style={{
              color: BaseColor.blackColor,
              fontFamily: FontFamily.SemiBold,
              fontSize: "18px",
              letterSpacing: "0.5px",
              lineHeight: "24px",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            {translate("drawer_menu_change_language")}
          </Typography>
          {changeLanguageList.map((item, index) => {
            const isSelected =
              item.language_id == selectedLanguage?.language_id;
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "24px",
                }}
                onClick={() => {
                  setSelectedLanguage(item);
                }}
              >
                <Radio checked={isSelected} />
                <ReactCountryFlag
                  svg
                  countryCode={item?.country}
                  // cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                  // cdnSuffix="svg"
                  style={{
                    fontSize: 20,
                    cursor: "default",
                  }}
                />
                <Typography
                  style={{
                    color: BaseColor.textColor,
                    fontFamily: FontFamily.Medium,
                    fontSize: "16px",
                    letterSpacing: "0.5px",
                    lineHeight: "24px",
                    marginLeft: "15px",
                    cursor: "pointer",
                  }}
                >
                  {item.name}
                </Typography>
              </div>
            );
          })}
        </Grid>
        <Grid
          item
          container
          justifyContent={"center"}
          gap={isMobile ? 1 : 2}
          wrap="nowrap"
          marginTop={"10px"}
        >
          <Grid item xs={6}>
            <CButton variant="outlined" onClick={handleClose}>
              {leftBtnText}
            </CButton>
          </Grid>
          <Grid item xs={6}>
            <CButton
              loading={confirmBtnLoader}
              variant="contained"
              onClick={() => {
                GetLanguageApi(selectedLanguage?.language_id);
              }}
            >
              {rightBtnText}
            </CButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
