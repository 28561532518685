/*firebase daniel start*/
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import Files from "../../Config/Files";
import authActions from "../../Redux/Reducers/auth/actions";
import { useSelector } from "react-redux";
import _ from "lodash";
import { store } from "../../Redux/Store/configureStore";
import "firebase/messaging";

const PushNotification = () => {
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyCt7-BDl1WG7yV-gtJ7FrkIUdiQz-AZZI0",
    authDomain: "push-notification-scrapc.firebaseapp.com",
    projectId: "push-notification-scrapc",
    storageBucket: "push-notification-scrapc.appspot.com",
    messagingSenderId: "562218573711",
    appId: "1:562218573711:web:ddb1855bcc70228f4075fa",
    measurementId: "G-B44BTLGN04",
  };

  const fapp = initializeApp(firebaseConfig);
  const messaging = getMessaging(fapp);
  const { accessToken } = useSelector((state) => state.auth);
  useEffect(() => {
    AskNotificationPermission();
  }, [accessToken]);

  const AskNotificationPermission = () => {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      // alert("This browser does not support desktop notification");
    } else if (Notification.permission !== "granted") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          requestForFCMToken();
        } else {
        }
      });
    } else if (Notification.permission === "granted") {
      requestForFCMToken();
      ReceiveMsg();
    }
  };

  const requestForFCMToken = async () => {
    getToken(messaging, {
      vapidKey:
        "BGFeeNACu0IBpjvEm95n72zn16g15krc49MFALrycXkA1Ro666Ld4J-eOXoRrXykbNaKEmxnuVbw3H11u7eWtBs",
    })
      .then((currentToken) => {
        if (currentToken) {
          store.dispatch(authActions.setUUID(currentToken));
        } else {
          // Show permission request UI
        }
      })
      .catch((err) => {});
  };

  const ReceiveMsg = () => {
    onMessage(messaging, (payload) => {
      const notification = new Notification(payload?.notification?.title, {
        body: payload?.notification?.body,
        icon: Files.images.appIcon,
        vibrate: true,
        native: true,
      });
      // setTimeout(() => notification.close(), 10 * 1000);
    });
  };
};

export default PushNotification;
