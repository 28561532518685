import { io } from "socket.io-client";
import { isLive, isStaging } from "./setting";
// "undefined" means the URL will be computed from the `window.location` object
// const URL = "http://192.168.0.106:4000";

const localApiUrl = isLive
  ? isStaging
    ? "https://uatchat.scrapc.com:3300"
    : "https://chat.scrapc.com/"
  : "http://192.168.1.51:3300"; // Ruchit
export const chatImageUpload = `${localApiUrl}/chat/upload-image`;
const socket = io(localApiUrl, {
  autoConnect: false,
});

export default socket;
